// Prep a sentence and sequence so they can be played
//  create sentence segments based on splits
//  creage segFinal array of segments

export const useSentencePlay = () => {
  function prepareSentence(sentence, seqText) {
    // const sequence = sentenceList.usesSeq
    //   ? sentenceList.usesSeq.seqText
    //   : 'Otttx1i,Ttttx1i';
    const sequenceSteps = seqText.split(",");
    const { origText, splits } = sentence;
    const sentenceSegments = createSentenceSegments(origText, splits);
    const seqFinal = transformSequence(sentenceSegments.length, sequenceSteps);

    return {
      status: "OK",
      segments: sentenceSegments,
      steps: seqFinal,
    };
  }

  function transformSequence(maxLength, sequenceSteps) {
    // get the Ottt or Ottf etc part, clip off the x1 or x2 and the
    // ttf part that is longer than the number of sentence segments (maxLength)
    const seqTransformOne = sequenceSteps.map((s) => {
      return s.slice(0, maxLength + 1) + s.slice(-3);
    });
    // filter sequnce steps that now have no play part (no 't' value)
    const seqTransformTwo = seqTransformOne.filter((s) => {
      return s.indexOf("t") > -1;
    });
    const seqTransformThree = [...seqTransformTwo];
    for (let i = seqTransformTwo.length; i > -1; i--) {
      if (i > 0 && seqTransformThree[i] === seqTransformThree[i - 1]) {
        seqTransformThree.splice(i, 1);
      }
    }
    // for the final transform, fill in 'f' for 2d 3d segments if needed
    const seqFinal = seqTransformThree.map((s) => {
      if (s.length === 5) {
        return s.slice(0, 2) + "ff" + s.slice(2, 5);
      }
      if (s.length === 6) {
        return s.slice(0, 3) + "f" + s.slice(3, 6);
      }
      return s;
    });
    return seqFinal;
  }

  function createSentenceSegments(text, splits) {
    let segArray = [];
    if (splits && splits.length) {
      const splitsArray = splits.split(",");
      const wordArray = text.split(" ");
      let ix = 0;
      let collector = "";
      let word;
      for (let i = 0; i < wordArray.length; i++) {
        word = wordArray[i];
        if (splitsArray[i] === "true") {
          collector += word + " ";
          segArray[ix] = collector;
          ix++;
          collector = "";
        } else {
          collector += word + " ";
        }
        if (collector.length) {
          segArray[ix] = collector;
        }
      }
    } else {
      segArray[0] = text;
    }
    return segArray;
  }

  return { prepareSentence };
};
