import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Box, Typography, Button, Stack, TextField } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { AuthContext } from "../_context/AuthContext";
import useAxiosPrivate from "../_hooks/useAxiosPrivate";

export { Login };

function Login() {
  const { setAuth } = useContext(AuthContext);
  const api = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  //  const from = location.state?.from?.pathname || "/";

  async function handleLogin({ email, password }) {
    let authResponse;
    try {
      authResponse = await api.post("/auth/signin", {
        email,
        password,
      });
      const {
        id,
        accessToken,
        roles,
        refreshToken,
        preferences,
        isPaid,
        hasRenewed,
      } = authResponse.data;

      setAuth({
        id,
        email,
        accessToken,
        roles,
        refreshToken,
        isPaid,
        hasRenewed,
      });
      localStorage.setItem(
        "user",
        JSON.stringify({
          email: email,
          preferences: preferences,
        })
      );
      navigate(preferences.openToPage, {
        state: { from: location },
        replace: true,
      });
    } catch (err) {
      if (err.response) {
        enqueueSnackbar(err.response.data.errors.join(", "), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(err, { variant: "error" });
      }
    }
  }

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "chip@test.com",
      password: "pwChip",
    },
  };

  // from app bar, 'LOGOUT' navigates to /login, so null out auth first
  useEffect(() => {
    setAuth(null);
    localStorage.removeItem("user");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h6">
        gvLangLearning - Sign in
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(handleLogin)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          name="email"
          label="Email address"
          type="text"
          autoComplete="off"
          autoFocus
          error={errors.name ? true : false}
          {...register("email")}
        />
        <Typography variant="inherit" color="textSecondary">
          {errors.email?.message}
        </Typography>
        <TextField
          margin="normal"
          name="password"
          label="Password"
          type="password"
          autoComplete="off"
          error={errors.password ? true : false}
          {...register("password")}
        />
        <Typography variant="inherit" color="textSecondary">
          {errors.password?.message}
        </Typography>
        <Stack spacing={3}>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            startIcon={<LoginIcon />}
          >
            Login
          </Button>
        </Stack>
        <div>{isSubmitting && <span>Submitting...</span>}</div>
        <Button
          variant="outlined"
          onClick={() => navigate("/register")}
          startIcon={<AppRegistrationIcon />}
        >
          Sign Up
        </Button>
      </Box>
    </Box>
  );
}
