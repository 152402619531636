import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTheme } from "@mui/material";
import {
  Paper,
  Grid,
  Box,
  TextField,
  Typography,
  Link,
  Button,
  Stack,
} from "@mui/material";

import useAxiosPrivate from "../_hooks/useAxiosPrivate";
import { registrationInfo } from "../_helpers/info-blocks";

export { Register };

function Register({ history }) {
  const theme = useTheme();
  const api = useAxiosPrivate();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    couponCode: Yup.string().required("Coupon code is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  async function onSubmit(data) {
    try {
      await api.post("auth/signup", data);
      enqueueSnackbar("You are now registered: please login in", {
        variant: "success",
      });
      setTimeout(() => navigate("/login"), 3000);
    } catch (err) {
      if (err.response) {
        if (err.response) {
          enqueueSnackbar(err.response.data.errors.join(", "), {
            variant: "error",
          });
        }
      }
    }
  }
  return (
    <Fragment>
      <Paper>
        <Stack direction="row" spacing={2} mt={3} ml={1}>
          <Box
            sx={{
              width: "55%",
              color: theme.palette.neutral.dark,
              backgroundColor: theme.palette.grey[400],
              padding: "20px",
            }}
          >
            <Stack spacing={2}>
              <Typography variant="h6">Sign up for gvLangLearning</Typography>
              {registrationInfo.map((regLine, index) => (
                <Typography key={index} variant="body2" sx={{ margin: "15px" }}>
                  {regLine}
                </Typography>
              ))}
            </Stack>
          </Box>
          <Box sx={{ width: "40%" }}>
            <Stack justifyContent="center" marginTop="5px" spacing={1}>
              <Typography variant="body1" margin="dense">
                gvLangLearning - Sign-up
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: 700, padding: "5px" }}
              >
                At this time, registration is open only to beta testers who have
                a coupon code.
              </Typography>
            </Stack>
            <Grid container sx={{ width: "75%" }} spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  margin="dense"
                  {...register("name")}
                  error={errors.name ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.name?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  margin="dense"
                  {...register("email")}
                  error={errors.email ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.email?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  fullWidth
                  margin="dense"
                  {...register("password")}
                  error={errors.password ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.password?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  fullWidth
                  margin="dense"
                  {...register("confirmPassword")}
                  error={errors.confirmPassword ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.confirmPassword?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  required
                  id="couponCode"
                  name="couponCode"
                  label="Coupon code"
                  type="text"
                  fullWidth
                  margin="dense"
                  {...register("couponCode")}
                  error={errors.couponCode ? true : false}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.couponCode?.message}
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container sx={{ width: "600px" }} spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Link onClick={() => navigate("/login")}>Login</Link>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Paper>
    </Fragment>
  );
}
