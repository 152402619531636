import React from "react";
import { useTheme } from "@mui/material";
import {
  Stack,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import { termsOfUse } from "../_helpers/info-blocks";

export { TermsOfUseDialog };

function TermsOfUseDialog(props) {
  const { onClose, open } = props;
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box
          sx={{
            padding: "10px",
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.light,
            typography: "body2",
            marginBottom: "15px",
          }}
        >
          PhraseKeeperTTS - Terms of Use
        </Box>
        <Stack spacing={2}>
          {termsOfUse.map((item, ix) => (
            <Stack spacing={1} key={ix}>
              <Typography variant="caption">{item}</Typography>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ width: "45%", margin: "10px" }}
          variant="text"
          onClick={() => onClose()}
          startIcon={<CancelOutlinedIcon />}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
