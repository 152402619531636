import React, { useState, useEffect } from "react";
import { SequenceDisplay } from "../sequences";
import { Box, Stack, Typography } from "@mui/material";
import { Auth, API } from "aws-amplify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const highlightColor = "#ccdc39";

export { SequenceSelector };

function SequenceSelector({ onSelect, changeCount, showFilter }) {
  const [sequences, setSequences] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [seqLeadWith, setSeqLeadWith] = useState("All");
  const [seqDirection, setSequenceDirection] = useState("All");

  function handleSelected(s) {
    setSelectedSequence(s);
    onSelect(s);
  }

  const handleLeadWithChange = (event) => {
    setSeqLeadWith(event.target.value);
  };

  const handleDirectionChange = (event) => {
    setSequenceDirection(event.target.value);
  };

  useEffect(() => {
    const applyFilter = () => {
      const ary = apiData.filter((item) => {
        let pos1 = item.seqText.search("Otff");
        if (pos1 === -1) pos1 = 99;
        let pos3 = item.seqText.search("Offt");
        if (pos3 === -1) pos3 = 99;
        if (seqLeadWith === "TL" && item.seqText.startsWith("T")) return false;
        if (seqLeadWith === "NL" && item.seqText.startsWith("O")) return false;
        if (seqDirection === "1-2-3" && pos3 < pos1) return false;
        if (seqDirection === "3-2-1" && pos1 < pos3) return false;
        return true;
      });
      setSequences(ary);
    };
    applyFilter();
  }, [seqLeadWith, seqDirection, apiData]);

  useEffect(() => {
    const cognitoUser = Auth.user.username;
    let isMounted = true;
    const getSequences = async () => {
      try {
        const response = await API.get(
          "sequencesApi",
          `/sequences/get-sequences?ownerid=${cognitoUser}`
        );
        if (isMounted) {
          setApiData(response.appData.sequences);
          setSequences(response.appData.sequences);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getSequences();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCount]);

  return (
    <>
      <FormControl>
        <Stack direction="row" spacing={2} alignItems="center">
          <FormLabel id="rbLeadWith">
            <Typography variant="caption">
              Start with Target or Natural language:
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="rbLeadWith"
            name="controlled-rb-leadWith"
            value={seqLeadWith}
            onChange={handleLeadWithChange}
          >
            <FormControlLabel
              value="TL"
              control={<Radio />}
              label={<Typography variant="caption">TL</Typography>}
            />
            <FormControlLabel
              value="NL"
              control={<Radio size="small" />}
              label={<Typography variant="caption">NL</Typography>}
            />
            <FormControlLabel
              value="All"
              control={<Radio size="small" />}
              label={<Typography variant="caption">All</Typography>}
            />
          </RadioGroup>
        </Stack>
      </FormControl>
      <FormControl>
        <Stack direction="row" spacing={2} alignItems="center">
          <FormLabel id="rbDirection">
            <Typography variant="caption">
              Play from start (1-2-3) or end (3-2-1):
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="rbDirection"
            name="controlled-rb-direction"
            value={seqDirection}
            onChange={handleDirectionChange}
          >
            <FormControlLabel
              value="1-2-3"
              control={<Radio />}
              label={<Typography variant="caption">1-2-3</Typography>}
            />
            <FormControlLabel
              value="3-2-1"
              control={<Radio />}
              label={<Typography variant="caption">3-2-1</Typography>}
            />
            <FormControlLabel
              value="All"
              control={<Radio />}
              label={<Typography variant="caption">All</Typography>}
            />
          </RadioGroup>
        </Stack>
      </FormControl>

      <Box
        sx={{
          display: "flex",
          maxHeight: "100%",
          flexWrap: "wrap",
          overflowY: "scroll",
          backgroundColor: "#607d8b",
          border: "2px solid gray",
          borderRadius: "6px",
          marginTop: "15px",
        }}
      >
        {sequences &&
          sequences.map((s, index) => {
            return (
              <Box
                key={index}
                sx={{
                  margin: "10px",
                  width: "30%",
                  minWidth: "250px",
                  backgroundColor:
                    !!selectedSequence && s === selectedSequence
                      ? highlightColor
                      : "white",
                }}
                onClick={() => {
                  handleSelected(s);
                }}
              >
                <SequenceDisplay
                  key={s._id}
                  id={s._id}
                  seqName={s.name}
                  seqText={s.seqText}
                />
              </Box>
            );
          })}
      </Box>
    </>
  );
}
