import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import { useTheme } from "@mui/material";

const paragraphs = [
  {
    text: `A SEQUENCE in PhraseKeeperTTS is a set of steps that define how a sentence is played
    to you in text-to-speech (TTS). The steps are displayed as a PhraseKeeperTTS Sequence Graphic (TM)`,
    variant: "body2",
    padding: "5px",
  },
  {
    text: `The picture on the right is the Add/Edit form for sequences. There is a Name field which
    is an input, and the display-only field for the Sequence Steps. Directly below these is the 
    Sequence Graphic. In the seqGraphic display you can drag steps to re-order, or delete steps. `,
    variant: "body2",
    padding: "5px",
  },
  {
    text: `To add a sequence step, use the box at bottom labelled
    'Add Sequence Step'. Select one of steps from the list on the left:
    seg 1, seg 2, seg 3, seg 1-2, seg 2-3, seg 1-3, or the natural language step.
    Select the number of times this step will play (default is '1').
    Check the 'pause after play' box if you want a pause after the step is played. 
    Click the button 'Add Step to Sequence'.
    `,
    variant: "body2",
    padding: "5px",
  },
  {
    text: `
    When you are done adding steps, click SUBMIT to save.`,
    variant: "body2",
    padding: "5px",
  },
];

export { GsSequences };

function GsSequences() {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={3}>
      <Box
        sx={{
          backgroundColor: theme.palette.blues.light,
          width: "30%",
        }}
      >
        <Stack spacing={1} padding={1}>
          {paragraphs.map((p, index) => {
            return (
              <Typography
                key={index}
                variant={p.variant}
                sx={{ padding: p.padding }}
              >
                {p.text}
              </Typography>
            );
          })}
        </Stack>
      </Box>
      <Box sx={{ width: "70%" }}>
        <Stack spacing={1}>
          <Box sx={{ padding: "10px" }}>
            <img
              src="https://gvlang-guides.s3.us-west-2.amazonaws.com/AddEditSequence_2.png"
              alt="Edit Sequence"
              height="400px"
              width="650px"
            />
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}
