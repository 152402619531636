import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Typography, useTheme, IconButton } from "@mui/material";

import CssBaseline from "@mui/material/CssBaseline";
import { Button, Stack } from "@mui/material";
import { SnackbarProvider } from "notistack";

import { Amplify, Auth, API, Hub } from "aws-amplify";
import awsconfig from "../aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import HomeIcon from "@mui/icons-material/Home";

import "./App.css";

Amplify.configure(awsconfig);

function App(props) {
  const theme = useTheme();
  const navigate = useNavigate();

  function handleClick(menuItem) {
    //setSelMenuItem(menuItem);
    navigate(menuItem);
  }

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          console.log("signIn event in app.js");
          const cognitoUser = Auth.user.username;
          const email = data.signInUserSession.idToken.payload.email;
          const getUser = async () => {
            const response = await API.post("usersApi", `/users/sign-in`, {
              body: { cognitoUser: cognitoUser, email: email },
            });
            if (response.error) {
              alert(response.appData.message);
            }
            localStorage.setItem(
              "gvLangUser",
              JSON.stringify(response.appData)
            );
          };
          getUser();
          navigate("/app");
          break;
        default:
          console.log("useEffect switch default case");
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        console.log("current user", currentUser);
        const getUser = async () => {
          const cognitoUser = Auth.user.username;
          const response = await API.get(
            "usersApi",
            `/users/one-user?id=${cognitoUser}`
          );
          // console.log("currentAuth", response.appData);
          localStorage.setItem("gvLangUser", JSON.stringify(response.appData));
        };
        getUser();
      })
      .catch(() => console.log("Not signed in"));

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CssBaseline />
      <SnackbarProvider autoHideDuration={3000}>
        <Authenticator>
          {({ signOut, user }) => (
            <div>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  height: "6%",
                  backgroundColor: theme.palette.grey[700],
                  padding: "3px",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={() => navigate("/welcome")}>
                  <HomeIcon color="warning" />
                </IconButton>

                <Typography
                  variant="body1"
                  sx={{ color: "white", fontWeight: 600 }}
                >
                  PhraseKeeperTTS
                </Typography>
                <Button
                  variant="text"
                  size="large"
                  color="secondary"
                  onClick={() => handleClick("gettingstarted")}
                >
                  Start here
                </Button>

                <Button
                  variant="text"
                  size="large"
                  color="secondary"
                  onClick={() => handleClick("lessons")}
                >
                  Lessons
                </Button>

                <Button
                  variant="text"
                  size="large"
                  color="secondary"
                  onClick={() => handleClick("sequences")}
                >
                  Sequences
                </Button>
                <Button
                  variant="text"
                  size="large"
                  color="secondary"
                  onClick={() => handleClick("history")}
                >
                  History
                </Button>
                <Button
                  variant="text"
                  size="large"
                  color="secondary"
                  onClick={() => handleClick("preferences")}
                >
                  Settings
                </Button>
                <Button
                  variant="text"
                  size="large"
                  color="secondary"
                  onClick={() => handleClick("account")}
                >
                  Account
                </Button>

                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  onClick={() => {
                    localStorage.setItem("gvLangUser", null);
                    signOut();
                  }}
                >
                  Sign out
                </Button>
              </Stack>
              <Outlet />
            </div>
          )}
        </Authenticator>
      </SnackbarProvider>
    </div>
  );
}

export default App;
