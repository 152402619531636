import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, API } from "aws-amplify";

import { format as DateFormat } from "date-fns";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Input,
  Paper,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import LineStyleOutlinedIcon from "@mui/icons-material/LineStyleOutlined";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import EditIcon from "@mui/icons-material/Edit";

import { useTheme } from "@mui/material";
import { getIsPaid } from "../_services/user.service";
import { WindowTitle } from "../_helpers/gvlang-components";

export { SentencePractice };

function SentencePractice() {
  const theme = useTheme();
  const [isPaid, setIsPaid] = useState(false);
  const navigate = useNavigate();
  const cognitoUser = Auth.user.username;
  const [lessons, setLessons] = useState([]);
  const [sortBy, setSortBy] = useState("date");
  const [searchInput, setSearchInput] = useState("");
  const [apiData, setApiData] = useState("");

  // api is api.get(`lesson/owner/${ownerId}`)
  useEffect(() => {
    const getLessons = async () => {
      try {
        const response = await API.get(
          "lessonsApi",
          `/lessons/get-lessons?ownerid=${cognitoUser}`
        );
        setApiData(response.appData.lessons);
        const arr = response.appData.lessons.sort(function compare(a, b) {
          var dateA = new Date(a.lastUsed);
          var dateB = new Date(b.lastUsed);
          return dateB - dateA;
        });
        setLessons(arr);
        setIsPaid(() => getIsPaid(response.appData.user));
        localStorage.setItem(
          "gvLangUser",
          JSON.stringify(response.appData.user)
        );
      } catch (err) {
        console.error(err);
      }
    };
    getLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controlProps = (item) => ({
    checked: sortBy === item,
    onChange: handleSortByChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const searchItems = (searchValue) => {
    if (searchValue !== "") {
      const filteredData = apiData.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      setLessons(filteredData);
    } else {
      setLessons(apiData);
    }
    setSearchInput(searchValue);
  };

  const handleSortByChange = (event) => {
    const val = event.target.value;
    if (val === "date") {
      setLessons(
        lessons.sort(function compare(a, b) {
          var dateA = new Date(a.lastUsed);
          var dateB = new Date(b.lastUsed);
          return dateB - dateA;
        })
      );
    } else {
      setLessons(
        lessons.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      );
    }
    setSortBy(val);
  };

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          margin: "1px",
          height: "90vh",
          backgroundColor: theme.palette.neutral.main,
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          padding="10px"
          backgroundColor="white"
        >
          <WindowTitle>Practice Lesson with TTS</WindowTitle>
          <Typography variant="caption" sx={{ marginRight: "10px" }}>
            Sort by:
          </Typography>
          <Radio {...controlProps("date")} size="small" />
          <Typography variant="caption" sx={{ marginRight: "10px" }}>
            Last used
          </Typography>
          <Radio {...controlProps("name")} size="small" />
          <Typography variant="caption">Name</Typography>
          <Input
            sx={{ marginLeft: "25px", fontSize: "0.8rem" }}
            placeholder="search"
            name="searchInput"
            type="text"
            value={searchInput}
            onChange={(e) => searchItems(e.target.value)}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            height: "90%",
            overflowY: "scroll",
          }}
        >
          {lessons.map((lesson) => (
            <Card
              key={lesson._id}
              sx={{
                width: "45%",
                maxWidth: "375px",
                maxHeight: "200px",
                margin: "10px",
              }}
            >
              <CardHeader title={lesson.name} sx={{ fontSize: "0.8rem" }} />
              <CardContent
                sx={{
                  borderRadius: "8px",
                  marginLeft: "20px",
                }}
              >
                <Stack direction="row" spacing={6}>
                  <Typography
                    variant="caption"
                    color="text.primary"
                    display="block"
                  >
                    {`Last used: `}
                    {DateFormat(new Date(lesson.lastUsed), "dd-MMM-yy hh:mm a")}
                  </Typography>
                  <Typography variant="caption">
                    {lesson.sentenceCount} sentences
                  </Typography>
                </Stack>

                <Typography variant="caption" color="text.primary">
                  {lesson.origVoice}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.primary"
                  sx={{ marginLeft: "30px" }}
                >
                  Seq: {lesson.sequenceDetails.name}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.primary"
                  display="block"
                >
                  {lesson.transVoice}
                </Typography>
                <Typography variant="caption" display="block">
                  Course: {lesson.course}
                </Typography>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    padding: "5px",
                    fontWeight: 700,
                  }}
                >
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    disabled={!isPaid}
                    onClick={() => navigate(`edit/${lesson._id}`)}
                    startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    disabled={!isPaid}
                    onClick={() => navigate(`free/${lesson._id}`)}
                    startIcon={<VolumeUpIcon />}
                  >
                    Free Play
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    disabled={!isPaid}
                    onClick={() => navigate(`seq/${lesson._id}`)}
                    startIcon={<LineStyleOutlinedIcon />}
                  >
                    Sequence Play
                  </Button>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Paper>
    </>
  );
}
