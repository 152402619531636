import { Button, Stack, Dialog, DialogTitle, Typography } from "@mui/material";
import React from "react";

export { DialogConfirm };

function DialogConfirm(props) {
  const { onClose, open, dialogTitle, dialogText, dialogButtons } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <Typography variant="body2" sx={{ margin: 1 }}>
        {dialogText}
      </Typography>
      <Stack direction="row" spacing={2} justifyContent="center" sx={{ m: 2 }}>
        {dialogButtons.includes("OK") && (
          <Button variant="contained" onClick={() => onClose("OK")}>
            OK
          </Button>
        )}
        <Button autoFocus variant="contained" onClick={() => onClose("Cancel")}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
}
