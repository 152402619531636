import stringHash from "string-hash";
import { Auth, API } from "aws-amplify";

import { localStoragePrefix } from "../_helpers";
import { getGVsValue, setGVsValue } from "../_helpers/idb";
import { getBrowserType } from "../_services/browser.service";

function getGvsChecksum(s) {
  return stringHash(s);
}

export const usePolly = () => {
  const playPhrase = async (params) => {
    const { textToPlay, voiceStr } = params;
    const voiceId = voiceStr.toString().split(" ")[0];
    const engine = voiceStr.includes("neural") ? "neural" : "standard";
    const cognitoUser = Auth.user.username;
    let ttsCharUsed = 0;
    const isSafari = getBrowserType() === "Apple Safari";
    //    console.log("browser is: ", window.navigator.userAgent);
    const storageKey = localStoragePrefix + voiceId + textToPlay;
    const idKey = getGvsChecksum(storageKey);
    let uInt8Val;
    let blob;
    try {
      const gvsData = await getGVsValue(idKey);
      if (gvsData === undefined) {
        const textType = textToPlay.startsWith("<speak>") ? "ssml" : "text";
        const bodyData = { textToPlay, voiceId, textType, engine, cognitoUser };
        const response = await API.post(
          "sentencesApi",
          "/sentences/play-sentence",
          {
            body: bodyData,
            headers: {
              //Accept: "audio/mpeg",
              "Content-Type": "application/json",
            },
          }
        );
        if (response.error) {
          throw new Error("usePolly error");
        }
        ttsCharUsed = textToPlay.length;
        uInt8Val = Uint8Array.from(
          response.split(",").map((x) => parseInt(x, 10))
        );
        blob = new Blob([uInt8Val], { type: "audio/mpeg" });
        if (!isSafari) {
          const idbData = {
            id: idKey,
            textPhrase: textToPlay,
            uInt8Val: uInt8Val,
            date: Date(),
          };
          setGVsValue(idbData);
        }
      } else {
        uInt8Val = gvsData.uInt8Val;
        blob = new Blob([uInt8Val], { type: "base" });
      }
      var blobUrl = URL.createObjectURL(blob);
      var audio = new Audio(blobUrl);
      audio.crossOrigin = "anonymous";
      var playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            //console.log('it played!');
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setTimeout(function () {
              window.URL.revokeObjectURL(blobUrl);
            }, 100);
          });
      }
      return { error: false, message: "success", ttsCharUsed: ttsCharUsed };
    } catch (error) {
      console.log(error);
      return { error: true, message: "usePolly error" };
    }
  };

  return { playPhrase };
};
