import React, { useRef } from "react";
import { Button } from "@mui/material";

import SourceIcon from "@mui/icons-material/Source";

export default function FileUploader({ handleFile, buttonLabel, fileTypes }) {
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        sx={{ width: "200px" }}
        startIcon={<SourceIcon />}
      >
        {buttonLabel}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        accept={fileTypes}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
}
