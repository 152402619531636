import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material";

export { GsSentences };

const paragraphs = [
  {
    text: `Click the SENTENCES item in the top menu to add, edit or delete sentences. 
    The Sentence Workbook is shown on the right, where the user has clicked the sentence 'Bygget har ...'
    to open the edit window.
    `,
    variant: "body2",
  },
  {
    text: `To add a sentence, click  NEW SENTENCE. To edit a sentence, click the sentence to see the EDIT window.
    To delete a sentence, open the edit window and click DELETE.
    `,
    variant: "body2",
  },
  {
    text: `In the Add/Edit window, enter the TL text and NL text. Tic the Instruction box if the sentence
    will be spoken as an instruction: NL only, no pause. Enter the source (you can set a default Source in Preferences).
    Then, in Sentence splits, click the boxes between words to split the sentence into 2 or 3 parts. The sentence
    parts are used in Sequence Play.
    `,
    variant: "body2",
  },
  {
    text: `To load a group of sentences, click IMPORT. In the import window, paste the sentences you have copied from
    another source. Your source sentences can use TAB, single-sentence, or paragraph format.`,
    variant: "body2",
  },
  {
    text: `To move sentences between lists, click TRANSFER. This opens a TRANSFER window where you select two lessons
    and can move sentences between them. The TRANSFER window is also a quich way to delete sentences as a group.`,
    variant: "body2",
  },
];

function GsSentences() {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={2}>
      <Stack
        spacing={4}
        sx={{
          width: "30%",
          height: "25%",
          backgroundColor: theme.palette.blues.light,
          borderRadius: "6px",
          padding: "10px",
        }}
      >
        {paragraphs.map((p, index) => {
          return (
            <Typography key={index} variant={p.variant}>
              {p.text}
            </Typography>
          );
        })}
      </Stack>
      <Box sx={{ height: "60%" }}>
        <Stack spacing={1}>
          <img
            src="https://gvlang-guides.s3.us-west-2.amazonaws.com/gvLang_Sentence_Workbook_3.png"
            alt="alt sentence edit"
            height="450px"
            width="750px"
          />
        </Stack>
      </Box>
    </Stack>
  );
}
