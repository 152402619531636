import { useContext } from 'react';
import { AuthContext } from '../_context/AuthContext';
import { axiosPrivate } from '../_services/axios';

const useRefreshToken = () => {
  const { auth, setAuth } = useContext(AuthContext);

  const refresh = async () => {
    const { refreshToken } = auth;
    const response = await axiosPrivate.post('/auth/refreshtoken', {
      refreshToken,
    });
    setAuth((prev) => {
      return { ...prev, accessToken: response.data.accessToken };
    });
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
