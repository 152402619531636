import React from "react";
import { Box, Stack } from "@mui/material";

export function SequenceStepDisplay({ s, height, width }) {
  const user = JSON.parse(localStorage.getItem("gvLangUser"));
  const tlColor = user?.preferences.origColor || "#595ea1";
  const nlColor = user?.preferences.transColor || "#969c3a";
  const noColor = "#d3d3d3";

  var bgSeg1, bgSeg2, bgSeg3;

  if (s !== "") {
    bgSeg1 = s[0] === "O" ? (s[1] === "t" ? tlColor : noColor) : nlColor;
    bgSeg2 = s[0] === "O" ? (s[2] === "t" ? tlColor : noColor) : nlColor;
    bgSeg3 = s[0] === "O" ? (s[3] === "t" ? tlColor : noColor) : nlColor;
  } else {
    bgSeg1 = noColor;
    bgSeg2 = noColor;
    bgSeg3 = noColor;
  }

  return (
    <Stack direction="row">
      <Box height={height} width={width} bgcolor={bgSeg1} />
      <Box height={height} width={width} bgcolor={bgSeg2} />
      <Box height={height} width={width} bgcolor={bgSeg3} />
    </Stack>
  );
}
