import { React } from "react";
import { Box } from "@mui/material";

const WindowTitle = ({ children }) => {
  return (
    <Box
      typography="body2"
      sx={{
        backgroundColor: "#F1D45E",
        color: "black",
        padding: "5px 15px 5px 15px",
        borderRadius: "2px",
        marginRight: "15px",
        maxWidth: "250px",
      }}
    >
      {children}
    </Box>
  );
};

export { WindowTitle };
