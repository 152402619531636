import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material";

export { GsLesson };

const paragraphs = [
  {
    text: `A LESSON in PhraseKeeperTTS is a container of sentences in a defined target language (TL),
    a defined natural language (NL), and a sequence that defines how you will hear the sentence
    when it is played using TTS. 
    `,
    variant: "body2",
  },
  {
    text: `Select LESSONS in the main menu to see your lessons, displayed as a Lesson Card
    for each lesson. You can click CREATE LESSON to open the Add-Edit Lesson form for adding a lesson.
    The IMPORT LESSON link lets you import a lesson from an export file you received from another user.
    `,
    variant: "body2",
  },
  {
    text: `On the Lesson Card itself there are several links. The sentnce count is a link that brings you to a
    window where you can add/edit sentences. The LESSON link opens a window where you can change attibutes of
    your lesosn such as the target language (TL) or the sequence. The FREE PLAY and SEQUENCE PLAY links go to
    practice windows where you can hear the TTS in 'free-form' or following the Sequence.
    `,
    variant: "body2",
  },
];

//edf358
function GsLesson() {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={4}>
      <Stack
        sx={{
          width: "25%",
          backgroundColor: theme.palette.blues.light,
          borderRadius: "6px",
          padding: "10px",
        }}
      >
        {paragraphs.map((p, index) => {
          return (
            <Typography key={index} variant={p.variant} sx={{ padding: 1 }}>
              {p.text}
            </Typography>
          );
        })}
      </Stack>
      <Stack>
        <Stack spacing={4} direction="row">
          <Stack spacing={2}>
            <Typography vaient="body2">Lesson Card</Typography>
            <img
              src="https://gvlang-guides.s3.us-west-2.amazonaws.com/LessonMain.png"
              alt="Lesson List"
              height="300px"
              width="400px"
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant="body1">Add-Edit Lesson</Typography>
            <img
              src="https://gvlang-guides.s3.us-west-2.amazonaws.com/LessonAddEdit.png"
              alt="Add or Edit Lesson"
              height="500px"
              width="500px"
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
