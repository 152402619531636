import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { enqueueSnackbar } from "notistack";
import { Box, Paper, Stack } from "@mui/material";
import { LessonCard } from "../lessons";
import LessonService from "../_services/lesson.service";

export { GsQuickImport };

function GsQuickImport() {
  const cognitoUser = Auth.user.username;
  const [lessons, setLessons] = useState([]);

  const handleImport = async (selectedLesson) => {
    // the lesson from the array does not include sentences, so retrieve them
    //for the selected lesson
    const response = await API.get(
      "lessonsApi",
      `/lessons/one-lesson?id=${selectedLesson._id}`
    );
    const lesson = response.appData.lesson;

    const adjLesson = {
      ...lesson,
      ownerId: cognitoUser,
      usesSeq: lesson.sequenceDetails,
    };
    const lessonContent = JSON.stringify(
      LessonService.cloneLessonNoIds(adjLesson),
      null,
      2
    );
    const newLesson = JSON.parse(lessonContent);
    try {
      const response = await API.post("lessonsApi", `/lessons/import-lesson`, {
        body: newLesson,
      });
      if (response.error) {
        throw new Error(response.appData.message);
      }
      const newLessonName = response.appData.newLesson.name;
      enqueueSnackbar("This Lesson has been imported as " + newLessonName, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err, { variant: "error" });
    }
  };

  useEffect(() => {
    const getLessons = async () => {
      try {
        const response = await API.get("lessonsApi", `/lessons/get-gvlang`);
        if (response.error) {
          throw new Error(response.appData.message);
        }
        setLessons(
          response.appData.lessons.sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          })
        );
      } catch (err) {
        enqueueSnackbar(err, { variant: "error" });
      }
    };
    getLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper>
      <Stack
        spacing={2}
        sx={{ maxHeight: "80vh", maxWidth: "800px", overflowY: "scroll" }}
      >
        {lessons.map((lesson, index) => {
          return (
            <Box
              key={index}
              sx={{
                border: "2px solid gray",
                borderRadius: "4px",
                padding: "15px",
              }}
            >
              <LessonCard lesson={lesson} onImport={handleImport} />
            </Box>
          );
        })}
      </Stack>
    </Paper>
  );
}
