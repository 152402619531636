export const whatsInGvlang = [
  `PhraseKeeperTTS was developed for language learners who have collected ('mined') their own 
  sentences and want to use flexible text-to-speech playback to hear them.
  `,
  `TTS playback follows a sequence, a set of steps that play phrases with an order,
  repetitions and pauses you set up. A PhraseKeeperTTS sequenceGraphic (TM), like the one on the  left, 
  is a diagram of a sequence. Sentences are grouped into a lesson that uses a sequence. 
  `,
  `As you improve your listening and speaking, you can change your pattern of TTS by 
  changing the sequence a lesson uses. You may start with a simple target language (TL) 
  natural language (NL) sequence, and later change the lesson to play NL-first, then TL 
  by phrase: 1-2-3 or 3-2-1. You can change the number of reps, lengthen or shorten the 
  pauses between steps. There are about 10 pre-defined sequences, and you can create your own. `,
  `Teachers of English Language Learning (ELL) can create lessons using two English voices 
  as TL and NL. One voice plays the prompts and examples, and the other voice plays the 
  responses. You can export your lesson and then email it to students.
  `,
];

export const registrationInfo = [
  `When you create your PhraseKeeperTTS account you have a 7-day free trial with all features.
    After the free trial, you will need to renew every month, or every 200K TTS characters used,
    at a cost of $5.00 USD. Right now, there is no annual billing and no automatic billing.
    `,
  `Read the FAQ before you sign up so you better understand what is in
  and not in PhraseKeeperTTS. We hope that, after all the disclaimers, you will continue with sign-up 
  and find PhraseKeeperTTS a good way to understand and speak the languages you want to learn.
  `,
];

export const faqList = [
  {
    Q: "What does it cost? Is there a free trial?",
    A: `When you create your PhraseKeeperTTS account you have a 7-day free trial with all features.
    After the free trial, you will need to renew every month, or every 200K TTS characters used,
    at a cost of $5.00 USD. Right now, there is no annual billing and no automatic billing.`,
  },
  {
    Q: "Can you explain the TTS character limit?",
    A: `TTS means Text-to-Speech, a service PhraseKeeperTTS uses to play the audio for your sentences.
    You can play up to 200K characters in any month; when you reach 200K you will need to renew.
    Please note: Once you have played audio for a sentence or phrase,
    the audio is saved to your brower storage, and playing it again does NOT count towards your TTS total.
    In the Account window you can view your current TTS count, and you can decide to clear the browser storage.`,
  },

  {
    Q: "What personal or financial data about me do you keep?",
    A: `Only your email. PhraseKeeperTTS does not store credit card or other financial information
    about you. At each renewal, you enter this data and it is sent directly to our payment processor,
    which then tells PhraseKeeperTTS you have renewed. Billing details are not stored anywhere in PhraseKeeperTTS`,
  },
  {
    Q: "Can I practice multiple languages?",
    A: `Yes. Each lesson has its own target language and natural language, so you can have 
    lessons in many languages. The languages offered are: English, Danish, Dutch, French, German, 
    Icelandic, Italian, Polish, Swedish, Spanish and Turkish. A lesson can use non-English languages
    for TL and NL. If you are a German speaker using Italian, you can define a lesson with Italian TL 
    and German NL`,
  },
  {
    Q: "Can I load sentences in bulk, e.g. from a TXT or CSV file?",
    A: `Yes, you can do a 'bulk import' from your own source using one of three formats: (1) TAB-delimited:
     TL sentence [TAB] NL sentence or (2) single sentence:  TL sentence (NL sentence) or (3) paragraph
    : TL sentence one. TL sentence 2. (NL sentence 1. NL sentence 2.)
    `,
  },
  {
    Q: "What device types can I use with PhraseKeeperTTS?",
    A: `At this time PhraseKeeperTTS is a web version only, and can be used only on a tablet or larger size
    screen.`,
  },
  {
    Q: "What is TL and NL?",
    A: `TL is the target language, the language you are learning. NL is the natural language,
    the language you are assumed to know. The NL text is always played as a complete sentence. It
    cannot be played in parts.
    `,
  },
  {
    Q: "Does PhraseKeeperTTS do translations?",
    A: `No. You need to provide your own translations. I personally liked to put in literal translations
    so that, when I use a sequence that prompts with my NL, it gives a clue to the idiomatic structure
    of the TL. Also, you can use a free translator for a group of sentences, and import TL and NL together
    using the paragraph import format.
    `,
  },
  {
    Q: "How many sentences can I have? How long can they be?",
    A: `Maximum sentence length is 150 characters. You can have as many sentences
      in a lesson as you want, but we recommend 75 or fewer so that lesson playback
      is not too long.
    `,
  },
  {
    Q: "Can I set voice speeds and stress symbols, etc.?",
    A: `No. We use a standard TTS playback to save cost, and these extra features cannnot be used.`,
  },
  {
    Q: "Does PhraseKeeperTTS used sequenced repetition?",
    A: `No. You need to revisit your sentences yourself.`,
  },
  {
    Q: "Can I share my sentences with others?",
    A: `Yes, you can export a lesson and send the export to others, or import a lesson sent to you. 
    PhraseKeeperTTS itself does NOT share your sentences with anyone else.
    `,
  },
  {
    Q: "Can I move sentences around?",
    A: `Yes. Within a lesson, you can drag sentences up or down in the lesson. To move sentences
    between lessons, use the TRANSFER button on the SENTENCES page.
    `,
  },
  {
    Q: "Can I get a copy of all my sentences?",
    A: `Yes, you can download your sentences anytime from the ACCOUNT menu. The sentence download 
    format is a flat file with columns: lesson name, sentence origText, sentence transText, TL voice, NL voice.`,
  },
  {
    Q: "How do I select parts of a sentence, to hear just those parts?",
    A: `The sentence SPLITS break up a sentence into parts that are used in SEQUENCE PLAY. To listen on demand
    to specific words or phrases in a sentence, use FREE PLAY, where you can select any words in the sentence
    to hear them played.
    `,
  },
  {
    Q: "How do I play an entire lesson?",
    A: `Yes. In Sequence Play, click the Play All button. Each sentence will be played, starting with
    the sentence you last selected.
    `,
  },
  {
    Q: "Can I use PhraseKeeperTTS to teach ESL?",
    A: `Yes. Create a lesson with two different English voices (there are several), one for TL 
      and one for NL. You can then enter your prompts as the TL text and answers as the NL text.
      You can insert intruction sentences at any point in the lesson.
    `,
  },
  {
    Q: "What is an Instruction sentence?",
    A: `If you indicate a sentence is an instruction, it will always play just the NL, once, with 
     no pause. An instruction can be used to introduce a particular type of practice, e.g. for an
     ESL student
    `,
  },
  {
    Q: `I click Play but there is no sound. What do I do?`,
    A: `Allow Autoplay: In some browsers you may need to set PhraseKeeperTTS so it can use autoplay for TTS. 
    To do this in Safari: Right click on the URL. In the pop-up window, click 'Settings for PhraseKeeperTTS'. 
    In the next window, set the Auto-Play value to 'Allow All Autoplay'`,
  },
  {
    Q: "What happens if I leave PhraseKeeperTTS?",
    A: `We'd be sorry to see you go, but you can always downloaded your sentences and take them with
    you to use elsewhere.
    `,
  },
];

export const importantTips = {
  preferences: `You can set user preferences for: TL/NL color, pause length
  after TTS play, add sentences to beginning/end of lesson, history
  days, and page length (number of rows on each list page).
`,
  autoPlay: `Allow Autoplay: In some browsers you may need to set PhraseKeeperTTS so it
  can use autoplay for TTS. To do this in Safari: Right click on the
  URL. In the pop-up window, click 'Settings for PhraseKeeperTTS'. In the next
  window, set the Auto-Play value to 'Allow All Autoplay'
`,
  contactUs: `If you have any questions or issues with the application, please email
   us at PhraseKeeperTTS@gmail.com`,
};

export const termsOfUse = [
  `Agreement between User and PhraseKeeperTTS.com`,
  `Welcome to PhraseKeeperTTS.com. The PhraseKeeperTTS.com 
  website (the "Site") is comprised of various web pages operated by A C Nevins Database Consulting LLC ("the LLC"). 
  PhraseKeeperTTS.com is offered to you conditioned on your acceptance without modification of the terms, conditions, 
  and notices contained herein (the "Terms"). Your use of PhraseKeeperTTS.com constitutes your agreement to all 
  such Terms. Please read these terms carefully, and keep a copy of them for your reference. 
  `,
  `PhraseKeeperTTS.com is an E-Commerce Site.`,
  `This website is designed to help language learners practice listening and speaking sentences in another language, 
  following a text-to-speech sequence they set up themselves. 
  `,
  `Privacy`,
  `Your use of PhraseKeeperTTS.com is subject to the LLC's Privacy Policy. PhraseKeeperTTS collects only the email address 
  you enter as your registration username. No other personal or financial data is collected or stored on the 
  site's server. Your payments are made directly to our payment provider without passing through 
  or being saved on our servers.
  `,
  `Electronic Communications`,
  `Visiting PhraseKeeperTTS.com or sending emails to the LLC constitutes electronic communications. 
  You consent to receive electronic communications and you agree that all agreements, notices, 
  disclosures and other communications that we provide to you electronically, via email and on the Site, 
  satisfy any legal requirement that such communications be in writing. 
  `,
  `Your Account`,
  `If you use this site, you are responsible for maintaining the confidentiality of your account and password 
  and for restricting access to your computer, and you agree to accept responsibility for all activities 
  that occur under your account or password. You may not assign or otherwise transfer your account 
  to any other person or entity. You acknowledge that the LLC is not responsible for third party 
  access to your account that results from theft or misappropriation of your account. 
  the LLC and its associates reserve the right to refuse or cancel service, terminate accounts, 
  or remove or edit content in our sole discretion.
  `,
  `Your contract with PhraseKeeperTTS.com runs for each month, with $5 USD due on your monthly billing date. 
  There is no automatic billing; you must renew every month. You can choose to leave PhraseKeeperTTS at any time, 
  but the current month's payment is not refundable.
  `,
  `Children Under Thirteen`,
  `the LLC does not knowingly collect, either online or offline, personal information from persons under 
  the age of thirteen. If you are under 18, you may use PhraseKeeperTTS.com only with permission of a parent or guardian. 
  `,
  `Links to Third Party Sites/Third Party Services`,
  `PhraseKeeperTTS.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under 
  the control of the LLC and the LLC is not responsible for the contents of any Linked Site, including 
  without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. 
  the LLC is providing these links to you only as a convenience, and the inclusion of any link does not 
  imply endorsement by the LLC of the site or any association with its operators. 
  `,
  `Certain services made available via PhraseKeeperTTS.com are delivered by third party sites and organizations. 
  By using any product, service or functionality originating from the PhraseKeeperTTS.com domain, 
  you hereby acknowledge and consent that the LLC may share such information and data with any third party 
  with whom the LLC has a contractual relationship to provide the requested product, service or functionality 
  on behalf of PhraseKeeperTTS.com users and customers. 
  `,
  `No Unlawful or Prohibited Use/Intellectual Property`,
  `You are granted a non-exclusive, non-transferable, revocable license to access and use PhraseKeeperTTS.com 
  strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to 
  the LLC that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. 
  You may not use the Site in any manner which could damage, disable, overburden, or impair the Site 
  or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to 
  obtain any materials or information through any means not intentionally made available or provided 
  for through the Site. 
  `,
  `All content included as part of the Service, such as text, graphics, logos, images, as well as the 
  compilation thereof, and any software used on the Site, is the property of the LLC or its suppliers 
  and protected by copyright and other laws that protect intellectual property and proprietary rights. 
  You agree to observe and abide by all copyright and other proprietary notices, legends or other 
  restrictions contained in any such content and will not make any changes thereto. 
  `,
  `You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, 
  create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. 
  the LLC content is not for resale. Your use of the Site does not entitle you to make any unauthorized use 
  of any protected content, and in particular you will not delete or alter any proprietary rights or 
  attribution notices in any content. You will use protected content solely for your personal use, 
  and will make no other use of the content without the express written permission of the LLC 
  and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. 
  We do not grant you any licenses, express or implied, to the intellectual property of the LLC or our 
  licensors except as expressly authorized by these Terms. 
  `,
  `International Users`,
  `The Service is controlled, operated and administered by the LLC from our offices within the USA. 
  If you access the Service from a location outside the USA, you are responsible for compliance 
  with all local laws. You agree that you will not use the the LLC Content accessed through 
  PhraseKeeperTTS.com in any country or in any manner prohibited by any applicable laws, 
  restrictions or regulations. 
  `,
  `Indemnification`,
  `You agree to indemnify, defend and hold harmless the LLC, its officers, directors, employees, 
  agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) 
  relating to or arising out of your use of or inability to use the Site or services, 
  any user postings made by you, your violation of any terms of this Agreement or your violation
   of any rights of a third party, or your violation of any applicable laws, rules or regulations. 
   the LLC reserves the right, at its own cost, to assume the exclusive defense and control of 
   any matter otherwise subject to indemnification by you, in which event you will fully 
   cooperate with the LLC in asserting any available defenses. 
  `,
  `Liability Disclaimer`,
  `THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY 
  INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. 
  A C NEVINS DATABASE CONSULTING LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME. 
  `,
  `A C NEVINS DATABASE CONSULTING LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, 
  RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND 
  RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, 
  ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY 
  OR CONDITION OF ANY KIND. A C NEVINS DATABASE CONSULTING LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL 
  WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, 
  INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
  TITLE AND NON-INFRINGEMENT. 
  `,
  `TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL A C NEVINS DATABASE CONSULTING LLC 
  AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL 
  DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, 
  ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY 
  TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, 
  SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT 
  OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, 
  EVEN IF A C NEVINS DATABASE CONSULTING LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY 
  OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY 
  FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED
   WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO 
   DISCONTINUE USING THE SITE. 
  `,
  `Termination/Access Restriction`,
  `the LLC reserves the right, in its sole discretion, to terminate your access to the Site and the related 
  services or any portion thereof at any time, without notice. To the maximum extent permitted by law, 
  this agreement is governed by the laws of the Commonwealth of Massachusetts and you hereby consent 
  to the exclusive jurisdiction and venue of courts in Massachusetts in all disputes arising out of 
  or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does 
  not give effect to all provisions of these Terms, including, without limitation, this section. 
  `,
  `You agree that no joint venture, partnership, employment, or agency relationship exists between you a
  nd the LLC as a result of this agreement or use of the Site. the LLC's performance of this agreement 
  is subject to existing laws and legal process, and nothing contained in this agreement is in derogation 
  of the LLC's right to comply with governmental, court and law enforcement requests or requirements 
  relating to your use of the Site or information provided to or gathered by the LLC with respect to such use. 
  If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, 
  but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid 
  or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely 
  matches the intent of the original provision and the remainder of the agreement shall continue in effect. 
  `,
  `Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and 
  the LLC with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, 
  whether electronic, oral or written, between the user and the LLC with respect to the Site. A printed version 
  of this agreement and of any notice given in electronic form shall be admissible in judicial or 
  administrative proceedings based upon or relating to this agreement to the same extent and subject 
  to the same conditions as other business documents and records originally generated and maintained 
  in printed form. It is the express wish to the parties that this agreement and all related documents 
  be written in English. 
  `,
  `Changes to Terms`,
  `The LLC reserves the right, in its sole discretion, to change the Terms under which PhraseKeeperTTS.com 
  is offered. The most current version of the Terms will supersede all previous versions. 
  the LLC encourages you to periodically review the Terms to stay informed of our updates. 
  `,
  `Contact Us`,
  `the LLC welcomes your questions or comments regarding the Terms: `,
  `A C Nevins Database Consulting LLC`,

  `Email Address: `,
  `PhraseKeeperTTS@gmail.com`,

  `Effective as of April 28, 2024`,
];
