// business logic for User area

function aboutMe() {
  return "Placeholder for user services";
}

export function getIsPaid(user) {
  const maxTTS = 200000;
  if (user === null) {
    return false;
  }
  if (user.account.ttsCharUsed > maxTTS) {
    return false;
  }
  let isPaid = false;
  const graceDays = 7;
  const dtToday = new Date();
  const dtJoined = new Date(user.account.dateJoined);
  dtJoined.setDate(dtJoined.getDate() + graceDays);
  if (dtJoined >= dtToday) {
    isPaid = true;
  }
  const dtRenewedThru = user.account.dateRenewedThru
    ? new Date(user.account.dateRenewedThru)
    : null;
  if (dtRenewedThru) {
    dtRenewedThru.setDate(dtRenewedThru.getDate() + graceDays);
    if (dtRenewedThru >= dtToday) {
      isPaid = true;
    }
  }
  return isPaid;
}

const UserService = {
  aboutMe,
  getIsPaid,
};

export default UserService;
