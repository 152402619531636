import React, { useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import { enqueueSnackbar } from "notistack";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import SourceIcon from "@mui/icons-material/Source";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { LessonSelector } from "../lessons";
import LessonService from "../_services/lesson.service";
import { useTheme } from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export { SentenceTransfer };

function SentenceTransfer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [focusOn, setFocusOn] = useState("left");
  const user = JSON.parse(localStorage.getItem("gvLangUser"));

  // modals
  const [showLessonDialog, setShowLessonDialog] = useState(false);

  const [lessonL, setLessonL] = useState(null);
  const [checkedItemsL, setCheckedItemsL] = useState([]);
  const [listItemsL, setListItemsL] = useState([]);
  const [checkedAllL, setCheckedAllL] = useState(false);

  const [lessonR, setLessonR] = useState(null);
  const [checkedItemsR, setCheckedItemsR] = useState([]);
  const [listItemsR, setListItemsR] = useState([]);
  const [checkedAllR, setCheckedAllR] = useState(false);

  function toggleLessonModal() {
    setShowLessonDialog(!showLessonDialog);
  }

  async function handleSaveAll() {
    try {
      if (lessonL) {
        const respL = await API.put(
          "lessonsApi",
          `/lessons/set-sentences?id=${lessonL._id}`,
          {
            body: { sentences: listItemsL },
          }
        );
        if (respL.error) {
          throw new Error(respL.appData.message);
        }
      }
      if (lessonR) {
        const respR = await API.put(
          "lessonsApi",
          `/lessons/set-sentences?id=${lessonR._id}`,
          {
            body: { sentences: listItemsR },
          }
        );
        if (respR.error) {
          throw new Error(respR.appData.message);
        }
      }
      enqueueSnackbar("All changes saved", { variant: "success" });
    } catch (err) {
      enqueueSnackbar(err, { variant: "error" });
    }
  }

  async function handleLessonSelect(lesson) {
    const response = await API.get(
      "lessonsApi",
      `/lessons/one-lesson?id=${lesson._id}`
    );
    const selectedLesson = response.appData.lesson;
    toggleLessonModal();
    if (focusOn === "left") {
      if (selectedLesson._id === lessonR?._id) {
        enqueueSnackbar("The two lessons must be different", {
          variant: "error",
        });
        return -1;
      }
      setLessonL(selectedLesson);
      setListItemsL(selectedLesson.sentences);
      if (LessonService.checkSameLanguage(selectedLesson, lessonR) < 0) {
        enqueueSnackbar("These lessons do NOT use the same language", {
          variant: "warning",
        });
      }
    } else {
      if (selectedLesson._id === lessonL?._id) {
        enqueueSnackbar("The two lessons must be different", {
          variant: "error",
        });
        return -1;
      }
      setLessonR(selectedLesson);
      setListItemsR(selectedLesson.sentences);
      if (LessonService.checkSameLanguage(selectedLesson, lessonL) < 0) {
        enqueueSnackbar("These lessons do NOT use the same language", {
          variant: "warning",
        });
      }
    }
  }

  function handleMoveTo(toSide) {
    // move left selected items to right
    if (toSide === "right" && checkedItemsL.length) {
      if (!!lessonR) {
        const arr = listItemsL.filter((item) => {
          return (
            checkedItemsL.indexOf(item._id) > -1 &&
            listItemsR.indexOf(item._id === -1)
          );
        });
        handleDeleteItems("left");
        if (user.preferences.addSentenceTo === "beg") {
          setListItemsR([...arr, ...listItemsR]);
        } else {
          setListItemsR([...listItemsR, ...arr]);
        }
      } else {
        enqueueSnackbar("No list selected to move to", { variant: "warning" });
      }
    }
    // move left selected items to left
    if (toSide === "left" && checkedItemsR.length) {
      if (!!lessonL) {
        const arr = listItemsR.filter((item) => {
          return (
            checkedItemsR.indexOf(item._id) > -1 &&
            listItemsL.indexOf(item._id === -1)
          );
        });
        handleDeleteItems("right");
        if (user.preferences.addSentenceTo === "beg") {
          setListItemsL([...arr, ...listItemsL]);
        } else {
          setListItemsL([...listItemsL, ...arr]);
        }
      } else {
        enqueueSnackbar("No list selected to move to", { variant: "warning" });
      }
    }
  }

  function handleDeleteItems(side) {
    let arr = [];
    if (side === "left" && checkedItemsL.length) {
      arr = listItemsL.filter((item) => {
        return checkedItemsL.indexOf(item._id) === -1;
      });
      setListItemsL(arr);
    }
    if (side === "right" && checkedItemsR.length) {
      arr = listItemsR.filter((item) => {
        return checkedItemsR.indexOf(item._id) === -1;
      });
      setListItemsR(arr);
    }
    resetChecked(side);
  }

  function resetChecked(sides) {
    if (sides.includes("left") > -1) {
      setCheckedItemsL([]);
      setCheckedAllL(false);
    }
    if (sides.includes("right") > -1) {
      setCheckedItemsR([]);
      setCheckedAllR(false);
    }
  }

  function toggleList() {
    setFocusOn(focusOn === "left" ? "right" : "left");
  }

  const handleCheckAll = (side) => {
    let arr = [];
    if (side === "left") {
      const newValue = !checkedAllL;
      if (newValue) {
        arr = listItemsL.map((item) => item._id);
      }
      setCheckedAllL(newValue);
      setCheckedItemsL(arr);
    }
    if (side === "right") {
      const newValue = !checkedAllR;
      if (newValue) {
        arr = listItemsR.map((item) => item._id);
      }
      setCheckedAllR(newValue);
      setCheckedItemsR(arr);
    }
  };

  function handleItemClick(side, id) {
    if (side === "left") {
      const currentIndex = checkedItemsL.indexOf(id);
      const newChecked = [...checkedItemsL];

      if (currentIndex === -1) {
        newChecked.push(id);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setCheckedItemsL([...newChecked]);
    }
    if (side === "right") {
      const currentIndex = checkedItemsR.indexOf(id);
      const newChecked = [...checkedItemsR];

      if (currentIndex === -1) {
        newChecked.push(id);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setCheckedItemsR([...newChecked]);
    }
  }

  const topButtonsJsx = (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        backgroundColor: theme.palette.blues.light,
        width: "100%",
        padding: "0px 10px",
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Button
          disabled={focusOn !== "left"}
          onClick={toggleLessonModal}
          variant="text"
          size="small"
          startIcon={<SourceIcon />}
        >
          Select
        </Button>
        <Box
          sx={{
            padding: "10px",
            backgroundColor: focusOn === "left" ? "gray" : "inherit",
            color: focusOn === "left" ? "white" : "black",
            typography: "body2",
            borderRadius: "6px",
          }}
        >
          {lessonL?.name}
        </Box>
      </Stack>
      <Button
        variant="text"
        size="small"
        onClick={() => toggleList()}
        startIcon={<MultipleStopIcon />}
      >
        Active lesson
      </Button>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box
          sx={{
            padding: "10px",
            backgroundColor: focusOn === "right" ? "gray" : "inherit",
            color: focusOn === "right" ? "white" : "black",
            typography: "body2",
            borderRadius: "6px",
          }}
        >
          {lessonR?.name}
        </Box>
        <Button
          disabled={focusOn !== "right"}
          onClick={toggleLessonModal}
          variant="text"
          size="small"
          startIcon={<SourceIcon />}
        >
          Select
        </Button>
      </Stack>
    </Box>
  );

  const leftBoxJsx = (
    <Box sx={{ height: "100%", backgroundColor: "white" }}>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          alignItems: "center",
          height: "25px",
          padding: "20px",
          marginBottom: "10px",
        }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Checkbox
            {...label}
            disabled={focusOn !== "left"}
            checked={checkedAllL}
            onChange={() => handleCheckAll("left")}
            sx={{ padding: "3px" }}
          />
          <Typography variant="body2">
            {!checkedAllL ? "Select all" : "De-select all"}
          </Typography>
        </Stack>

        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => handleDeleteItems("left")}
          disabled={focusOn !== "left"}
        >
          Delete selected
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => handleMoveTo("right")}
          disabled={focusOn !== "left"}
          endIcon={<KeyboardDoubleArrowRightIcon />}
        >
          Move selected to right
        </Button>
      </Stack>
      <Divider />
      <Stack
        sx={{
          maxHeight: "80%",
          overflowY: "scroll",
          backgroundColor: "white",
        }}
      >
        {listItemsL &&
          listItemsL.map((item) => {
            return (
              <Box
                key={item._id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "1px 5px 1px 5px",
                  margin: "3px",
                  borderRadius: "8px",
                }}
              >
                <Checkbox
                  edge="start"
                  disabled={focusOn !== "left"}
                  checked={checkedItemsL.indexOf(item._id) !== -1}
                  onChange={() => handleItemClick("left", item._id)}
                />
                <Typography variant="body2">{item.origText}</Typography>
              </Box>
            );
          })}
      </Stack>
    </Box>
  );

  const rightBoxJsx = (
    <Box sx={{ height: "100%", backgroundColor: "white" }}>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          alignItems: "center",
          height: "25px",
          padding: "20px",
          marginBottom: "10px",
        }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Checkbox
            {...label}
            disabled={focusOn !== "right"}
            checked={checkedAllR}
            onChange={() => handleCheckAll("right")}
            sx={{ padding: "3px" }}
          />
          <Typography variant="body2">
            {!checkedAllR ? "Select all" : "De-select all"}
          </Typography>
        </Stack>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => handleMoveTo("left")}
          disabled={focusOn !== "right"}
          startIcon={<KeyboardDoubleArrowLeftIcon />}
        >
          Move selected to left
        </Button>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => handleDeleteItems("right")}
          disabled={focusOn !== "right"}
        >
          Delete selected
        </Button>
      </Stack>
      <Divider />
      <Stack
        sx={{
          maxHeight: "80%",
          overflowY: "scroll",
          backgroundColor: "white",
        }}
      >
        {listItemsR &&
          listItemsR.map((item) => {
            return (
              <Box
                key={item._id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "1px 5px 1px 5px",
                  margin: "3px",
                  borderRadius: "8px",
                }}
              >
                <Checkbox
                  edge="start"
                  disabled={focusOn !== "right"}
                  checked={checkedItemsR.indexOf(item._id) !== -1}
                  onChange={() => handleItemClick("right", item._id)}
                />
                <Typography variant="body2">{item.origText}</Typography>
              </Box>
            );
          })}
      </Stack>
    </Box>
  );

  return (
    <Paper sx={{ padding: "3px", height: "100vh" }}>
      <Box sx={{ height: "7%" }}>
        <Box
          typography="h6"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px 50px",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Typography>Sentence Transfer</Typography>

          <Button variant="contained" size="small" onClick={handleSaveAll}>
            Save all changes
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", height: "7%" }}>{topButtonsJsx}</Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{ backgroundColor: theme.palette.blues.main, height: "80%" }}
      >
        <Box sx={{ width: "50%", padding: "10px" }}>{leftBoxJsx}</Box>
        <Box sx={{ width: "50%", padding: "10px" }}>{rightBoxJsx}</Box>
      </Stack>
      <Box
        sx={{ height: "5%", backgroundColor: theme.palette.neutral.light }}
      ></Box>
      <Dialog
        open={showLessonDialog}
        onClose={toggleLessonModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Click a lesson to select it:
        </DialogTitle>
        <DialogContent>
          <LessonSelector
            seqWidth="75%"
            seqFont="0.6rem"
            onSelect={handleLessonSelect}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleLessonModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
