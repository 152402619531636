import React, { useState } from "react";
import { getGVsValue, setGVsValue } from "../_helpers/idb";

export { TestIndexedDb };

function checksum(s) {
  var chk = 0x12345678;
  var len = s.length;
  for (var i = 0; i < len; i++) {
    chk += s.charCodeAt(i) * (i + 1);
  }
  return (chk & 0xffffffff).toString(16);
}

// function getIDBValue(idKey) {
//   return new Promise((resolve) => {
//     const storeName = "Gvs";
//     const request = window.indexedDB.open("gvLang", 1);
//     request.onupgradeneeded = (event) => {
//       const db = event.target.result;
//       const gvStore = db.createObjectStore(storeName, { keyPath: "id" });
//       gvStore.createIndex("gvDate", "date");
//     };
//     request.onsuccess = () => {
//       const db = request.result;
//       const tx = db.transaction(storeName, "readonly");
//       const store = tx.objectStore(storeName);
//       const res = store.get(idKey);
//       res.onsuccess = () => {
//         resolve(res.result);
//       };
//     };
//   });
// }

// function setIDBValue(val) {
//   const storeName = "Gvs";
//   const idVal = checksum(val);
//   const request = window.indexedDB.open("gvLang", 1);
//   request.onupgradeneeded = (event) => {
//     const db = event.target.result;

//     const gvStore = db.createObjectStore(storeName, { keyPath: "id" });
//     gvStore.createIndex("gvDate", "date");
//   };
//   request.onsuccess = () => {
//     const db = request.result;
//     const tx = db.transaction(storeName, "readwrite");
//     const gvStore = tx.objectStore(storeName);
//     gvStore.add({ id: idVal, origText: val, date: Date() });
//     tx.oncomplete = () => {
//       db.close();
//     };
//   };
// }

function TestIndexedDb() {
  const [value, setValue] = useState("");

  if (!window.indexedDB) {
    console.log(`Your browser doesn't support IndexedDB`);
    return;
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log("submitted: " + value);
    const idKey = checksum(value);
    const myGvs = {
      id: idKey,
      origText: value,
      uInt8Val: "some uint8 value",
      date: Date(),
    };
    setGVsValue(myGvs);
  }

  function handleNewValueChange(e) {
    setValue(e.target.value);
  }

  async function handleRead() {
    const idKey = checksum(value);
    const myObj = await getGVsValue(idKey);
    const v = JSON.stringify(myObj, null, 2);
    console.log("Value read", v);
    setValue(v);
  }

  return (
    <React.Fragment>
      <div>TestIndexedDb</div>
      <form onSubmit={handleSubmit}>
        <input
          placeholder="new value"
          name="newValue"
          type="text"
          value={value}
          onChange={handleNewValueChange}
        />
        <button type="submit">Submit</button>
        <button type="button" onClick={handleRead}>
          Read value
        </button>
      </form>
      <div>
        <p>Value read:</p>
        <p>{value}</p>
      </div>
    </React.Fragment>
  );
}
