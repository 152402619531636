import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
//import { UserContextProvider } from "./_context/UserContext";

import App from "./App/App";
import { Welcome } from "./App";
import { Login, Register } from "./auth";
import { Account, Preferences, Admin } from "./account";
import {
  SentenceWorkbook,
  SentenceImport,
  SentencePractice,
  SentenceSequencePlay,
  SentenceTransfer,
  SentenceFreePlay,
  SentenceReview,
} from "./sentences";
import { HistoryMRT } from "./account";
import { LessonAddEdit, LessonMain, LessonImport } from "./lessons";
import { SequenceWorkbook } from "./sequences";
import { UserList } from "./users";
import { GettingStarted } from "./guides";
import { TestIndexedDb } from "./_archive/TestIndexedDb";

import Expenses from "./App/Expenses";
import Invoices from "./App/Invoices";

const theme = createTheme({
  typography: {
    fontFamily: ["Jost", "cursive"].join(","),
    body2: {
      fontSize: 14,
    },
  },
  palette: {
    primary: {
      light: "#6573c3",
      main: "#3f50b5",
      dark: "#2c387e",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffa733",
      main: "#ff9100",
      dark: "#b26500",
      contrastText: "#fff",
    },
    neutral: {
      main: "#64748B",
      light: "#f5f5f5",
      dark: "#37404e",
      contrastText: "#ffffff",
    },
    custom: {
      light: "#e5ee9c",
      main: "#ccdc39",
      dark: "#817717",
      contrastText: "#fff",
    },
    blues: {
      light: "#cfd8dc",
      main: "#90a4ae",
      dark: "#455a64",
      contrastText: "#fff",
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: { height: "20px", width: "20px" },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: { padding: "2px", fontSize: "12px" },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          padding: "2px",
          fontSize: "1.25rem",
        },
        root: {
          margin: "5px",
          padding: "3px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "2px",
          fontSize: "0.9rem",
        },
      },
    },
  },
});

const rootElement = document.getElementById("root");

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        {/* public routes */}
        <Route path="/" element={<Welcome />} />
        <Route path="/welcome" element={<Welcome />} />
        {/* protected routes */}
        <Route path="/app" element={<App />}>
          <Route path="preferences" element={<Preferences />} />
          <Route path="expenses" element={<Expenses />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="testindexeddb" element={<TestIndexedDb />} />
          <Route path="gettingstarted" element={<GettingStarted />} />
          <Route path="lessons">
            <Route index element={<LessonMain />} />
            <Route path="add" element={<LessonAddEdit />} />
            <Route path="edit/:id" element={<LessonAddEdit />} />
            <Route path="import" element={<LessonImport />} />
            <Route path="seq/:id" element={<SentenceSequencePlay />} />
            <Route path="free/:id" element={<SentenceFreePlay />} />
            <Route path="review/:id" element={<SentenceReview />} />
            <Route path="sentences/:id">
              <Route index element={<SentenceWorkbook />} />
              <Route path="import" element={<SentenceImport />} />
              <Route path="transfer" element={<SentenceTransfer />} />
            </Route>
          </Route>
          <Route path="history" element={<HistoryMRT />} />
          <Route path="sequences" element={<SequenceWorkbook />} />
          <Route path="practice">
            <Route index element={<SentencePractice />} />
          </Route>
          <Route path="account" element={<Account />} />
          <Route path="users" element={<UserList />} />
        </Route>
        <Route path="admin" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  rootElement
);
