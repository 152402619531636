import React, { useState } from "react";
import { Auth, API } from "aws-amplify";

import { Button, Paper, Stack, Box, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import AddCardIcon from "@mui/icons-material/AddCard";

import { SequenceSelector, SequenceAddEdit } from "../sequences";
import { DialogConfirm } from "../App";
import { WindowTitle } from "../_helpers/gvlang-components";

export { SequenceWorkbook };

function SequenceWorkbook() {
  const theme = useTheme();
  const cognitoUser = Auth.user.username;
  const dialogInitialState = {
    open: false,
    title: "",
    text: "",
    targetId: null,
    buttons: "",
  };

  const [selectedSequence, setSelectedSequence] = useState(null);
  const [changeCount, setChangeCount] = useState(0);

  const [dialog, setDialog] = useState(dialogInitialState);

  function handleNewClicked() {
    setSelectedSequence(null);
    const count = changeCount + 1;
    setChangeCount(count);
    window.scrollTo(0, 0);
  }

  function displayError(err) {
    if (err.response) {
      enqueueSnackbar(err.response.data.errors.join(", "), {
        variant: "error",
      });
    } else {
      enqueueSnackbar(err, {
        variant: "error",
      });
    }
  }

  function selectSequence(s) {
    setSelectedSequence(s);
    window.scrollTo(0, 0);
  }

  async function addSequence(data) {
    const bodyData = { ...data, ownerId: cognitoUser };
    let resp = "initial";
    try {
      resp = await API.post("sequencesApi", "/sequences/one-sequence", {
        body: bodyData,
      });
      const count = changeCount + 1;
      setChangeCount(count);
      setSelectedSequence(null);
      if (!resp.error) {
        enqueueSnackbar("Sequence added", {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        displayError(resp.appData.message);
      }
    } catch (err) {
      enqueueSnackbar(JSON.stringify(err, null, 2), {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }

  async function updateSequence(id, data) {
    try {
      const resp = await API.put(
        "sequencesApi",
        `/sequences/one-sequence?id=${id}`,
        {
          body: data,
        }
      );
      const count = changeCount + 1;
      setChangeCount(count);
      setSelectedSequence(null);
      if (resp.error) {
        throw new Error(resp.appData.message);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }

  async function deleteSequence(sequence) {
    let lessons = [];
    try {
      const response = (lessons = await API.get(
        "sequencesApi",
        `/sequences/get-sequence-lessons?id=${sequence._id}`
      ));
      lessons = response.appData.lessons;
      if (lessons.length > 0) {
        let names = [];
        lessons.map((l) => {
          return names.push(l.name);
        });
        setDialog({
          open: true,
          title: `Delete Sequence: ${sequence.name}`,
          text: `Delete not allowed. This sequence is used by ${
            lessons.length
          } ${lessons.length > 1 ? "lessons" : "lesson"}: ${names.join(",")}`,
          targetId: sequence._id,
          buttons: "Cancel",
        });
      } else {
        setDialog({
          open: true,
          title: `Delete Sequence: ${sequence.name}`,
          text: `Are you sure you want to delete this sequence?`,
          targetId: sequence._id,
          buttons: "OK,Cancel",
        });
      }
    } catch (err) {
      displayError(err);
    }
  }

  async function handleDialogClose(value) {
    if (value === "OK") {
      if (dialog.targetId) {
        try {
          await API.del(
            "sequencesApi",
            `/sequences/one-sequence?id=${dialog.targetId}`
          );
          enqueueSnackbar("Sequence deleted", { variant: "success" });
        } catch (err) {
          displayError(err);
        }
      }
      const count = changeCount + 1;
      setChangeCount(count);
    }
    setDialog(dialogInitialState);
  }

  return (
    <Paper sx={{ height: "90vh" }}>
      <Stack
        direction="row"
        sx={{
          height: "5%",
          padding: "5px",
          margin: "8px",
          gap: "60px",
          minHeight: "40px",
        }}
      >
        <WindowTitle>Sequences</WindowTitle>
        <Box sx={{ width: "30%" }}>
          <Button
            onClick={handleNewClicked}
            variant="outlined"
            size="small"
            startIcon={<AddCardIcon />}
          >
            New sequence
          </Button>
        </Box>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        ml="10px"
        sx={{ height: "80%", backgroundColor: theme.palette.neutral.light }}
      >
        <Box width="35%">
          <SequenceSelector
            showFilter={false}
            maxWidth={false}
            seqFont="0.75rem"
            onSelect={selectSequence}
            changeCount={changeCount}
          />
        </Box>
        <Box
          sx={{
            width: "60%",
            padding: "10px",
            backgroundColor: theme.palette.neutral.light,
            marginTop: "20px",
          }}
        >
          <SequenceAddEdit
            sequence={selectedSequence}
            changeCount={changeCount}
            onAdd={addSequence}
            onUpdate={updateSequence}
            onDelete={deleteSequence}
          />
        </Box>
      </Stack>
      <DialogConfirm
        onClose={handleDialogClose}
        open={dialog.open}
        dialogTitle={dialog.title}
        dialogText={dialog.text}
        dialogButtons={dialog.buttons}
      />
    </Paper>
  );
}
