import { Fragment, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Paper,
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Button,
  Slider,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { WindowTitle } from "../_helpers/gvlang-components";

import { Auth, API } from "aws-amplify";

const pageSizes = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 25, label: "25" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const addToPosition = [
  { value: "beg", label: "Beginning of lesson" },
  { value: "end", label: "End of lesson" },
];

export { Preferences };

function Preferences() {
  const [preferences, setPreferences] = useState(null);

  // form validation rules
  const validationSchema = Yup.object().shape({
    origColor: Yup.string().required("Target language color is required"),
    transColor: Yup.string().required("Natural language color is required"),
    pauseInterval: Yup.string().required("Pause Interval is required"),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      origColor: "#dc143c",
      transColor: "#008b8b",
      pauseInterval: 50,
      goBackDays: 30,
      defaultSourcedFrom: "none",
      openToPage: "/lessons",
      addSentenceTo: "beg",
      pageSize: 10,
    },
  };

  // get functions to build form with useForm() hook
  const { control, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isDirty } = formState;

  async function onFormSubmit(values) {
    const cognitoUser = Auth.user.username;
    const email = Auth.user.attributes.email;

    try {
      await API.put("usersApi", `/users/one-user?id=${cognitoUser}`, {
        body: { email: email, preferences: values },
      });
      let user = JSON.parse(localStorage.getItem("gvLangUser"));
      user = { ...user, preferences: values };
      localStorage.setItem("gvLangUser", JSON.stringify(user));
      enqueueSnackbar("User preferences saved", {
        variant: "success",
        autoHideDuration: 2000,
      });
      reset({
        ...values,
        pauseInterval: Number(values.pauseInterval),
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const cognitoUser = Auth.user.attributes.sub;
    const getPreferences = async () => {
      try {
        const response = await API.get(
          "usersApi",
          `/users/one-user?id=${cognitoUser}`
        );
        if (response.error === false) {
          setPreferences(response.appData.preferences);
        } else {
          enqueueSnackbar(response.appData.message, {
            variant: "error",
          });
        }
      } catch (err) {
        console.log("preferences useEffect", err);
      }
    };

    getPreferences();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(preferences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences]);

  return (
    preferences && (
      <Fragment>
        <Paper>
          <Box px={3} py={2}>
            <WindowTitle sx={{ width: "250px", marginBottom: "15px" }}>
              Settings for User
            </WindowTitle>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Grid
                container
                sx={{ width: "500px", marginTop: "20px" }}
                spacing={3}
              >
                <Grid item xs={6} sm={6}>
                  <Controller
                    name="origColor"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="origColor"
                          fullWidth
                          type="color"
                          label="Color for target language"
                        />
                      );
                    }}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.origColor?.message}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Controller
                    name="transColor"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="transColor"
                          type="color"
                          fullWidth
                          label="Color for natural language"
                        />
                      );
                    }}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.transColor?.message}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Controller
                    name="pageSize"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="pageSize"
                          select
                          fullWidth
                          label="Page size in lists"
                          error={Boolean(errors.transVoice)}
                        >
                          {pageSizes.map((size) => (
                            <MenuItem key={size.value} value={size.value}>
                              {size.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Controller
                    name="addSentenceTo"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="addSentenceTo"
                          select
                          fullWidth
                          label="Add new sentences to:"
                          error={Boolean(errors.transVoice)}
                        >
                          {addToPosition.map((pos) => (
                            <MenuItem key={pos.value} value={pos.value}>
                              {pos.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="caption">
                    Specify a longer or shorter interval between sentence
                    repetition
                  </Typography>
                  <Controller
                    name="pauseInterval"
                    control={control}
                    defaultValue={[0, 100]}
                    render={({ field }) => {
                      return (
                        <Slider
                          {...field}
                          id="pauseInterval"
                          value={parseInt(field.value)}
                          label="Pause Interval"
                          valueLabelDisplay="on"
                          size="small"
                          step={5}
                          min={0}
                          max={100}
                          marks={true}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="goBackDays"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="goBackDays"
                          width="300px"
                          type="number"
                          size="small"
                          InputProps={{
                            inputProps: {
                              min: 1,
                              max: 1000,
                              style: { textAlign: "center" },
                            },
                          }}
                          fullWidth
                          label="History days to retrieve: "
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Controller
                    name="defaultSourcedFrom"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="defaultSourcedFrom"
                          fullWidth
                          label="Sourced from (default): "
                        />
                      );
                    }}
                  />
                </Grid>
                <Box width="100%" />
                <Grid item xs={6} sm={6}>
                  <Button
                    type="primary"
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit(onFormSubmit)}
                  >
                    Save Changes
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {isDirty && (
                    <Typography
                      variant="caption"
                      sx={{ color: "maroon", fontWeight: 700 }}
                    >
                      There are unsaved changes
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Fragment>
    )
  );
}
