import React, { useEffect, useState } from "react";
import { Auth, API } from "aws-amplify";

import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";
import { createFilterOptions } from "@mui/material";

import { pollyVoices } from "../_helpers/polly-voices";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import Draggable from "react-draggable";
import { useTheme } from "@mui/material";
import { SequenceDisplay, SequenceSelector } from "../sequences";
import LessonService from "../_services/lesson.service";
import { getIsPaid } from "../_services/user.service";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

// TODO: history items

export { LessonAddEdit };

function LessonAddEdit() {
  const theme = useTheme();
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("gvLangUser"));
  const isPaid = getIsPaid(user);
  const mode = { add: !id, edit: !!id };
  const navigate = useNavigate();
  const cognitoUser = Auth.user.username;

  const [lesson, setLesson] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [count, setCount] = useState(0);
  const [courses, setCourses] = useState(["aaa", "bbb"]);
  const [showNL, setShowNL] = useState(true);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });

  function toggleNL() {
    setShowNL(!showNL);
  }

  function toggleModal() {
    setCount(count + 1);
    setShowDialog(!showDialog);
  }

  function handleSeqSelect(s) {
    setValue("usesSeq", s, { shouldDirty: true });
    clearErrors("usesSeq");
    toggleModal();
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Lesson name is required")
      .min(5, "Lesson name must be 5-100 char")
      .max(100, "Lesson name must be 5-100 char"),
    course: Yup.string().max(60, "Lesson course - 60 char max"),
    origVoice: Yup.string()
      .required("Target language voice is required")
      .min(5, "Target language voice: too Short!")
      .max(100, "Target language voice; too long"),
    transVoice: Yup.string()
      .required("Natural language voice is required")
      .min(5, "natural language voice too Short!")
      .max(100, "Too Long!"),
    usesSeq: Yup.object().typeError(
      "Please select a sequence - sequence is required"
    ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      course: "",
      notes: "",
      origVoice: "",
      transVoice: "",
      sentences: [],
      usesSeq: null,
    },
  };

  // get functions to build form with useForm() hook
  const {
    control,
    clearErrors,
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState,
  } = useForm(formOptions);
  const { errors, isSubmitting, isDirty } = formState;
  const usesSeq = watch("usesSeq");

  useEffect(() => {
    const getLesson = async () => {
      try {
        const resp = await API.get(
          "lessonsApi",
          `/lessons/get-courses?ownerid=${cognitoUser}`
        );
        const apiCourses = resp.appData.courses.map((course) => {
          return course || "";
        });
        setCourses(apiCourses);
        if (id) {
          const response = await API.get(
            "lessonsApi",
            `/lessons/one-lesson?id=${id}`
          );
          setLesson(response.appData.lesson);
        }
      } catch (err) {
        if (err.response) {
          enqueueSnackbar(err.response.data.errors.join(", "), {
            variant: "error",
          });
        }
      }
    };
    getLesson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(lesson);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson]);

  async function onExport() {
    const dateAdded = new Date().toISOString();
    const tsDate = new Date();
    tsDate.setHours(0, 0, 0);
    const filename =
      lesson.name.replace(/[/\\?%*:|"<>]/g, "-") + " " + dateAdded + ".txt";
    const content = JSON.stringify(
      LessonService.cloneLessonNoIds(lesson),
      null,
      2
    );
    const link = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    link.href = URL.createObjectURL(file);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
    const historyItem = {
      ownerId: cognitoUser,
      lessonName: lesson.name,
      tsDate: tsDate.toISOString(),
      dateAdded: dateAdded,
      objectType: "lesson",
      actionTaken: "exported",
      origText: "",
      origVoice: "",
      transText: "",
      transVoice: "",
    };
    await API.post(`historyApi`, `/history/add-item`, { body: historyItem });
    enqueueSnackbar("Lesson exported to downloads as " + filename, {
      variant: "success",
    });
  }

  function onSubmit(data) {
    return mode.add ? onAdd(data) : onUpdate(lesson, data);
  }

  async function onAdd(data) {
    const bodyData = { ...data, ownerId: cognitoUser };
    const response = await API.post("lessonsApi", "/lessons/one-lesson", {
      body: bodyData,
    });
    if (response.error === false) {
      navigate("/app/lessons");
    } else {
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
  }

  async function onUpdate(lesson, data) {
    const bodyData = { ...data, ownerId: cognitoUser, lessonName: lesson.name };
    try {
      await API.put("lessonsApi", `/lessons/one-lesson?id=${lesson._id}`, {
        body: bodyData,
      });
      navigate("/app/lessons");
    } catch (err) {
      if (err.response) {
        enqueueSnackbar(err.response.data.errors.join(", "), {
          variant: "error",
        });
      }
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "40ch" },
        height: "90vh",
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        typography="h6"
        sx={{
          display: "flex",
          alignItems: "center",
          height: "5%",
          paddingLeft: "15px",
        }}
      >
        {mode.add ? "Add Lesson" : "Edit Lesson"}
      </Box>
      <Stack
        direction="row"
        spacing={3}
        sx={{ backgroundColor: theme.palette.blues.light, height: "90%" }}
      >
        <Stack
          spacing="5px"
          sx={{
            backgroundColor: "white",
            padding: "5px",
            marginTop: "10px",
            marginLeft: "15px",
            borderRadius: "6px",
            height: "95%",
            width: "55%",
          }}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Name is required",
            }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="name"
                  size="small"
                  label="Name"
                  error={Boolean(errors.name)}
                  InputProps={{
                    sx: {
                      fontSize: "14px",
                    },
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="course"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                freeSolo
                options={courses}
                onChange={(event, values) => onChange(values)}
                value={value}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Course"
                    variant="outlined"
                    onChange={onChange}
                    InputProps={{
                      ...params.InputProps,
                      sx: { fontSize: "14px" },
                    }}
                  />
                )}
              />
            )}
          />
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            <Controller
              name="origVoice"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    id="origVoice"
                    size="small"
                    InputProps={{
                      ...field.InputProps,
                      sx: { fontSize: "14px" },
                    }}
                    select
                    label="Target language voice"
                    error={Boolean(errors.origVoice)}
                  >
                    {pollyVoices.map((voice) => (
                      <MenuItem key={voice.value} value={voice.value}>
                        {voice.label}
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />
            <Box
              sx={{
                width: 30,
                height: 30,
                backgroundColor: user.preferences.origColor || "#595ea1",
              }}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-start"
            sx={{ alignItems: "center" }}
          >
            <Controller
              name="transVoice"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    id="transVoice"
                    size="small"
                    InputProps={{
                      sx: {
                        fontSize: "14px",
                      },
                    }}
                    select
                    label="Natural language voice"
                    error={Boolean(errors.transVoice)}
                  >
                    {pollyVoices.map((voice) => (
                      <MenuItem key={voice.value} value={voice.value}>
                        {voice.label}
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />
            <Box
              sx={{
                width: 30,
                height: 30,
                textAlign: "left",
                backgroundColor: user.preferences.transColor || "#969c3a",
              }}
            />
          </Stack>
          <Stack direction="row" spacing={6}>
            <Box
              display="flex"
              sx={{ paddingLeft: "10px", paddingRight: "20px" }}
            >
              <Stack
                direction="column"
                sx={{
                  alignItems: "top",
                  borderRadius: "4px",
                }}
              >
                <Box>
                  <Button
                    variant="text"
                    size="small"
                    onClick={toggleModal}
                    startIcon={<FolderOpenOutlinedIcon />}
                  >
                    Sequence
                  </Button>
                </Box>
                <Box>
                  <input
                    type="hidden"
                    name="usesSeq"
                    {...register("usesSeq")}
                  />
                  {usesSeq && Object.keys(usesSeq).length > 0 && (
                    <SequenceDisplay
                      id={usesSeq._id}
                      seqName={usesSeq.name}
                      seqText={usesSeq.seqText}
                      seqWidth="100px"
                      seqFont="0.65rem"
                    />
                  )}
                </Box>
              </Stack>
            </Box>
            <Controller
              name="notes"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    id="notes"
                    label="Notes"
                    multiline
                    maxRows={10}
                    minRows={6}
                    style={{
                      width: 450,
                      marginTop: "15px",
                      marginBottom: "20px",
                    }}
                    inputProps={{
                      style: { fontSize: "0.8rem" },
                    }}
                    error={Boolean(errors.notes)}
                  />
                );
              }}
            />
          </Stack>

          <Stack direction="row" spacing={3} sx={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              size="small"
              type="submit"
              disabled={isSubmitting}
              startIcon={<SaveOutlinedIcon />}
              sx={{ marginLeft: "10px" }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => navigate("/app/lessons")}
              startIcon={<CancelOutlinedIcon />}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => onExport()}
              startIcon={<IosShareOutlinedIcon />}
              disabled={!isPaid || !lesson?.sentences.length}
            >
              Export Lesson
            </Button>
          </Stack>
          {isDirty && (
            <Typography
              variant="caption"
              sx={{ color: "maroon", fontWeight: 700 }}
            >
              There are unsaved changes
            </Typography>
          )}
          {Object.entries(errors).map((entry) => {
            const [key, value] = entry;
            return (
              <Typography key={key} variant="caption" color="red">
                {value.message}
              </Typography>
            );
          })}
        </Stack>
        <Stack sx={{ height: "95%", width: "45%", overflowY: "scroll" }}>
          <Stack
            direction="row"
            spacing={6}
            alignItems="center"
            padding="5px"
            backgroundColor="white"
            marginTop="10px"
            borderRadius="6px"
          >
            <Typography variant="body2" sx={{ padding: "5px" }}>
              Sentences in this lesson: {lesson?.sentences.length}
            </Typography>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Typography variant="body2">Show NL text</Typography>
              <Checkbox
                checked={showNL}
                onChange={toggleNL}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
          </Stack>

          <Typography variant="caption" sx={{ padding: "5px" }}>
            (You can copy these to a document for off-line reading)
          </Typography>
          <Box
            sx={{
              backgroundColor: theme.palette.blues.light,
              padding: "10px",
              marginRight: "8px",
            }}
          >
            {lesson?.name}
            {lesson &&
              lesson.sentences.map((s, index) => {
                return (
                  <Box key={index} sx={{ marginTop: "10px" }}>
                    <Typography variant="body2">{s.origText}</Typography>
                    {showNL && (
                      <Typography display="block" variant="body2">
                        ({s.transText})
                      </Typography>
                    )}
                  </Box>
                );
              })}
          </Box>
        </Stack>
      </Stack>
      <Dialog
        open={showDialog}
        onClose={toggleModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Select a sequence for the lesson
        </DialogTitle>
        <DialogContent>
          <SequenceSelector
            showFilter={true}
            seqWidth="75%"
            seqFont="0.6rem"
            onSelect={handleSeqSelect}
            count={count}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
