import React, { useEffect, useState, Fragment } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { format as dateFormat } from "date-fns";

import useAxiosPrivate from "../_hooks/useAxiosPrivate";
import { useForm, Controller } from "react-hook-form";
import { enqueueSnackbar } from "notistack";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  Checkbox,
  Paper,
  Box,
  Divider,
  TextField,
  Typography,
  Button,
  Stack,
} from "@mui/material";

let formatting_options = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};
let dollarString = new Intl.NumberFormat("en-US", formatting_options);

export { UsersAddEdit };

function UsersAddEdit({ history, match }) {
  const params = useParams();
  const { id } = params;
  const api = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState(null);
  const [payments, setPayments] = useState([]);

  const validationShema = Yup.object().shape({
    name: Yup.string().required("User name is required"),
    email: Yup.string().required("Email is required"),
  });

  const formOptions = {
    resolver: yupResolver(validationShema),
    defaultValues: {
      name: "",
      isDeleted: false,
      email: "",
      dateLastRenewed: "",
      ttsCharUsed: 0,
      couponCode: "",
      isUser: false,
      isCreator: false,
      isAdmin: false,
    },
  };

  const { control, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  async function onFormSubmit(data) {
    let { isAdmin, isUser, isCreator, ...bodyData } = data;
    let roleArray = [];
    if (data.isUser) {
      roleArray[roleArray.length] = "ROLE_USER";
    }
    if (data.isCreator) {
      roleArray[roleArray.length] = "ROLE_CREATOR";
    }
    if (data.isAdmin) {
      roleArray[roleArray.length] = "ROLE_ADMIN";
    }
    bodyData = { ...bodyData, roles: roleArray };

    try {
      await api.put(`user/${user._id}`, bodyData);
      navigate(-1);
    } catch (err) {
      if (err.response) {
        enqueueSnackbar(err.response.data.errors.join(", "), {
          variant: "error",
        });
      }
    }
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getUser = async () => {
      if (id) {
        try {
          const response = await api.get(`user/${id}`, {
            signal: controller.signal,
          });
          const foundUser = response.data.user;
          const dateLastRenewed =
            foundUser.account.dateLastRenewed || foundUser.account.dateJoined;
          const formValues = {
            _id: foundUser._id,
            isDeleted: foundUser.isDeleted,
            name: foundUser.name,
            email: foundUser.email,
            dateLastRenewed: dateLastRenewed.split("T")[0],
            ttsCharUsed: foundUser.account.ttsCharUsed,
            couponCode: foundUser.account.couponCode,
            isUser: foundUser.roles.includes("ROLE_USER"),
            isAdmin: foundUser.roles.includes("ROLE_ADMIN"),
            isCreator: foundUser.roles.includes("ROLE_CREATOR"),
          };
          isMounted && setUser(formValues);
          const resp2 = await api.get(`account/${id}/payments`);
          isMounted && setPayments(resp2.data.payments);
        } catch (err) {
          console.error(err);
          navigate("/login", { state: { from: location }, replace: true });
        }
      } else {
        setUser(formOptions.defaultValues);
      }
    };
    getUser();
    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      reset(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const formJsx = (
    <Box>
      {user && user.isDeleted && (
        <Box sx={{ color: "red", padding: "10px", typography: "body1" }}>
          Deleted
        </Box>
      )}
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={1}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="name"
                  label="User name"
                  error={Boolean(errors.name)}
                  sx={{ width: "300px" }}
                />
              );
            }}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.name?.message}
          </Typography>
          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="email"
                  label="Email"
                  error={Boolean(errors.email)}
                  sx={{ width: "300px" }}
                />
              );
            }}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.email?.message}
          </Typography>
          <Controller
            name="dateLastRenewed"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="dateLastRenewed"
                  type="date"
                  label="Last renewed"
                  error={Boolean(errors.dateLastRenewed)}
                  sx={{ width: "300px" }}
                />
              );
            }}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.dateLastRenewed?.message}
          </Typography>
          <Controller
            name="ttsCharUsed"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="ttsCharUsed"
                  type="number"
                  label="TTS Characters Used"
                  error={Boolean(errors.ttsCharUsed)}
                  sx={{ width: "300px" }}
                />
              );
            }}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.ttsCharUsed?.message}
          </Typography>
          <Controller
            name="couponCode"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="couponCode"
                  label="Coupon code"
                  error={Boolean(errors.couponCode)}
                  sx={{ width: "300px" }}
                />
              );
            }}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.couponCode?.message}
          </Typography>
          <Stack direction="row" spacing={3}>
            <Box display="flex" sx={{ alignItems: "center" }}>
              <Controller
                name="isUser"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    sx={{ padding: "3px" }}
                  />
                )}
              />
              <Typography variant="caption">User</Typography>
            </Box>
            <Box>
              <Controller
                name="isCreator"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    sx={{ padding: "3px" }}
                  />
                )}
              />
              <Typography variant="caption">Creator</Typography>
            </Box>
            <Box>
              <Controller
                name="isAdmin"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    sx={{ padding: "3px" }}
                  />
                )}
              />
              <Typography variant="caption">Admin</Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} pt={3}>
          <Button
            type="primary"
            color="primary"
            variant="contained"
            onClick={() => handleSubmit(onFormSubmit)}
          >
            Save changes
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </Stack>
      </form>
    </Box>
  );

  return (
    <Fragment>
      <Paper>
        <Typography paragraph variant="h5" gutterBottom={true} align="left">
          Edit User
        </Typography>
        <Box display="flex" flexDirection="row" width="95%">
          <Box sx={{ width: "50%", border: "1px solid gray", padding: "15px" }}>
            {formJsx}
          </Box>
          <Box sx={{ width: "50%", border: "1px solid gray", padding: "15px" }}>
            <Stack spacing={2} sx={{ marginLeft: "15px", width: "50%" }}>
              <Typography variant="caption" fontWeight={700}>
                Payments
              </Typography>
              {payments &&
                payments.map((p, index) => {
                  return (
                    <Stack key={index}>
                      <Box display="flex" flexDirection="row">
                        <Typography
                          variant="body2"
                          sx={{ marginRight: "15px" }}
                        >
                          {dateFormat(new Date(p.paymentDate), "dd-MMM-yy")}
                        </Typography>
                        <Typography variant="body2">
                          {dollarString.format(p.amount)}
                        </Typography>
                      </Box>
                      <Divider />
                    </Stack>
                  );
                })}
            </Stack>
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
}
