import React from "react";
import {
  Stack,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { SequenceDisplay } from "./SequenceDisplay";
import CancelOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";

export { SequenceDisplayDialog };

function SequenceDisplayDialog(props) {
  const { onClose, open, name, seqText, origVoice, transVoice } = props;
  const user = JSON.parse(localStorage.getItem("gvLangUser"));
  const tlColor = user.preferences.origColor;
  const nlColor = user.preferences.transColor;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Stack spacing={2}>
          <SequenceDisplay seqName={name} seqText={seqText} />
          <Stack mx={3} sx={{ width: "220px" }}>
            <Box display="flex" justifyContent="space-between">
              <Typography typography="caption">Target language:</Typography>
              {origVoice && (
                <Typography variant="caption">{origVoice}</Typography>
              )}
              <Box height="15px" width="15px" bgcolor={tlColor} />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="caption">Natural language:</Typography>
              {transVoice && (
                <Typography variant="caption">{transVoice}</Typography>
              )}
              <Box height="15px" width="15px" bgcolor={nlColor} />
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ width: "45%", margin: "10px" }}
          variant="outlined"
          onClick={() => onClose()}
          startIcon={<CancelOutlinedIcon />}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
