import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, TextField, Typography, Box, Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { enqueueSnackbar } from "notistack";

import { SentenceAddEditNested } from ".";
import { isInstructionTip } from "../_helpers";

export { SentenceAddEdit };

function SentenceAddEdit({
  sentence,
  changeCount,
  onAdd,
  onCancel,
  onUpdate,
  onDelete,
  languageLetters,
}) {
  const mode = { add: !sentence?._id, edit: !!sentence?._id };
  const [splits, setSplits] = useState("");
  const origTextRef = useRef();
  const user = JSON.parse(localStorage.getItem("gvLangUser"));
  const defaultSourcedFrom = user.preferences.defaultSourcedFrom;

  const validationSchema = Yup.object().shape({
    origText: Yup.string()
      .min(5, "Value must be 5 characters or more")
      .max(250, "Value must be 250 characters max")
      .required("Required"),
    sourcedFrom: Yup.string().required("Sourced from is required"),
  });

  const initialValues = {
    origText: "",
    transText: "",
    sourcedFrom: defaultSourcedFrom,
    isInstruction: false,
    splits: "",
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  };

  // get functions to build form with useForm() hook
  const methods = useForm(formOptions);
  const { register, handleSubmit, reset, formState, watch, setValue } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const textToSplit = watch("origText");

  // get sentence from sentencelist, based on _id of sentence sub-document
  useEffect(() => {
    if (mode.edit) {
      reset(sentence);
      setSplits(sentence.splits);
    } else {
      reset(initialValues);
      setSplits("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentence?._id, changeCount]);

  function onSubmit(data) {
    var cleanText = data.origText.replace(/\xA0/g, " ");
    const bodyData = {
      ...data,
      origText: cleanText.trim(),
      transText: data.transText.trim(),
    };
    const splitsCount = bodyData.splits
      .split(",")
      .filter((p) => p !== "false").length;
    if (splitsCount < 3) {
      return mode.add ? onAdd(bodyData) : onUpdate(sentence._id, bodyData);
    } else {
      enqueueSnackbar("Max 2 splits allowed in a sentence", {
        variant: "error",
      });
    }
  }

  function deleteSentence(id) {
    onDelete(id);
  }

  function insertChar(val) {
    const index = origTextRef.current.selectionStart;
    const curValue = origTextRef.current.value;
    const newValue = curValue.slice(0, index) + val + curValue.slice(index);
    setValue("origText", newValue);
    origTextRef.current.focus();
    origTextRef.current.setSelectionRange(index + 1, index + 1);
  }

  const loading = mode.edit && !sentence;
  return (
    <Box marginTop="10px">
      {!loading && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Stack direction="row" spacing={3}>
                <Stack sx={{ width: "45%" }}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { fontSize: 15 } }} // font size of input text
                    sx={{ fontSize: "0.7rem" }}
                    spellCheck="false"
                    id="origText"
                    required
                    multiline
                    autoFocus
                    label="Target language text"
                    inputRef={origTextRef}
                    error={Boolean(errors.origText)}
                    {...register("origText")}
                  />
                  {languageLetters.length > 0 && (
                    <Stack direction="row" spacing={1} sx={{ pb: 1, mt: 1 }}>
                      {languageLetters.map((letter, index) => {
                        return (
                          <Button
                            key={index}
                            sx={{
                              width: "3%",
                              textTransform: "none",
                              minWidth: "20px",
                              padding: "2px 2px",
                            }}
                            size="small"
                            tabIndex={-1}
                            variant="outlined"
                            onClick={() => {
                              insertChar(letter);
                            }}
                          >
                            {letter}
                          </Button>
                        );
                      })}
                    </Stack>
                  )}
                  <Typography variant="caption" color="red">
                    {errors.origText?.message}
                  </Typography>
                </Stack>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { fontSize: 15 } }} // font size of input text
                  sx={{ width: "45%", fontSize: "0.7rem" }}
                  spellCheck="false"
                  id="transText"
                  label="Natural language text"
                  required
                  multiline
                  error={Boolean(errors.transText)}
                  {...register("transText")}
                />
                <Typography variant="caption" color="red">
                  {errors.transText?.message}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { fontSize: 15 } }} // font size of input text
                  sx={{ width: "30%" }}
                  spellCheck="false"
                  id="sourcedFrom"
                  required
                  label="Sourced from"
                  error={Boolean(errors.sourcedFrom)}
                  {...register("sourcedFrom")}
                />
                <Typography variant="caption" color="red">
                  {errors.sourcedFrom?.message}
                </Typography>
                <Box sx={{ fontSize: "13px" }}>
                  <input
                    id="isInstruction"
                    type="checkbox"
                    {...register("isInstruction")}
                  />
                  <label data-tip={isInstructionTip}>
                    Is this an Instruction?
                  </label>
                  <div className="error">{errors.isInstruction?.message}</div>
                </Box>
              </Stack>
              <Stack>
                <SentenceAddEditNested val={textToSplit} splits={splits} />
              </Stack>

              <Stack direction="row" spacing={3}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !isDirty}
                  startIcon={<SaveOutlinedIcon />}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => onCancel()}
                  disabled={isSubmitting}
                  startIcon={<CancelOutlinedIcon />}
                >
                  Cancel
                </Button>
                {!!sentence?._id && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => deleteSentence(sentence?._id)}
                    disabled={isSubmitting}
                    startIcon={<DeleteOutlinedIcon />}
                  >
                    Delete
                  </Button>
                )}
              </Stack>
              {isDirty && !isSubmitting && (
                <Typography
                  variant="caption"
                  sx={{ color: "maroon", fontWeight: 700 }}
                >
                  There are unsaved changes
                </Typography>
              )}
              {isSubmitting && (
                <Typography
                  variant="body2"
                  sx={{ color: "blue", fontWeight: 700 }}
                >
                  Saving changes ...
                </Typography>
              )}
            </Stack>
          </form>
          <ReactTooltip multiline={true} html={false} />
        </FormProvider>
      )}
      {loading && <div>Loading...</div>}
    </Box>
  );
}
