import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { enqueueSnackbar } from "notistack";
import { useParams, useNavigate } from "react-router-dom";

import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";

export { SentenceReview };

function SentenceReview(props) {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [unreviewed, setUnreviewed] = useState([]);
  const [numberToReview, setNumberToReview] = useState(0);
  const [lesson, setLesson] = useState(null);
  const [flipped, setFlipped] = useState(true);
  const [nlFirst, setNlFirst] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (id) {
      const getLesson = async () => {
        try {
          const response = await API.get(
            "lessonsApi",
            `/lessons/one-lesson?id=${id}`
          );
          if (isMounted) {
            setLesson(response.appData.lesson);
            const ary = [];
            for (var i = 0; i < response.appData.lesson.sentences.length; i++) {
              if (!response.appData.lesson.sentences[i].isInstruction) {
                ary.push(i);
              }
            }
            setUnreviewed(ary);
            setNumberToReview(ary.length);
          }
        } catch (error) {
          if (error.response) {
            console.log(error.response.data);
          }
        }
      };
      getLesson();
      return () => {
        isMounted = false;
        controller.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function handleNextClick() {
    if (unreviewed.length) {
      const ix = getRandomInt(0, unreviewed.length - 1);
      const ixS = unreviewed[ix];
      setSelectedIndex(ixS);
      const copiedAry = [...unreviewed];
      copiedAry.splice(ix, 1);
      setUnreviewed(copiedAry);
      setFlipped(false);
    } else {
      enqueueSnackbar("Review complete - restarting", { variant: "message" });
      const ary = [];
      for (var i = 0; i < lesson.sentences.length; i++) {
        if (!lesson.sentences[i].isInstruction) {
          ary.push(i);
        }
      }
      setUnreviewed(ary);
    }
  }

  function handleFlippedClick() {
    setFlipped(true);
  }

  function showCard() {
    if (!nlFirst) {
      return (
        <Stack spacing={2}>
          <Box>{lesson.sentences[selectedIndex].origText}</Box>
          <Box>{flipped && lesson.sentences[selectedIndex].transText}</Box>
        </Stack>
      );
    } else {
      return (
        <Stack spacing={2}>
          <Box>{lesson.sentences[selectedIndex].transText}</Box>
          <Box>{flipped && lesson.sentences[selectedIndex].origText}</Box>
        </Stack>
      );
    }
  }
  return (
    <Stack spacing={2} sx={{ height: "90%", margin: "20px 100px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ margin: "15px" }}
      >
        <Typography variant="body1">Reviewing: {lesson?.name}</Typography>
        <Typography variant="body2">
          {lesson?.sentences.length} sentences
        </Typography>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Typography variant="body2">Show NL first</Typography>
          <Checkbox
            checked={nlFirst}
            onChange={() => setNlFirst((prev) => !prev)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        <Button
          variant="outlined"
          size="small"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          Back to lessons
        </Button>
      </Box>
      <Stack
        direction="row"
        spacing={3}
        sx={{ paddingBottom: "15px", alignIems: "center" }}
      >
        {flipped && (
          <Button
            variant="outlined"
            size="small"
            sx={{ width: "40%" }}
            onClick={() => handleNextClick()}
            endIcon={<ArrowForwardIcon />}
          >
            Next card
          </Button>
        )}
        {!flipped && (
          <Button
            variant="outlined"
            size="small"
            sx={{ width: "40%" }}
            onClick={() => handleFlippedClick()}
            startIcon={<ThreeSixtyIcon />}
          >
            Flip Card
          </Button>
        )}
        <Typography variant="body2">
          {Math.round(100 - (unreviewed.length / numberToReview) * 100, 4)}%
          reviewed
        </Typography>
      </Stack>
      <Box>{lesson && selectedIndex != null && showCard()}</Box>
    </Stack>
  );
}
