import React, { useState } from "react";
import { Box, Tabs, Tab, Paper } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";

import {
  GsLesson,
  GsFreePlay,
  GsSentences,
  GsSequences,
  GsSequencePlay,
  GsQuickImport,
  GsQuickStart,
} from "../guides";

export { GettingStarted };

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

function GettingStarted() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={tabValue}
        onChange={handleChange}
        aria-label="Tabs example"
        sx={{ borderRight: 1, borderColor: "divider", minWidth: "100px" }}
      >
        <Tab label="Quick Start" wrapped {...a11yProps(0)} />
        <Tab label="Example Lessons" wrapped {...a11yProps(1)} />
        <Tab icon={<HelpIcon />} label="Lessons" wrapped {...a11yProps(2)} />
        <Tab icon={<HelpIcon />} label="Sentences" wrapped {...a11yProps(3)} />
        <Tab icon={<HelpIcon />} label="Sequences" wrapped {...a11yProps(4)} />
        <Tab icon={<HelpIcon />} label="Free Play" wrapped {...a11yProps(5)} />
        <Tab
          icon={<InfoIcon />}
          label="Sequence Play"
          wrapped
          {...a11yProps(6)}
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <GsQuickStart />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <GsQuickImport />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <GsLesson />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <GsSentences />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <GsSequences />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <GsFreePlay />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <GsSequencePlay />
      </TabPanel>
    </Paper>
  );
}
