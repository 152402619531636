import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Stack, Typography, Grid, Slider } from "@mui/material";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { enqueueSnackbar } from "notistack";
import { useTheme } from "@mui/material";
import { usePolly } from "../_hooks/usePolly.js";

const gridStyles = {
  paddingBottom: 1,
  paddingRight: 1,
  marginTop: 1,
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
};

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        display: "flex",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        fontSize: "0.8rem",
        fontWeight: "400",
        justifyContent: "left",
        ...sx,
      }}
      {...other}
    />
  );
}

export { SentencePlay };

function SentencePlay(props) {
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem("gvLangUser"));
  const { playPhrase } = usePolly();
  const { playSteps, onComplete, onStart, autoPlay, isPaid } = props;
  const initialPlayed = Array(10).fill(false);
  const [played, setPlayed] = useState(initialPlayed);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [pauseInterval, setPauseInterval] = useState(
    parseInt(user.preferences.pauseInterval)
  );
  const haltRef = useRef(false);

  const pauseUnit = (pauseInterval / 100) * 1000 + 1000;

  const handlePauseIntervalChange = (e, newValue) => {
    setPauseInterval(newValue);
  };

  useEffect(() => {
    haltRef.current = false;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (autoPlay) {
      playSequence();
    }
    return () => {
      haltRef.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playSteps]);

  function haltPlay() {
    haltRef.current = true;
    const ary = played.map((s, index) => false);
    setPlayed(ary);
  }

  const wait = (ms) =>
    new Promise((resolve) => {
      return setTimeoutId(setTimeout(resolve, ms));
    });

  // simplify. instead of loop through steps and then reps,
  // create a flat array of step, phrase, voice, pauseAfter
  function getPlayArray() {
    let playArray = [];
    let prevPhrase = "";
    for (var iStep = 0; iStep < playSteps.length; iStep++) {
      const { voice, seg1, seg2, seg3, reps, deco, langOT, isInstruction } =
        playSteps[iStep];
      const repeat = reps.slice(-1);
      const phrase = (seg1 + seg2 + seg3).trim();
      // skip a repeat step but only if this is 3rd step (iStep=2) or higher
      if (phrase === prevPhrase && (iStep > 1 || isInstruction)) {
        continue;
      }
      prevPhrase = phrase;
      for (var j = 0; j < repeat; j++) {
        const playRec = {
          step: iStep,
          phrase: phrase,
          voice: voice,
          pauseAfter: calcNextPause(phrase, deco, langOT),
          phraseLength: phrase.length,
          deco: deco,
        };
        playArray.push(playRec);
      }
    }
    return playArray;
  }

  const playSequence = async () => {
    setIsPlaying(true);
    onStart();
    haltRef.current = false;
    if (!playSteps.length) {
      return null;
    }
    const playArray = getPlayArray();
    await wait(pauseUnit);
    for (var playRec of playArray) {
      if (haltRef.current) {
        break;
      }
      const { step, phrase, voice, pauseAfter } = playRec;
      const ary = played.map((s, index) => (step === index ? true : false));
      setPlayed(ary);
      // const res = await playIt(phrase, voice);
      const res = await playPhrase({
        textToPlay: phrase,
        voiceStr: voice,
      });
      if (res.error) {
        enqueueSnackbar(res.message, { variant: "error" });
        break;
      }
      await wait(pauseAfter);
    }
    const ary = played.map((s, index) => false);
    setPlayed(ary);
    setIsPlaying(false);
    onComplete(haltRef.current ? "stop" : "continue");
  };

  function calcNextPause(phrase, deco, langOT) {
    // deco = 'n' - pause,  deco = 'i' no pause
    let pause;
    if (deco === "i") {
      pause = Math.max(Math.ceil(phrase.length / 15), 2.75) * pauseUnit;
    } else {
      //pause = Math.ceil(phrase.length * 10) * 20;
      pause = Math.max(Math.ceil(phrase.length / 10), 2.75) * pauseUnit;
    }
    //add a second for speaking delay after a comma
    const commaPos = phrase.indexOf(",");
    if (commaPos > -1 && commaPos < phrase.length - 1) {
      pause += pauseUnit / 2;
    }

    // add a second for speaking delay after a sentence
    const periodPos = phrase.indexOf(". ");
    if (periodPos > -1 && periodPos < phrase.length - 1) {
      pause += pauseUnit;
    }

    // for natural language (langOT ='T') make it shorter
    // because the user doe not repeat it
    if (langOT === "T") {
      pause = pause * 0.75;
    }

    return pause;
  }

  return (
    <>
      <Box
        sx={{
          padding: "5px",
          width: "95%",
          minHeight: "250px",
          display: "flex",
          alignItems: "flex-start",
          borderRadius: "6px",
          bgcolor: "white",
        }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={1}
          columns={16}
          sx={gridStyles}
        >
          {playSteps.map((s, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={1}>
                  <Item sx={{ width: "4%", mx: "3px", minHeight: "25px" }}>
                    {index + 1}
                  </Item>
                </Grid>

                <Grid item xs={1}>
                  <Box
                    sx={{
                      width: "15px",
                      height: "15px",
                      maxWidth: "15px",
                    }}
                    bgcolor={played[index] ? "greenyellow" : "inherit"}
                  ></Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    borderRadius: "6px",
                  }}
                >
                  {s.langOT === "O" && (
                    <Stack direction="row" spacing="5px">
                      <Item sx={{ width: "33%", minWidth: "33%", px: "3px" }}>
                        <Typography variant="body2">{s.seg1}</Typography>
                      </Item>
                      <Item
                        sx={{
                          width: "33%",
                          minWidth: "33%",
                          px: "3px",
                          borderLeft: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        <Typography variant="body2">{s.seg2}</Typography>
                      </Item>
                      <Item sx={{ width: "33%", minWidth: "33%", px: "3px" }}>
                        <Typography variant="body2">{s.seg3}</Typography>
                      </Item>
                    </Stack>
                  )}
                  {s.langOT === "T" && (
                    <>
                      <Item>
                        <Typography variant="body2">{s.seg1}</Typography>
                      </Item>
                    </>
                  )}
                </Grid>

                <Grid item xs={1}>
                  <Box
                    sx={{
                      backgroundColor: "darkkhaki",
                      fontWeight: 700,
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "4px",
                      maxWidth: "35px",
                    }}
                  >
                    <Typography variant="caption">
                      {s.reps.slice(-1)}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={1}>
                  <Item sx={{ mx: "3px" }}>
                    {s.deco === "n" && <ScheduleIcon sx={{ height: "15px" }} />}
                  </Item>
                </Grid>
              </React.Fragment>
            );
          })}
          <Stack
            direction="row"
            spacing={2}
            mt={2}
            ml={1}
            sx={{ alignItems: "center" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={playSequence}
              disabled={!playSteps.length || !isPaid || isPlaying}
              startIcon={<PlayCircleOutlinedIcon />}
            >
              Play
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={haltPlay}
              disabled={!isPaid}
              startIcon={<StopCircleOutlinedIcon />}
            >
              Stop
            </Button>
            {isPlaying && (
              <Box
                sx={{
                  backgroundColor: "#cecc41",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  padding: "2px 20px",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    fontWeight: 600,
                  }}
                >
                  Playing ...
                </Typography>
              </Box>
            )}
            {haltRef.current && isPlaying && (
              <Box
                typography="caption"
                sx={{ color: "maroon", fontWeight: 700 }}
              >
                Stopping...
              </Box>
            )}
          </Stack>
          {!isPlaying && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={1}
              mt={2}
              ml={1}
              sx={{ width: "90%" }}
            >
              <Typography variant="caption">Pause interval</Typography>
              <Slider
                sx={{ width: 300 }}
                aria-label="Pause"
                id="pauseInterval"
                label="Pause Interval"
                valueLabelDisplay="auto"
                size="small"
                step={5}
                min={0}
                max={100}
                marks={true}
                value={pauseInterval}
                onChange={handlePauseIntervalChange}
              />
            </Stack>
          )}
        </Grid>
      </Box>
    </>
  );
}
