import React, { useMemo, useEffect, useState, useRef } from "react";
import { Auth, API } from "aws-amplify";
import { enqueueSnackbar } from "notistack";

import MaterialReactTable from "material-react-table";
import { Box, Paper, Typography } from "@mui/material";

export { UserList };

function UserList() {
  const [users, setUsers] = useState([]);
  const tableInstanceRef = useRef(null);
  const cognitoUser = Auth.user.username;

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await API.get(
          "usersApi",
          `/users/all-users?id=${cognitoUser}`
        );
        if (response.error) {
          throw new Error(response.appData.message);
        }
        let items = [];
        if (isMounted) {
          response.appData.users.map((u) => {
            return items.push({
              id: u._id,
              name: u.name,
              isDeleted: u.isDeleted,
              email: u.email,
              ttsCharUsed: u.account.ttsCharUsed,
              dateJoined: u.account.dateJoined,
              dateRenewedThru: u.account.dateRenewedThru,
              couponCode: u.account.couponCode,
            });
          });
          setUsers(items);
        }
      } catch (err) {
        enqueueSnackbar(err, { variant: "error" });
      }
    };

    getUsers();
    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "dateJoined",
        header: "Joined",
        size: 80,
        Cell: ({ cell }) => (
          <span>{cell.getValue() && cell.getValue().split("T")[0]}</span>
        ),
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 100,
      },
      {
        accessorKey: "ttsCharUsed",
        header: "TTS used",
        size: 10,
      },
      {
        accessorKey: "couponCode",
        header: "Coupon code",
        size: 80,
      },
      {
        accessorKey: "dateRenewedThru",
        header: "RenewedThru",
        size: 150,
        Cell: ({ cell }) => (
          <span>{cell.getValue() && cell.getValue().split("T")[0]}</span>
        ),
      },
    ],
    []
  );

  return (
    <Paper>
      <MaterialReactTable
        title="User List"
        columns={columns}
        data={users}
        tableInstanceRef={tableInstanceRef}
        enableSorting
        enableGrouping
        enableColumnDragging={false}
        enableGlobalFilter={true}
        enableStickyHeader
        enableStickyFooter
        renderTopToolbarCustomActions={() => (
          <Box>
            <Typography variant="body1">User List</Typography>
          </Box>
        )}
        initialState={{
          density: "comfortable",
          expanded: false, //expand all groups by default
          pagination: { pageIndex: 0, pageSize: 10 },
          sorting: [{ id: "dateJoined", desc: true }],
          grouping: ["dateJoined"],
        }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "12px",
            padding: "5px",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: "14px",
            color: "#0A0D1C",
            padding: "5px",
          },
        }}
        muiToolbarAlertBannerProps={{
          sx: {
            fontSize: "0.75rem",
            padding: "2px",
          },
        }}
        muiTableHeadRowProps={{
          sx: { backgroundColor: "#9897A6 " },
        }}
      />
    </Paper>
  );
}
