import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

export { GsSequencePlay };

const paragraphs = [
  {
    text: `In Sequence Play, you hear the sentence TTS played as defined by the
    steps in the SEQUENCE. To use sequence play, select Practice from
    the menu, then click SEQUENCE PLAY for any lesson.`,
    variant: "body2",
  },
  {
    text: `To the right of the list a box shows the sentence as it will be
    played. Each step shows the text that will play, the repititions,
    and a clock icon if the play will pause (briefly) after the step.
    Note the Pause Interval slider that lets you set the pause length as
    you practice.`,
    variant: "body2",
  },
  {
    text: `Other notes: If a sentence is an Instruction, Sequence play will play
    the natural language text, one time. (Target language text for the
    sentence is not used). If the sentence has fewer segments than used in
    the sequence (e.g. a sequence step plays segment 3 but the sentence has
    only 2 segments) the step will be ignored. A duplicate step is also
    ignored.`,
    variant: "body2",
  },
];

function GsSequencePlay() {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      <Stack
        spacing={5}
        sx={{
          width: "30%",
          height: "25%",
          backgroundColor: theme.palette.blues.light,
          borderRadius: "6px",
          padding: "10px",
        }}
      >
        {paragraphs.map((p, index) => {
          return (
            <Typography key={index} variant={p.variant}>
              {p.text}
            </Typography>
          );
        })}
      </Stack>

      <Stack>
        <img
          src="https://gvlang-guides.s3.us-west-2.amazonaws.com/Sequence_Practice.png"
          alt="Sequence Play"
          height="400px"
          width="800px"
        />
      </Stack>
    </Stack>
  );
}
