export const pollyVoices = [
  {
    label: "Naja da-DK Danish",
    value: "Naja da-DK",
    letters: ["æ", "ø", "å"],
    engine: "standard",
  },
  {
    label: "Lotte nl-NL Dutch",
    value: "Lotte nl-NL",
    letters: [],
    engine: "standard",
  },
  {
    label: "Ruben nl-NL Dutch",
    value: "Ruben nl-NL",
    letters: [],
    engine: "standard",
  },
  {
    label: "Emma en-GB English (British)",
    value: "Emma en-GB",
    letters: [],
    engine: "standard",
  },
  {
    label: "Nicole en-AU English (Australian)",
    value: "Nicole en-AU",
    letters: [],
    engine: "standard",
  },
  {
    label: "Russell en-AU English (Australian)",
    value: "Russell en-AU",
    letters: [],
    engine: "standard",
  },
  {
    label: "Joey en-US English (US)",
    value: "Joey en-US",
    letters: [],
    engine: "standard",
  },
  {
    label: "Justin en-US English (US)",
    value: "Justin en-US",
    letters: [],
    engine: "standard",
  },
  {
    label: "Kendra en-US English (US)",
    value: "Kendra en-US",
    letters: [],
    engine: "standard",
  },
  {
    label: "Kimberly en-US English (US)",
    value: "Kimberly en-US",
    letters: [],
    engine: "standard",
  },
  {
    label: "Salli en-US English (US)",
    value: "Salli en-US",
    letters: [],
    engine: "standard",
  },
  {
    label: "Mathieu fr-FR French",
    value: "Mathieu fr-FR",
    letters: ["ç", "é", "è", "î", "ô", "ù", "û"],
    engine: "standard",
  },
  {
    label: "Chantal fr-CA French",
    value: "Chantal fr-CA",
    letters: ["ç", "é", "è", "î", "ô", "ù", "û"],
    engine: "standard",
  },
  {
    label: "Lea fr-FR French",
    value: "Lea fr-FR",
    letters: ["ç", "é", "è", "î", "ô", "ù", "û"],
    engine: "standard",
  },
  {
    label: "Hans de-DE German",
    value: "Hans de-DE",
    letters: ["ä", "ö", "ü", "ß"],
    engine: "standard",
  },
  {
    label: "Marlene de-DE German",
    value: "Marlene de-DE",
    letters: ["ä", "ö", "ü", "ß"],
    engine: "standard",
  },
  {
    label: "Vicki de-DE German",
    value: "Vicki de-DE",
    letters: ["ä", "ö", "ü", "ß"],
    engine: "standard",
  },
  {
    label: "Hans de-DE German",
    value: "Hans de-DE",
    letters: ["ä", "ö", "ü", "ß"],
    engine: "standard",
  },
  {
    label: "Dora is-IS Icelandic",
    value: "Dora is-IS",
    letters: ["á", "ð", "é", "í", "ó", "ú", "ý", "þ", "æ", "ö"],
    engine: "standard",
  },
  {
    label: "Karl is-IS Icelandic",
    value: "Karl is-IS",
    letters: ["á", "ð", "é", "í", "ó", "ú", "ý", "þ", "æ", "ö"],
    engine: "standard",
  },
  {
    label: "Carla it-IT Italian",
    value: "Carla it-IT",
    letters: ["é", "ó", "à", "è", "ì", "ò", "ù"],
    engine: "standard",
  },
  {
    label: "Giorgio it-IT Italian",
    value: "Giorgio it-IT",
    letters: ["é", "ó", "à", "è", "ì", "ò", "ù"],
    engine: "standard",
  },
  {
    label: "Bianca it-IT Italian",
    value: "Bianca it-IT",
    letters: ["é", "ó", "à", "è", "ì", "ò", "ù"],
    engine: "standard",
  },
  {
    label: "Liv nb-NO Norwegian",
    value: "Liv nb-NO",
    letters: ["æ", "ø", "å"],
    engine: "standard",
  },
  {
    label: "Ewa pl-PL Polish",
    value: "Ewa pl-PL",
    letters: ["ą", "ć", "ę", "ł", "ń", "ó", "ś", "ź", "ż"],
    engine: "standard",
  },
  {
    label: "Ines pt-PT Portuguese",
    value: "Ines pt-PT",
    letters: [],
    engine: "standard",
  },
  {
    label: "Cristiano pt-PT Portuguese",
    value: "Cristiano pt-PT",
    letters: [],
    engine: "standard",
  },
  {
    label: "Conchita es-ES Spanish (European)",
    value: "Conchita es-ES",
    letters: ["¿", "¡", "á", "é", "í", "ñ", "ó", "ú", "ü"],
    engine: "standard",
  },
  {
    label: "Lucia es-ES Spanish (European)",
    value: "Lucia es-ES",
    letters: ["¿", "¡", "á", "é", "í", "ñ", "ó", "ú", "ü"],
    engine: "neurstandardal",
  },
  {
    label: "Enrique es-ES Spanish (European)",
    value: "Enrique es-ES",
    letters: ["¿", "¡", "á", "é", "í", "ñ", "ó", "ú", "ü"],
    engine: "standard",
  },
  {
    label: "Mia es-MX Spanish (Mexican)",
    value: "Mia es-MX",
    letters: ["¿", "¡", "á", "é", "í", "ñ", "ó", "ú", "ü"],
    engine: "standard",
  },
  {
    label: "Penelope es-US Spanish (US)",
    value: "Penelope es-US",
    letters: ["¿", "¡", "á", "é", "í", "ñ", "ó", "ú", "ü"],
    engine: "standard",
  },
  {
    label: "Miguel es-US Spanish (US)",
    value: "Miguel es-US",
    letters: ["¿", "¡", "á", "é", "í", "ñ", "ó", "ú", "ü"],
    engine: "standard",
  },
  {
    label: "Astrid sv-SE Swedish",
    value: "Astrid sv-SE",
    letters: ["å", "ä", "ö"],
    engine: "standard",
  },
  {
    label: "Filiz tr-TR Turkish",
    value: "Filiz tr-TR",
    letters: ["ç", "ğ", "ı", "ö", "ş", "ü"],
    engine: "standard",
  },
];

export const getLanguageLetters = (voice) => {
  let letters = [];
  const voiceObj = pollyVoices.filter((l) => l.value === voice);
  if (voiceObj.length > 0) {
    letters = voiceObj[0].letters;
  }
  return letters;
};
