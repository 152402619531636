export const importDataTip = `Your import text can be of two types: \n\r
(1) a list of paired sentences:\n
the origLang sentence, then LF, then the transLang sentence in ()
<br><br>example:<br>Han heter Peter<br>(His name is Peter)
<br><br></br>
(2) a paragraph of origLang sentences, followed by a paragraph of transLang sentences
<br>example: 
<br>Han heter Peter. Han har ett stort hus. Han tycker om vin'
<br>(His name is Peter. He has a large house. He like wine.)
`;

export const isInstructionTip = `A sentence indicated as an instruction will be played one time in the
user's native language.
`;
