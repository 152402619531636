import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

export { GsFreePlay };

const paragraphs = [
  {
    text: `
    Under the PRACTICE menu, you can select FREE PLAY for any lesson.
  `,
    variant: "body1",
  },
  {
    text: `In Free Play you can hear any sentence or phrase. To use free play, select PRACTICE from
    the menu, then click FREE PLAY for any lesson. You hear the first sentences, and then you can click
    the left/right arrows to move between sentences.
    `,
    variant: "body2",
  },
  {
    text: `Each sentence or part you select accumulates in the Play Area. You
    can click CLEAR to clear the play area. You can check the box 'Clear
    for each new sentence' to clear it after you move to a new sentence.`,
    variant: "body2",
  },
];

function GsFreePlay() {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={2}>
      <Stack
        spacing={4}
        sx={{
          width: "30%",
          height: "25%",
          backgroundColor: theme.palette.blues.light,
          borderRadius: "6px",
          padding: "10px",
        }}
      >
        {paragraphs.map((p, index) => {
          return (
            <Typography key={index} variant={p.variant}>
              {p.text}
            </Typography>
          );
        })}
      </Stack>
      <Stack>
        <img
          src="https://gvlang-guides.s3.us-west-2.amazonaws.com/SentenceFreePlay.png"
          alt="Practice - Free Play"
          height="350px"
          width="500px"
        />
      </Stack>
    </Stack>
  );
}
