import React, { useState } from "react";
import { format as dateFormat, subDays, addDays, parse } from "date-fns";
import { enqueueSnackbar } from "notistack";

import useAxiosPrivate from "../_hooks/useAxiosPrivate";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  Box,
  Button,
  Paper,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
let formatting_options = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};
let dollarString = new Intl.NumberFormat("en-US", formatting_options);

export { ViewPayments };

function PaymentCard({ payment }) {
  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr" }}>
      <Typography variant="body2">{payment.paymentUser.email}</Typography>
      <Typography variant="body2">
        {payment.paymentDate.split("T")[0]}
      </Typography>
      <Typography variant="body2">
        {dollarString.format(payment.amount)}
      </Typography>
    </Box>
  );
}

function ViewPayments() {
  const api = useAxiosPrivate();
  const [payments, setPayments] = useState([]);
  const [totalPayments, setTotalPayments] = useState(0);

  const validationShema = Yup.object().shape({
    fromDate: Yup.string().required("From date is required"),
    toDate: Yup.string().required("To date is required"),
  });

  const td = new Date();
  const fd = subDays(new Date(), 30);

  const formOptions = {
    resolver: yupResolver(validationShema),
    defaultValues: {
      fromDate: dateFormat(fd, "yyyy-MM-dd"),
      toDate: dateFormat(td, "yyyy-MM-dd"),
    },
  };

  const { control, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    let isMounted = true;
    const controller = new AbortController();

    const { fromDate, toDate } = data;
    if (fromDate > toDate) {
      enqueueSnackbar("To date must follow from date", { variant: "error" });
    } else {
      let newDate = addDays(parse(toDate, "yyyy-MM-dd", new Date()), 1);
      newDate = dateFormat(newDate, "yyyy-MM-dd");

      const getPayments = async () => {
        const response = await api.get(
          `account/payments/${fromDate}/${newDate}`,
          {
            signal: controller.signal,
          }
        );
        if (isMounted) {
          setPayments(response.data.payments);
          var result = response.data.payments.reduce(function (acc, obj) {
            return acc + obj.amount;
          }, 0);
          setTotalPayments(result);
        }
      };
      getPayments();
    }
  }

  return (
    <Paper sx={{ padding: "10px" }}>
      <Typography variant="body2" sx={{ fontWeight: 700, marginLeft: "15px" }}>
        View Payments
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={2} p={2}>
          <Controller
            name="fromDate"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="fromDate"
                  type="date"
                  label="From date"
                  error={Boolean(errors.fromDate)}
                  sx={{ width: "150px" }}
                />
              );
            }}
          />
          <Controller
            name="toDate"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="toDate"
                  type="date"
                  label="To date"
                  error={Boolean(errors.toDate)}
                  sx={{ width: "150px" }}
                />
              );
            }}
          />
          <Button
            type="primary"
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => handleSubmit(onSubmit)}
          >
            View
          </Button>
        </Stack>
      </form>
      <Stack spacing={1}>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          Total payments: {dollarString.format(totalPayments)}
        </Typography>
        {payments &&
          payments.map((p, index) => <PaymentCard key={index} payment={p} />)}
      </Stack>
    </Paper>
  );
}
