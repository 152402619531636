// Business login for sentencelist area

function aboutMe() {
  return "Placeholder for sentence list services";
}

function checkSameLanguage(lesson1, lesson2) {
  let rtn = 0;
  if (lesson1?.origVoice && lesson2?.origVoice) {
    const lang1 = lesson1?.origVoice.split(" ")[1].split("-")[0];
    const lang2 = lesson2?.origVoice.split(" ")[1].split("-")[0];
    rtn = lang1 === lang2 ? 1 : -1;
  }
  return rtn;
}

// take a lesson, stringify without the id values
function cloneLessonNoIds(lesson) {
  let lessonCopy = {
    name: lesson.name,
    course: lesson.course,
    ownerId: lesson.ownerId,
    origVoice: lesson.origVoice,
    transVoice: lesson.transVoice,
    notes: lesson.notes,
    sentences: [],
    usesSeq: {
      name: lesson.usesSeq.name,
      seqText: lesson.usesSeq.seqText,
      lastUsed: lesson.usesSeq.lastUsed,
    },
  };
  if (lesson?.sentences) {
    lesson.sentences.forEach((s) => {
      lessonCopy.sentences.push({
        origText: s.origText,
        transText: s.transText,
        sourcedFrom: s.sourcedFrom,
        isInstruction: s.isInstruction,
        splits: s.splits,
      });
    });
  }
  return lessonCopy;
}

const LessonService = {
  aboutMe,
  cloneLessonNoIds,
  checkSameLanguage,
};

export default LessonService;
