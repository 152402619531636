import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material";

export { SentenceAddEditNested };

function SentenceAddEditNested({ val, splits, resetCount }) {
  const theme = useTheme();
  const { register, setValue } = useFormContext();
  const [btnState, setBtnState] = useState([]);

  const sArray = val.split(" ");
  let splitsArray, tempArray;
  if (!splits) {
    splitsArray = Array(sArray.length).fill(false);
  } else {
    tempArray = splits.split(",").map((s) => {
      return s === "true" ? true : false;
    });
    splitsArray = tempArray.filter(
      (elem, index) => index < val.split(" ").length
    );
  }

  useEffect(() => {
    setBtnState([...splitsArray]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val, splits, resetCount]);

  function updateSplits(ix) {
    const btnStateNew = btnState.map((btn, index) => {
      if (index === ix) {
        return !btn;
      } else {
        return btn;
      }
    });
    setBtnState(btnStateNew);
    setValue("splits", btnStateNew.join(","), { shouldDirty: true });
  }

  return (
    <Stack spacing={2} direction="row">
      {val && (
        <Box
          typography="caption"
          sx={{ color: theme.palette.grey[700], minWidth: "100px" }}
        >
          Click for splits:
        </Box>
      )}
      <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%">
        {sArray.map((word, index) => (
          <Box
            display="flex"
            flexDirection="row"
            style={{ fontSize: "15px" }}
            key={index}
          >
            {word}
            <Box
              sx={{
                backgroundColor: btnState[index] ? "mediumblue" : "lightgray",
                width: "15px",
                height: "15px",
                margin: "3px 3px",
                borderRadius: "4px",
              }}
              onClick={() => updateSplits(index)}
            ></Box>
          </Box>
        ))}
      </Box>
      <div className="formField">
        <input type="hidden" name="splits" {...register("splits")} />
      </div>
    </Stack>
  );
}
