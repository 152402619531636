import React from "react";
import { useTheme } from "@mui/material";
import { Box, Stack, Typography } from "@mui/material";
import { SequenceDisplay } from "../sequences";

export { LessonDisplay };

function LessonDisplay({ lesson }) {
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem("gvLangUser"));

  return (
    <Stack direction="row" spacing={5}>
      <Stack
        sx={{
          width: "45%",
          marginLeft: "10px",
          backgroundColor: "whitesmoke",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        {lesson && (
          <Stack spacing={2} sx={{ color: theme.palette.neutral.dark }}>
            <Box sx={{ display: "grid", gridTemplateColumns: "2fr 3fr 1fr" }}>
              <Typography variant="body2">Lesson name: </Typography>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {lesson.name}
              </Typography>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "2fr 3fr 1fr" }}>
              <Typography variant="body2">Course: </Typography>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {lesson.course}
              </Typography>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "2fr 3fr 1fr" }}>
              <Typography variant="body2">TL voice: </Typography>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {lesson.origVoice}
              </Typography>
              <Box
                sx={{
                  width: 15,
                  height: 15,
                  backgroundColor: user.preferences.origColor,
                }}
              />
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "2fr 3fr 1fr" }}>
              <Typography variant="body2">NL voice: </Typography>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {lesson.transVoice}
              </Typography>
              <Box
                sx={{
                  width: 15,
                  height: 15,
                  backgroundColor: user.preferences.transColor,
                }}
              />
            </Box>
            <Box
              sx={{
                padding: "15px",
                backgroundColor: theme.palette.grey[400],
                width: "70%",
              }}
            >
              <Typography variant="body2">Sequence:</Typography>
              <SequenceDisplay
                seqName={lesson.usesSeq.name}
                seqText={lesson.usesSeq.seqText}
              />
            </Box>
            <Typography variant="body2">Notes: </Typography>
            <Typography variant="caption" sx={{ fontWeight: 700 }}>
              {lesson.notes}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack
        sx={{
          width: "55%",
          height: "80vh",
          overflowY: "scroll",
          padding: "8px",
          backgroundColor: theme.palette.grey[200],
        }}
        spacing={2}
      >
        <Box sx={{ borderBottom: "1px solid gray", p: 1 }}>
          <Typography variant="caption" sx={{ fontWeight: 700 }}>
            {lesson?.sentences?.length} sentence(s)
          </Typography>
        </Box>
        {lesson?.sentences?.map((s, index) => {
          return (
            <Box key={index}>
              <Typography variant="body2">{s.origText}</Typography>
              <Typography variant="caption">{s.transText}</Typography>
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
}
