import React, { createContext, useState } from 'react';

// create context
const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  // the value that will be given to the context
  const [auth, setAuth] = useState(null);

  return (
    // the Provider gives access to the context to its children
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
