import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, API } from "aws-amplify";

import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import { useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import FileUploader from "../_helpers/file-uploader";
import { LessonDisplay } from "./LessonDisplay";

export { LessonImport };

const MAX_FILESIZE = 50000;

function LessonImport({ lessonContent }) {
  const theme = useTheme();
  const cognitoUser = Auth.user.username;
  const navigate = useNavigate();
  const [lesson, setLesson] = useState(null);
  const [importFile, setImportFile] = useState(null);

  const importLesson = async () => {
    const newLesson = { ...lesson, ownerId: cognitoUser };
    try {
      const response = await API.post("lessonsApi", `/lessons/import-lesson`, {
        body: newLesson,
      });
      if (response.error) {
        throw new Error(response.appData.message);
      }
      enqueueSnackbar(
        "This Lesson has been imported as " + response.appData.newLesson.name,
        {
          variant: "success",
        }
      );
      setImportFile(null);
      setTimeout(() => navigate("/app/lessons"), 3000);
    } catch (err) {
      enqueueSnackbar(err, { variant: "error" });
    }
  };

  useEffect(() => {
    if (lessonContent) {
      const parseLessonContent = () => {
        try {
          const newLesson = JSON.parse(lessonContent);
          setLesson(newLesson);
        } catch (err) {
          enqueueSnackbar(err, { variant: "error" });
        }
      };
      parseLessonContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let fileReader;

  const handleFileRead = async (e) => {
    const content = fileReader.result;
    try {
      const newLesson = JSON.parse(content);
      setLesson(newLesson);
    } catch (err) {
      enqueueSnackbar(err, { variant: "error" });
    }
  };

  const handleFileSelected = (file) => {
    if (!!file?.name) {
      setImportFile(file);
      if (file.size > MAX_FILESIZE) {
        enqueueSnackbar("This file is too big (" + MAX_FILESIZE + "max)", {
          variant: "error",
        });
      } else {
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
      }
    }
  };

  const instructionJsx = (
    <Stack spacing={2} sx={{ backgroundColor: "white", padding: "20px" }}>
      <Typography variant="body2">
        If you have a file that was created and exported by another
        PhraseKeeperTTS user, you can click SELECT FILE above to import it.
      </Typography>
      <Typography variant="body2">
        If you want to import one of the pre-built lessons from PhraseKeeperTTS,
        click START HERE in the top menu, and then EXAMPLE LESSONS.
      </Typography>
    </Stack>
  );

  return (
    <Paper sx={{ height: "100vh" }}>
      <Stack sx={{ height: "95%" }}>
        <Box
          sx={{
            height: "8%",
            p: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Lesson Import</Typography>
          <FileUploader
            handleFile={handleFileSelected}
            buttonLabel="Select file"
            fileTypes=".txt, .json"
          />
          <Typography variant="body2" fontWeight="700">
            {importFile?.name}
          </Typography>
          <Button
            variant="contained"
            disabled={!lesson}
            onClick={() => importLesson()}
            startIcon={<LibraryBooksOutlinedIcon />}
          >
            Import lesson
          </Button>
        </Box>
        <Box
          sx={{
            height: "90%",
            backgroundColor: theme.palette.blues.main,
            padding: "10px",
          }}
        >
          {lesson && <LessonDisplay lesson={lesson} />}
          {!lesson && (
            <Box sx={{ width: 600, padding: "15px" }}>{instructionJsx}</Box>
          )}
        </Box>
      </Stack>
    </Paper>
  );
}
