import React from "react";
import { Box } from "@mui/material";

export { SentenceDisplayWithSplits };

function SentenceDisplayWithSplits({ text, splits }) {
  const sArray = text.split(" ");
  let splitsArray;
  if (!splits) {
    splitsArray = Array(sArray.length).fill(false);
  } else {
    splitsArray = splits.split(",").map((s) => {
      return s === "true" ? true : false;
    });
  }
  const btnState = [...splitsArray];

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%">
      {sArray.map((word, index) => (
        <Box
          typography="caption"
          display="flex"
          flexDirection="row"
          key={index}
        >
          {word}
          <Box
            sx={{
              backgroundColor: btnState[index] ? "mediumblue" : "lightgray",
              width: "15px",
              height: "15px",
              margin: "3px 3px",
              borderRadius: "4px",
            }}
          ></Box>
        </Box>
      ))}
    </Box>
  );
}
