//business logic for sequence service

function checkSeqText(d) {
  const dArry = d.split(',');
  let ok = true;
  for (let i = 0; i < dArry.length; i++) {
    const step = dArry[i];
    const chars = step.split('');
    if (chars[0] !== 'O' && chars[0] !== 'T') {
      ok = false;
    }
    if (chars[1] !== 't' && chars[1] !== 'f') {
      ok = false;
    }
    if (chars[2] !== 't' && chars[2] !== 'f') {
      ok = false;
    }
    if (chars[3] !== 't' && chars[3] !== 'f') {
      ok = false;
    }
    if (chars[4] !== 'x') {
      ok = false;
    }
    if (chars[5] < 1 || chars[5] > 5) {
      ok = false;
    }
    if ('in'.indexOf(chars[6]) === -1) {
      ok = false;
    }
  }
  return ok;
}

const SequenceService = {
  checkSeqText,
};

export default SequenceService;
