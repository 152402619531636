// functions for using indexeddb storage

export function getGVsValue(idKey) {
  return new Promise((resolve) => {
    const storeName = "Gvs";
    const request = window.indexedDB.open("gvLang", 1);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      const gvStore = db.createObjectStore(storeName, { keyPath: "id" });
      gvStore.createIndex("gvDate", "date");
    };
    request.onsuccess = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains(storeName)) {
        alert("IndexedDB data not found - please refresh your browser");
      }
      const tx = db.transaction(storeName, "readonly");
      const store = tx.objectStore(storeName);
      const res = store.get(idKey);
      res.onsuccess = () => {
        resolve(res.result);
      };
      res.onerror = () => {
        alert("IndexDB error: please try refreshing this bowser page.");
        resolve({});
      };
    };
  });
}

export function getRecordCount() {
  return new Promise((resolve) => {
    const storeName = "Gvs";
    const request = window.indexedDB.open("gvLang", 1);

    request.onsuccess = () => {
      const db = request.result;
      const tx = db.transaction(storeName, "readonly");
      const store = tx.objectStore(storeName);
      const res = store.count();
      res.onsuccess = () => {
        resolve(res.result);
      };
      res.onerror = () => {
        resolve({});
      };
    };
  });
}

export function setGVsValue(gvsObj) {
  const storeName = "Gvs";
  const request = window.indexedDB.open("gvLang", 1);

  request.onupgradeneeded = (event) => {
    const db = event.target.result;
    const gvStore = db.createObjectStore(storeName, { keyPath: "id" });
    gvStore.createIndex("gvDate", "date");
  };

  request.onsuccess = () => {
    const db = request.result;
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);

    store.add(gvsObj);
    tx.oncomplete = () => {
      db.close();
    };
  };
}

export function clearGvsDb() {
  const storeName = "Gvs";
  const request = window.indexedDB.open("gvLang", 1);

  request.onsuccess = () => {
    const db = request.result;
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    store.clear();

    tx.oncomplete = () => {
      db.close();
    };
  };
}
