import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import React from "react";

export { GsQuickStart };

function GsQuickStart() {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing={4}
      sx={{
        borderRadius: "4px",
        maxWidth: "900px",
      }}
    >
      <Stack
        spacing={3}
        sx={{
          typography: "body2",
          backgroundColor: theme.palette.blues.light,
          padding: "10px",
          marginRight: "15px",
        }}
      >
        <Box>
          To start using PhraseKeeperTTS, you create a lesson, add sentences to
          the lesson, and select a Practice option to hear them. Here are the
          steps:
        </Box>{" "}
        <Box>
          Select LESSONS in the top menu, then CREATE LESSON. In the window that
          opens, fill in a lesson name and course (any values). Select the
          target language voice (TL) and the natural language voice (NL). Click
          the Sequence folder icon to pick a sequence to use when listening to
          the audio.
        </Box>
        <Box>
          Have some sentences ready with their translations. Select SENTENCES
          from the top menu; a window opens for the most recent lesson. Click
          NEW SENTENCE and enter the TL text and NL text, and the sentence
          source to acknowlege any copyright. Below these fields you will see
          the TL text displayed with gray box separators. Click one or two boxes
          to separate the sentence into phrases. For a short sentence you can
          leave out the splits.
        </Box>
        <Box>
          The SENTENCES screen has a BULK IMPORT button you can use to add a
          group of sentences together. (You will still need to add splits
          individually). The BULK import screen describes the formats you can
          use.
        </Box>
        <Box>
          You're ready to hear your sentences. Click the PRACTICE menu, and from
          the lesson list, click the FREE PLAY or SEQUENCE PLAY button to hear
          the TTS audio.
        </Box>
        <Box
          sx={{
            color: "white",
            fontWeight: 600,
            backgroundColor: theme.palette.primary.light,
            padding: "10px",
          }}
        >
          PhraseKeeperTTS has EXAMPLE LESSONS you can import to see how lessons,
          sentences, splits and sequences all fit together. Click the EXAMPLE
          LESSONS sub-menu item above to see a list.
        </Box>
      </Stack>
      <Stack spacing={2}>
        <Box>
          <Stack>
            <Typography variant="caption">
              A lesson card for the lesson 'Swedish Starter'
            </Typography>
            <img
              src="https://gvlang-guides.s3.us-west-2.amazonaws.com/LessonMain.png"
              alt="Sequence graphic"
              height="250px"
              width="350px"
            />
          </Stack>
        </Box>
        <Box typography="caption">
          <Stack>
            A sentence with Swedish TL, English NL and 2 splits
            <img
              src="https://gvlang-guides.s3.us-west-2.amazonaws.com/gvIntro_sentence_tlnl.png"
              alt="Sentence NL TL"
              height="50px"
              width="325px"
            />
            <img
              src="https://gvlang-guides.s3.us-west-2.amazonaws.com/gvIntro_sentence_splits.png"
              alt="Sentence splits"
              height="30px"
              width="325px"
            />
          </Stack>
        </Box>
        <Box typography="caption">
          <Stack>
            <Box display="flex" alignContent="top" typography="caption">
              A PhraseKeeperTTS sequenceGraphic
              <Typography sx={{ fontSize: "9px" }}>TM</Typography>
            </Box>
            <img
              src="https://gvlang-guides.s3.us-west-2.amazonaws.com/SeqGraphic.png"
              alt="Sequence graphic"
              height="200px"
              width="220px"
            />
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
