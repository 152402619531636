import React, { useState } from "react";
import {
  Button,
  Box,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Auth, API } from "aws-amplify";

export { MuiPlayground };

function MuiPlayground() {
  const [objId, setObjId] = useState("");
  const [input, setInput] = useState("");
  const [appOutput, setAppOutput] = useState("");
  const [eventOutput, setEventOutput] = useState("");

  const displayResponse = (resp) => {
    const { appData, eventData } = resp;
    const eventObj = {
      path: eventData.path,
      pathParameters: eventData.pathParameters,
      queryStringParameters: eventData.queryStringParameters,
      httpMethod: eventData.httpMethod,
    };
    console.log(resp);
    setAppOutput(JSON.stringify(appData));
    setEventOutput(JSON.stringify(eventObj, null, 2));
  };

  const getItems = async () => {
    console.log("getItems");
    const resp = await API.get("myToysAPI", "/toys");
    displayResponse(resp);
  };

  const getItem = async () => {
    const id = objId;
    if (id !== "") {
      const resp = await API.get("myToysAPI", `/toys/${id}`);
      displayResponse(resp);
    } else {
      console.error("ID is required");
    }
  };

  const postItem = async () => {
    setAppOutput("");
    const resp = await API.post("myToysAPI", "/toys", {
      body: {
        owner: Auth.user.attributes.sub,
        name: input,
        price: 8.99,
      },
    });
    displayResponse(resp);
  };

  const putItem = async () => {
    const id = objId;
    const name = input;
    setAppOutput("");
    if (id !== null) {
      const resp = await API.put("myToysAPI", `/toys/${id}`, {
        body: {
          owner: Auth.user.attributes.sub,
          name: name,
          price: 8.99,
        },
      });
      displayResponse(resp);
    }
  };

  const deleteItem = async () => {
    const id = objId;
    setAppOutput("");
    if (id !== null) {
      const resp = await API.del("myToysAPI", `/toys/${id}`);
      displayResponse(resp);
    }
  };

  const showAuth = () => {
    console.log(Auth);
    const authJson = JSON.stringify(Auth.user.attributes, null, 2);
    setEventOutput("");
    setAppOutput(authJson);
  };

  return (
    <Paper sx={{ height: "100vh" }}>
      <Stack direction="row" spacing={3} sx={{ margin: "15px" }}>
        <Stack spacing={3}>
          <TextField
            id="objId"
            label="Object Id"
            variant="outlined"
            size="small"
            value={objId}
            onChange={(e) => setObjId(e.target.value)}
            sx={{
              width: "300px",
            }}
          />
          <TextField
            id="input"
            label="Body Data"
            variant="outlined"
            multiline
            rows={10}
            size="small"
            value={input || ""}
            onChange={(e) => setInput(e.target.value)}
            sx={{
              width: "300px",
            }}
          />
        </Stack>
        <Stack p={2} spacing={1}>
          <Typography variant="h6">Amplify Playground</Typography>
          <Button onClick={getItems}>Get toys</Button>
          <Button onClick={getItem}>Get one toy</Button>
          <Button onClick={postItem}>Post toy</Button>
          <Button onClick={putItem}>Put toy</Button>
          <Button onClick={deleteItem}>Delete toy</Button>
          <Button onClick={showAuth}>Show Auth</Button>
        </Stack>
        <Stack spacing={2} sx={{ width: "35%", margin: "5px" }}>
          <Box typography="body2">App Output</Box>
          <Box typography="caption">{appOutput}</Box>
          <Box typography="body2">Event Output</Box>
          <Box typography="caption">{eventOutput}</Box>
        </Stack>
      </Stack>
    </Paper>
  );
}
