import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Input,
  Typography,
  Radio,
  Stack,
} from "@mui/material";
import { CardActionArea } from "@mui/material";
import { format as dateFormat } from "date-fns";
import CancelOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";

const highlightColor = "#ccdc39";

export { LessonSelectDialog };

function LessonSelectDialog(props) {
  const cognitoUser = Auth.user.username;
  const [lessons, setLessons] = useState([]);
  const [sortBy, setSortBy] = useState("date");
  const [searchInput, setSearchInput] = useState("");
  const [apiData, setApiData] = useState("");

  const { onClose, selectedValue, open } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };
  const handleListItemClick = (value) => {
    onClose(value);
  };

  const controlProps = (item) => ({
    checked: sortBy === item,
    onChange: handleSortByChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const searchItems = (searchValue) => {
    if (searchValue !== "") {
      const filteredData = apiData.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      setLessons(filteredData);
    } else {
      setLessons(apiData);
    }
    setSearchInput(searchValue);
  };

  const handleSortByChange = (event) => {
    const val = event.target.value;
    if (val === "date") {
      setLessons(
        lessons.sort(function compare(a, b) {
          var dateA = new Date(a.lastUsed);
          var dateB = new Date(b.lastUsed);
          return dateB - dateA;
        })
      );
    } else {
      setLessons(
        lessons.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      );
    }
    setSortBy(val);
  };

  useEffect(() => {
    const getLessons = async () => {
      const response = await API.get(
        "lessonsApi",
        `/lessons/get-lessons?ownerid=${cognitoUser}`
      );
      setApiData(response.appData.lessons);
      setLessons(
        response.appData.lessons.sort(function compare(a, b) {
          var dateA = new Date(a.lastUsed);
          var dateB = new Date(b.lastUsed);
          return dateB - dateA;
        })
      );
    };
    getLessons();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: "40%",
            margin: "2px",
            padding: "10px",
          },
        }}
      >
        <DialogTitle>Select a lesson</DialogTitle>
        <Box display="flex" alignItems="center">
          <Typography variant="caption" sx={{ marginRight: "10px" }}>
            Sort by:
          </Typography>
          <Radio {...controlProps("date")} size="small" />
          <Typography variant="caption" sx={{ marginRight: "10px" }}>
            Last used
          </Typography>
          <Radio {...controlProps("name")} size="small" />
          <Typography variant="caption">Name</Typography>
          <Input
            sx={{ marginLeft: "25px", fontSize: "0.8rem" }}
            placeholder="search"
            name="searchInput"
            type="text"
            value={searchInput}
            onChange={(e) => searchItems(e.target.value)}
          />
        </Box>
        <Stack
          spacing={1}
          p={1}
          sx={{
            maxHeight: "450px",
            overflowY: "scroll",
            backgroundColor: "#64748B",
          }}
        >
          {lessons &&
            lessons.map((lesson) => {
              return (
                <Box key={lesson._id}>
                  <Card
                    sx={{
                      minWidth: 275,
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: highlightColor,
                      },
                    }}
                    onClick={() => handleListItemClick(lesson)}
                  >
                    <CardActionArea>
                      <CardContent sx={{ padding: "5px" }}>
                        <Typography variant="body1" gutterBottom>
                          {lesson.name}
                        </Typography>
                        <Box display="grid" gridTemplateColumns="1fr 1fr">
                          <Box>
                            <Typography
                              variant="caption"
                              sx={{ mb: 0.5 }}
                              color="text.secondary"
                            >
                              {lesson.origVoice}
                            </Typography>
                            <Typography
                              display="block"
                              variant="caption"
                              sx={{ mb: 0.5 }}
                              color="text.secondary"
                            >
                              {lesson.transVoice}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography display="block" variant="caption">
                              Last used:{" "}
                              {dateFormat(
                                new Date(lesson.lastUsed),
                                "dd-MMM-yy"
                              )}
                            </Typography>
                            <Typography variant="caption">
                              Sentence count:
                              {lesson.sentenceCount}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Box>
              );
            })}
        </Stack>
        <Button
          sx={{ width: "25%", margin: "10px" }}
          variant="contained"
          onClick={() => onClose()}
          startIcon={<CancelOutlinedIcon />}
        >
          Cancel
        </Button>
      </Dialog>
    </>
  );
}
