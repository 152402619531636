import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { SequenceDisplay } from "../sequences";
import { format as DateFormat } from "date-fns";

export { LessonCard };
function LessonCard({ lesson, onImport }) {
  const user = JSON.parse(localStorage.getItem("gvLangUser"));

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "3fr 2fr",
        gap: "30px",
      }}
    >
      <Stack spacing={1}>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {lesson.name}
        </Typography>
        <Typography variant="caption">{lesson.notes}</Typography>
        <Typography variant="caption">
          Sentence count: {lesson.sentenceCount}
        </Typography>
        <Box display="flex" gap="30px">
          <Button
            variant="outlined"
            size="small"
            sx={{ width: "150px" }}
            onClick={() => onImport(lesson)}
          >
            Import this lesson
          </Button>
          <Typography variant="caption">
            published: {DateFormat(new Date(lesson.lastUsed), "dd-MMM-yyyy")}
          </Typography>
        </Box>
      </Stack>
      <Stack>
        <Box
          sx={{
            padding: "5px",
            maxWidth: "250px",
          }}
        >
          <SequenceDisplay
            seqName={lesson.sequenceDetails.name}
            seqText={lesson.sequenceDetails.seqText}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "6fr 1fr",
            gap: "2px",
            marginLeft: "15px",
            maxWidth: "200px",
          }}
        >
          <Typography variant="caption">
            TL voice: {lesson.origVoice}
          </Typography>
          <Box
            sx={{
              width: 15,
              height: 15,
              backgroundColor: user.preferences.origColor,
              marginLeft: "3px",
              marginRight: "30px",
            }}
          />
          <Typography variant="caption">
            NL voice: {lesson.transVoice}
          </Typography>
          <Box
            sx={{
              width: 15,
              height: 15,
              backgroundColor: user.preferences.transColor,
              marginLeft: "3px",
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
}
