import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";

import { useForm, Controller } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Box, Stack, Typography, Button, TextField, List } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SequenceService from "../_services/sequence.service";
import { SequenceStepAdd } from "./SequenceStepAdd";
import { SequenceStepDisplay } from "./SequenceStepDisplay";

export { SequenceAddEdit };

function SequenceAddEdit({
  sequence,
  changeCount,
  onAdd,
  onUpdate,
  onCancel,
  onDelete,
}) {
  const [seqSteps, setSeqSteps] = useState([]);
  const mode = { add: !sequence?._id, edit: !!sequence?._id };
  const user = JSON.parse(localStorage.getItem("gvLangUser"));
  const tlColor = user?.preferences.origColor || "#595ea1";
  const nlColor = user?.preferences.transColor || "#969c3a";

  const cognitoUser = Auth.user.username;

  // get functions to build form with useForm() hook
  const { control, handleSubmit, setValue, reset, formState, clearErrors } =
    useForm({
      defaultValues: {
        name: "",
        seqText: "",
      },
    });

  useEffect(() => {
    if (mode.edit) {
      //reset({ name: sequence.name, seqText: sequence.seqText });
      reset(sequence);
      const resetSteps = () => {
        setSeqSteps(sequence.seqText.split(","));
      };
      resetSteps();
    } else {
      reset({ name: "", seqText: "" });
      const resetSteps = () => {
        setSeqSteps([]);
      };
      resetSteps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequence?._id, changeCount]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ name: "", seqText: "" });
      const resetSteps = () => {
        setSeqSteps([]);
      };
      resetSteps();
    }
  }, [formState.isSubmitSuccessful, reset]);

  function onSubmit(data) {
    const bodyData = { ...data, ownerId: cognitoUser };
    if (!SequenceService.checkSeqText(bodyData.seqText)) {
    } else {
      return mode.add ? onAdd(bodyData) : onUpdate(sequence._id, bodyData);
    }
  }

  function deleteSequence(sequence) {
    onDelete(sequence);
  }

  function resetSequence() {
    reset(sequence);
    const resetSteps = () => {
      if (!!sequence) {
        setSeqSteps(sequence.seqText.split(","));
      } else {
        setSeqSteps([]);
      }
    };
    resetSteps();
  }

  function addSequenceStep(s) {
    let arr = [...seqSteps];
    arr.push(s);
    setSeqSteps(arr);
    setValue("seqText", arr.join(","));
    clearErrors("seqText");
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(seqSteps);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSeqSteps(items);
    setValue("seqText", items.join(","));
  }

  function deleteStep(i) {
    let arr = [...seqSteps];
    arr.splice(i, 1);
    setSeqSteps(arr);
    setValue("seqText", arr.join(","));
  }

  const loading = mode.edit && !sequence;
  return (
    <>
      {!loading && (
        <Stack direction="row" spacing={3}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "30ch" },
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "10px",
              width: "45%",
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              typography="body2"
              sx={{ fontWeight: 600, marginBottom: "15px" }}
            >
              Add/Edit Sequence
            </Box>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true, width: "60%" }}
                    id="name"
                    label="Name"
                    size="small"
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                );
              }}
              rules={{ required: "Sequence name is required" }}
            />
            <Controller
              name="seqText"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    id="seqText"
                    inputRef={field.ref}
                    size="small"
                    disabled
                    label="Sequence Steps (from seqGraphic)"
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                );
              }}
              rules={{ required: "Sequence steps are required" }}
            />
            <Stack direction="row" spacing={2} mx={1}>
              <Stack direction="row" spacing={1}>
                <Typography variant="caption">Target language:</Typography>
                <Box height="15px" width="15px" bgcolor={tlColor} />
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography variant="caption">Natural language:</Typography>
                <Box height="15px" width="15px" bgcolor={nlColor} />
              </Stack>
            </Stack>
            <Box
              my="3px"
              mx={1}
              mt={2}
              sx={{
                border: "2px solid grey",
                borderRadius: "4px",
                padding: "8px",
              }}
            >
              <Typography variant="caption">Sequence Steps</Typography>
              <Stack direction="row" spacing={2}>
                <List sx={{ width: "90%", paddingBottom: "2px" }}>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="d1">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {seqSteps.map((step, index) => {
                            const reps = step.substring(5, 6);
                            const pause = step.substring(6, 7) === "n";
                            return (
                              <Draggable
                                key={index}
                                draggableId={index + " "}
                                index={index}
                              >
                                {(provided) => (
                                  <Box
                                    key={index}
                                    m="1px"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing="3px"
                                    >
                                      <Box
                                        sx={{
                                          "&:hover": {
                                            cursor: "grabbing",
                                          },
                                        }}
                                      >
                                        <SequenceStepDisplay
                                          s={step}
                                          height="15px"
                                          width="60px"
                                        />
                                      </Box>
                                      <Typography
                                        width="20px"
                                        variant="caption"
                                        align="center"
                                      >
                                        {reps}
                                      </Typography>{" "}
                                      <Box width="24px">
                                        {pause && (
                                          <ScheduleIcon
                                            sx={{ height: "15px" }}
                                          />
                                        )}
                                      </Box>
                                      <DeleteOutlinedIcon
                                        size="small"
                                        onClick={() => deleteStep(index)}
                                      />
                                    </Stack>
                                  </Box>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </List>
              </Stack>
            </Box>
            <Stack direction="row" spacing={1} mx={1} mt={2}>
              <Button
                variant="contained"
                type="submit"
                disabled={formState.isSubmitting}
                startIcon={<SaveOutlinedIcon />}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                size="small"
                type="button"
                disabled={formState.isSubmitting}
                onClick={() => resetSequence()}
                startIcon={<CancelOutlinedIcon />}
              >
                Reset
              </Button>
              {!!sequence && (
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<DeleteOutlinedIcon />}
                  onClick={() => deleteSequence(sequence)}
                  type="button"
                  disabled={formState.isSubmitting}
                >
                  Delete
                </Button>
              )}
            </Stack>
          </Box>
          <Box
            sx={{
              width: "55%",
              padding: "15px",
              border: "1px solid black",
              borderRadius: "6px",
            }}
          >
            <SequenceStepAdd onAddStep={addSequenceStep} sequence={sequence} />
          </Box>
        </Stack>
      )}
      {loading && <div>Loading...</div>}
    </>
  );
}
