import React, { useState } from "react";
import { Box, Button, Stack, Tabs, Tab, Typography } from "@mui/material";

import { enqueueSnackbar } from "notistack";
import useAxiosPrivate from "../_hooks/useAxiosPrivate";
import { ViewPayments } from "./ViewPayments";
import { AdminUser } from "./AdminUser";
import { MuiPlayground } from "../_examples/MuiPlayground";

export { Admin };

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

function Admin() {
  const api = useAxiosPrivate();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  async function handleDeleteFiles() {
    await api.get(`/account/deleteoldfiles`);
    enqueueSnackbar("Old sentence files deleted");
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: "100%",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabValue}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider", minWidth: "100px" }}
      >
        <Tab label="View payments" wrapped {...a11yProps(0)} />
        <Tab label="Sentence Archives" wrapped {...a11yProps(1)} />
        <Tab label="Admin user" wrapped {...a11yProps(2)} />
        <Tab label="MUI Playground" wrapped {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <ViewPayments />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Stack spacing={3}>
          <Typography variant="body2">
            This will delete old sentence archive files
          </Typography>
          <Button variant="outlined" onClick={() => handleDeleteFiles()}>
            Clear files
          </Button>
        </Stack>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <AdminUser />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <MuiPlayground />
      </TabPanel>
    </Box>
  );
}
