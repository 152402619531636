import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SequenceStepDisplay } from "./SequenceStepDisplay";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useTheme } from "@mui/material";

export { SequenceDisplay };

function SequenceDisplay(props) {
  const { seqName, seqText } = props;
  const theme = useTheme();

  if (!!seqText.length) {
    const seqArray = seqText.split(",");
    return (
      <Box
        sx={{
          borderRadius: "4px",
          border: "1px solid gray",
          padding: "5px 5px 10px 15px",
          justifyContent: "center",
          backgroundColor: theme.palette.neutral.light,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            marginBottom: "8px",
            paddingLeft: "10px",
            borderRadius: "2px",
            fontWeight: 700,
          }}
        >
          {seqName}
        </Typography>
        <Stack spacing="3px">
          {seqArray.length &&
            seqArray.map((step, index) => {
              const reps = step.substring(5, 6);
              const info = step.substring(6, 7);
              return (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing="3px"
                >
                  <SequenceStepDisplay s={step} height="12px" width="50px" />
                  <Box
                    typography="caption"
                    width="25px"
                    minWidth="20px"
                    height="15px"
                    variant="caption"
                    align="center"
                  >
                    {reps}
                  </Box>
                  {info === "n" && (
                    <ScheduleIcon sx={{ height: "15px", width: "15px" }} />
                  )}
                </Stack>
              );
            })}
        </Stack>
      </Box>
    );
  } else {
    return <Typography variant="body2">No sequences found</Typography>;
  }
}
