import React, { useMemo, useEffect, useState, useRef } from "react";
import { Auth, API } from "aws-amplify";

import { format as dateFormat } from "date-fns";
import MaterialReactTable from "material-react-table";
import { enqueueSnackbar } from "notistack";

import { useTheme } from "@mui/material";
import { Box, Stack, IconButton, Paper, Typography } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { usePolly } from "../_hooks/usePolly.js";
import { getIsPaid } from "../_services/user.service.js";
import { WindowTitle } from "../_helpers/gvlang-components.jsx";

export { HistoryMRT };

function HistoryFreePlay(props) {
  const theme = useTheme();
  const { origText, origVoice, count } = props;
  const [playing, setPlaying] = useState(false);
  const { playPhrase } = usePolly();

  useEffect(() => {
    const playIt = () => {
      if (!playing) {
        const waitTime = 2000 + (origText.length / 20) * 1000;
        setPlaying(true);
        playPhrase({
          textToPlay: origText,
          voiceStr: origVoice,
        });
        setTimeout(() => setPlaying(false), waitTime);
      }
    };
    playIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <Paper sx={{ paddingBottom: "10px" }}>
      {playing && (
        <Stack direction="row" spacing={2} margin="10px">
          <Typography
            variant="body2"
            sx={{ color: theme.palette.secondary, fontWeight: 700 }}
          >
            Playing...
          </Typography>
          <Typography variant="body2">{origText}</Typography>
        </Stack>
      )}
    </Paper>
  );
}

function HistoryMRT() {
  const cognitoUser = Auth.user.username;
  const theme = useTheme();
  const [historyList, setHistoryList] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [count, setCount] = useState(0);
  const [isPaid, setIsPaid] = useState(false);
  const tableInstanceRef = useRef(null);
  const [user, setUser] = useState("");

  useEffect(() => {
    const getHistory = async () => {
      try {
        const response = await API.get(
          "historyApi",
          `/history/get-list?ownerid=${cognitoUser}`
        );
        if (response.error) {
          throw new Error(response.appData.message);
        }
        let items = [];
        response.appData.history.map((row) => {
          return items.push({
            lessonName: row.lessonName,
            objectType: row.objectType,
            tsDate: row.tsDate,
            addedOn: row.addedOn,
            origText: row.origText,
            origVoice: row.origVoice,
            transText: row.transText,
            transVoice: row.transVoice,
            actionTaken: row.actionTaken,
          });
        });
        setHistoryList(items);
        setUser(response.appData.user);
        setIsPaid(() => getIsPaid(response.appData.user));
      } catch (err) {
        enqueueSnackbar(err, {
          variant: "error",
        });
      }
    };
    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "addedOn",
        header: "Date",
        size: 10,
        Cell: ({ cell }) => (
          <span>{dateFormat(new Date(cell.getValue()), "dd-MMM-yy")}</span>
        ),
      },
      {
        accessorKey: "objectType",
        header: "Item",
        size: 10,
      },
      {
        accessorKey: "lessonName",
        header: "Lesson",
        size: 100,
      },
      {
        accessorKey: "actionTaken",
        header: "Act",
        size: 35,
      },
      {
        accessorKey: "origText",
        header: "TL text",
        size: 150,
      },
      {
        accessorKey: "transText",
        header: "NL text",
        size: 100,
      },
      {
        accessorKey: "origVoice",
        header: "TL",
        size: 10,
      },
      {
        accessorKey: "transVoice",
        header: "NL",
        size: 10,
      },
    ],
    []
  );

  return (
    <Paper sx={{ height: "90vh" }}>
      {historyList && user && (
        <MaterialReactTable
          title="Sentence History"
          columns={columns}
          data={historyList}
          globalFilterFn="contains"
          tableInstanceRef={tableInstanceRef}
          enableGrouping
          enableColumnDragging={false}
          enableGlobalFilter={true}
          enableStickyHeader
          enableStickyFooter
          enableRowActions
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Play", //change header text
              size: 25, //make actions column wider
            },
          }}
          renderRowActions={(row, index) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <IconButton
                onClick={() => {
                  setRowData(row.row.original);
                  setCount((prev) => prev + 1);
                }}
                disabled={!isPaid || row.row.original.objectType !== "sentence"}
                sx={{
                  "&.Mui-disabled": {
                    color: theme.palette.neutral,
                  },
                  color: theme.palette.primary.dark,
                }}
              >
                <VolumeUpIcon sx={{ fontSize: "20px" }} />
              </IconButton>
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <Box>
              <WindowTitle>History - Sentences and Lessons</WindowTitle>
            </Box>
          )}
          initialState={{
            density: "comfortable",
            expanded: false, //expand all groups by default
            pagination: { pageIndex: 0, pageSize: user.preferences.pageSize },
            sorting: [{ id: "addedOn", desc: true }],
          }}
          muiTableProps={{
            sx: {
              tableLayout: "fixed",
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              fontWeight: "normal",
              fontSize: "15px",
              padding: "5px",
            },
          }}
          muiTableContainerProps={{ sx: { maxHeight: "75vh" } }}
          muiTableHeadCellProps={{
            sx: {
              fontSize: "15px",
              backgroundColor: "primary.light",
              padding: "5px",
            },
          }}
          muiTopToolbarProps={{
            sx: {
              color: theme.palette.secondary.dark,
            },
          }}
          muiToolbarAlertBannerProps={{
            sx: {
              fontSize: "0.75rem",
              padding: "2px",
            },
          }}
          muiTableHeadRowProps={{
            sx: { backgroundColor: theme.palette.neutral.light },
          }}
        />
      )}
      {rowData && (
        <HistoryFreePlay
          origText={rowData.origText}
          origVoice={rowData.origVoice}
          count={count}
        />
      )}
    </Paper>
  );
}
