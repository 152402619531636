import {
  Box,
  ButtonGroup,
  Button,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

import { SequenceStepDisplay } from "./SequenceStepDisplay";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

export { SequenceStepAdd };

function SequenceStepAdd({ onAddStep, sequence }) {
  const stepChoices = ["Otff", "Oftf", "Offt", "Ottf", "Oftt", "Ottt", "Tttt"];
  const [lastStep, setLastStep] = useState("");

  // get functions to build form with useForm() hook
  const { control, setValue, handleSubmit, reset, formState } = useForm({
    defaultValues: {
      newStep: "",
      repeatTimes: 1,
      pause: true,
    },
  });

  useEffect(() => {
    setValue("newStep", "");
  }, [sequence, setValue]);

  const onSubmit = (data) => {
    const seqText =
      data.newStep +
      "x" +
      data.repeatTimes.toString() +
      (data.pause ? "n" : "i");
    onAddStep(seqText);
    setLastStep("");
    reset();
  };

  function handleStepClicked(s) {
    setValue("newStep", s, { shouldDirty: true });
    setLastStep(s);
  }

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box typography="body2" sx={{ marginBottom: "10px" }}>
        Add steps to the sequence
      </Box>
      <Box typography="caption" sx={{ marginBottom: "8px" }}>
        Click a step graphic, then select reps and pause
      </Box>
      <Stack direction="row" spacing={2}>
        <Box>
          <Stack spacing={1}>
            <ButtonGroup orientation="vertical">
              {stepChoices.map((step, index) => {
                return (
                  <Button key={index} onClick={() => handleStepClicked(step)}>
                    <SequenceStepDisplay s={step} height="15px" width="40px" />
                  </Button>
                );
              })}
            </ButtonGroup>
          </Stack>
        </Box>
        <Box>
          <Box sx={{ marginBottom: "10px" }}>
            <SequenceStepDisplay s={lastStep} height="15px" width="40px" />
          </Box>
          <Stack spacing={1}>
            <Controller
              name="newStep"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={<Typography variant="body2">seqGraphic</Typography>}
                  variant="filled"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  disabled
                  size="small"
                  inputProps={{
                    style: {
                      textAlign: "right",
                    },
                  }}
                />
              )}
              rules={{ required: "SequenceGraphic required" }}
            />
            <Controller
              name="repeatTimes"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Repeat (1-5)"
                  variant="filled"
                  value={value}
                  size="small"
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 5,
                      style: { textAlign: "right" },
                    },
                  }}
                />
              )}
              rules={{
                required:
                  "Select the number of times you want to repeat this step",
                min: { value: 1, message: "Value must be min 1 max 5" },
                max: { value: 5, message: "Value must be min 1 max 5" },
              }}
            />
            <Controller
              name="pause"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                      }}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center" }}
                    >
                      <ScheduleIcon />
                      <Typography variant="caption">
                        pause after play
                      </Typography>
                    </Stack>
                  }
                />
              )}
            />{" "}
          </Stack>
        </Box>
      </Stack>
      <Button
        variant="contained"
        size="small"
        type="submit"
        disabled={!formState.isDirty}
        startIcon={<ArrowCircleLeftIcon />}
        sx={{ marginTop: "15px" }}
      >
        Add this step to the sequence
      </Button>
    </Box>
  );
}
