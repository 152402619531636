import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Auth, API } from "aws-amplify";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import {
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  Stack,
  Select,
} from "@mui/material";
import SourceIcon from "@mui/icons-material/Source";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import { LessonSelectDialog } from "../lessons";
import { useTheme } from "@mui/material";

const inputFormats = [
  { value: "T", label: "tab-delimited" },
  { value: "S", label: "single-sentence" },
  { value: "P", label: "paragraph" },
  { value: "N", label: "- Select an input format -" },
];

export { SentenceImport };

function WhiteBox(props) {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: "8px",
        border: "1px solid indigo",
        borderRadius: "4px",
        marginTop: "8px",
      }}
    >
      {props.children}
    </Box>
  );
}

function SentenceImport() {
  const theme = useTheme();
  const navigate = useNavigate();
  const cognitoUser = Auth.user.username;
  const user = JSON.parse(localStorage.getItem("gvLangUser"));

  const [lessonId, setLessonId] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [newSentences, setNewSentences] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const handleClickOpen = () => setModalOpen(true);
  const handleClose = (value) => {
    setModalOpen(false);
    if (!!value) {
      setLessonId(value._id);
    }
  };

  const validationSchema = Yup.object().shape({
    sourcedFrom: Yup.string().required("Source is required"),
    importText: Yup.string().required("Text to import is required"),
    inputFormat: Yup.string().required("Input format is required"),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      sourcedFrom: "",
      importText: "",
      inputFormat: "N",
    },
  };

  const { register, handleSubmit, reset, control, formState } =
    useForm(formOptions);
  const { errors, isSubmitting, isDirty } = formState;

  useEffect(() => {
    const getLesson = async () => {
      let response;
      response = await API.get(
        "lessonsApi",
        `/lessons/get-lastused?ownerid=${cognitoUser}`
      );
      const lastLessonIdUsed = response.appData.lastLessonIdUsed;
      if (lastLessonIdUsed) {
        setLessonId(lastLessonIdUsed);
      } else {
        enqueueSnackbar("No lesson found", { variant: "warning" });
      }
    };
    getLesson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lessonId) {
      const getLesson = async () => {
        const response = await API.get(
          "lessonsApi",
          `/lessons/one-lesson?id=${lessonId}`
        );
        setLesson(response.appData.lesson);
      };
      getLesson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId]);

  function onSubmit(data) {
    const { sourcedFrom, importText, inputFormat } = data;
    setNewSentences([]);
    if (!importText || !sourcedFrom) {
      return enqueueSnackbar("Import text AND sourced from are required.", {
        variant: "error",
      });
    }
    if (inputFormat === "T") {
      return parseTabDelimList(importText, sourcedFrom);
    } else if (inputFormat === "P") {
      return parseParagraph(importText, sourcedFrom);
    } else if (inputFormat === "S") {
      return parseSentenceList(importText, sourcedFrom);
    } else {
      enqueueSnackbar("Please select an input format", { variant: "warning" });
    }
  }

  const parseParagraph = (importText, sourcedFrom) => {
    let ary = [];
    let origText, transText;
    const lines = importText.split("\n");
    for (var i = 0; i < lines.length; i++) {
      const line = lines[i].trim().toString();
      if (line.length > 0) {
        if (
          line.substring(0, 1) === "(" &&
          line.substring(line.length - 1) === ")"
        ) {
          transText = line.slice(1).slice(0, -1);
        } else {
          origText = line.replace(/\xA0/g, " ");
        }
        if (!!origText && !!transText) {
          break;
        }
      }
    }
    if (!origText || !transText) {
      return enqueueSnackbar(
        "We could not find sentence and translation paragraphs.",
        { variant: "warning" }
      );
    }
    const origTextLines = origText.split(". ");
    const transTextLines = transText.split(". ");
    if (origTextLines.length !== transTextLines.length) {
      return enqueueSnackbar(
        "Could not find a matching translation for each sentence.",
        { variant: "warning" }
      );
    }
    for (var j = 0; j < origTextLines.length; j++) {
      const s = {
        origText: origTextLines[j],
        transText: transTextLines[j],
        sourcedFrom: sourcedFrom,
        isInstruction: false,
        splits: "",
      };
      if (s !== null) {
        ary.push(s);
      }
    }
    setNewSentences(ary);
  };

  const parseTabDelimList = (importText, sourcedFrom) => {
    let ary = [];
    let s = null;
    // add each line to an array
    const lines = importText.split("\n");

    for (var i = 0; i < lines.length; i++) {
      const line = lines[i].trim().toString();
      if (line.length > 0) {
        const s = {
          origText: line.split("\t")[0],
          transText: line.split("\t")[1],
          sourcedFrom: sourcedFrom,
          isInstruction: false,
          splits: "",
        };
        if (s !== null) {
          ary.push(s);
        }
      }
    }
    if (s !== null) {
      ary.push(s);
    }
    setNewSentences(ary);
  };

  const parseSentenceList = (importText, sourcedFrom) => {
    let ary = [];
    let s = null;
    // add each line to an array
    const lines = importText.split("\n");

    if (lines[0].indexOf("\t") > 0) {
      return enqueueSnackbar(
        "This text has TABs - please select the tab-deimited input format",
        { variant: "warning" }
      );
    }

    for (var i = 0; i < lines.length; i++) {
      const line = lines[i].trim().toString();
      if (line.length > 0) {
        if (
          line.substring(0, 1) === "(" &&
          line.substring(line.length - 1) === ")"
        ) {
          s.transText = line.slice(1).slice(0, -1);
        } else {
          if (s !== null) {
            ary.push(s);
            s = null;
          }
          s = {
            origText: line,
            transText: "",
            sourcedFrom: sourcedFrom,
            isInstruction: false,
            splits: "",
          };
        }
      }
    }
    if (s !== null) {
      ary.push(s);
    }
    setNewSentences(ary);
  };

  const resetSentences = () => {
    setNewSentences([]);
    reset(formOptions.defaultValues);
  };

  const addNewSentences = async () => {
    let prefAddSentenceTo = user.preferences.addSentenceTo;
    if (newSentences.length > 0) {
      const items = Array.from(lesson.sentences);
      if (prefAddSentenceTo === "beg") {
        for (let index = newSentences.length - 1; index >= 0; index--) {
          items.unshift(newSentences[index]);
        }
      } else {
        newSentences.forEach((sentence) => items.push(sentence));
      }
      const response = await API.put(
        "lessonsApi",
        `/lessons/set-sentences?id=${lessonId}`,
        {
          body: {
            sentences: items,
          },
        }
      );
      if (response.error === false) {
        enqueueSnackbar("These sentences have been imported to the list", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(response.appData.message, {
          variant: "error",
        });
      }
      setTimeout(() => {
        navigate(-1);
      }, 2000);
      setNewSentences([]);
    } else {
      enqueueSnackbar("No sentences were provided", { variant: "warning" });
    }
  };

  if (!lesson) return <>Loading...</>;

  const formJsx = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        mx={1}
        spacing={2}
        sx={{
          backgroundColor: "white",
          padding: "15px 10px",
          borderRadius: "6px",
        }}
      >
        <FormControl fullWidth className="custom_select">
          <InputLabel>Input format * </InputLabel>
          <Controller
            name="inputFormat"
            render={({ field }) => (
              <Select {...field} label="Select input format">
                {inputFormats.map((f) => (
                  <MenuItem key={f.value} value={f.value}>
                    {f.label}
                  </MenuItem>
                ))}
              </Select>
            )}
            control={control}
            labelId="Input format"
            defaultValue="N"
            required
          />
        </FormControl>
        <TextField
          name="importText"
          InputLabelProps={{ shrink: true }}
          required
          multiline
          label="Text to import"
          rows={12}
          {...register("importText")}
          inputProps={{
            style: { fontSize: "0.8rem" },
          }}
          error={errors.importText ? true : false}
        />
        <TextField
          margin="normal"
          name="sourcedFrom"
          label="Sourced from"
          type="text"
          InputLabelProps={{ shrink: true }}
          required
          inputProps={{
            style: { fontSize: "0.75rem" },
          }}
          {...register("sourcedFrom")}
          error={errors.sourcedFrom ? true : false}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <Button
            disabled={isSubmitting || !isDirty}
            type="submit"
            variant="contained"
          >
            Parse text
          </Button>
          <Button
            type="button"
            variant="text"
            onClick={resetSentences}
            disabled={isSubmitting || !isDirty}
            startIcon={<CancelOutlinedIcon />}
          >
            Reset
          </Button>
        </Stack>
      </Stack>
    </form>
  );

  const instructionsJsx = (
    <Box
      sx={{
        padding: "10px",
        backgroundColor: theme.palette.grey[300],
        borderRadius: "4px",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <Typography variant="body1">
        Instructions for importing sentences
      </Typography>
      <Typography sx={{ whiteSpace: "pre-line" }} variant="caption" mt={1}>
        {`Select a format: tab-delimited, single-sentence, paragraph.
        Paste your mined sentences into the 'Text to import' field. 
        Click Parse Text preview sentences and Import.
        `}
      </Typography>
      <Typography variant="body2" mt={2}>
        Tab-delimited format:
      </Typography>
      <WhiteBox>
        <Typography sx={{ whiteSpace: "pre-line" }} variant="caption">
          {`det är ännu inte bestämt. TAB it has not yet been decided
        mötet blev uppskjutet TAB the meeting was postponed
        `}
        </Typography>
      </WhiteBox>
      <Typography variant="body2" mt={2}>
        Single sentences format:
      </Typography>
      <WhiteBox>
        <Typography sx={{ whiteSpace: "pre-line" }} variant="caption">
          {`det är ännu inte bestämt.
        (it has not yet been decided)
        mötet blev uppskjutet
        (the meeting was postponed)
        `}
        </Typography>
      </WhiteBox>
      <Typography variant="body2" mt={2}>
        Paragraph format:
      </Typography>
      <WhiteBox>
        <Typography sx={{ whiteSpace: "pre-line" }} variant="caption">
          {`Hon vaknade tidigt. Det fanns inte mycket tid att ta sig till jobbet. Hon tog lite kaffe och bröd och lämnade sedan huset.
        (She woke up early. There was not much time to get to work. She had some coffee and bread and then left the house.)
        `}
        </Typography>
      </WhiteBox>
      <Divider sx={{ marginTop: "15px" }} />
      <Typography variant="caption" sx={{ fontWeight: 700 }}>
        Acknowledge any use of copyrighted material in the 'Sourced from' box.
      </Typography>
    </Box>
  );

  const newSentencesJsx = (
    <Stack spacing={1}>
      <Typography
        sx={{ fontWeight: 700, whiteSpace: "pre-line" }}
        variant="caption"
        mt={1}
      >
        Sentences will be added to the
        {user.preferences.addSentenceTo === "beg" ? " beginning " : " end "} of
        the list
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "15px",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            backgroundColor: theme.palette.custom.main,
            padding: "8px",
          }}
        >
          {newSentences.length} sentence(s) ready for import
        </Typography>
        <Button size="small" variant="contained" onClick={addNewSentences}>
          Import these sentences
        </Button>
      </Box>
      <Box
        sx={{
          maxHeight: "75vh",
          overflowY: "scroll",
          padding: "5px",
          backgroundColor: "white",
        }}
      >
        {newSentences.map((s, index) => (
          <Box key={index} mb="8px">
            <Divider />
            <Typography variant="caption">Sentence {index + 1}</Typography>
            <Typography variant="body2">{s.origText}</Typography>
            <Typography variant="caption" ml="5px">
              {s.transText}
            </Typography>
          </Box>
        ))}
      </Box>
    </Stack>
  );

  return (
    <Paper sx={{ padding: "3px", height: "90vh" }}>
      <Box
        typography="h6"
        sx={{
          height: "7%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px 50px",
        }}
      >
        <Button
          variant="outlined"
          size="small"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>

        <Typography>Importing sentences to lesson: {lesson.name}</Typography>
        <Button
          variant="text"
          color="primary"
          onClick={handleClickOpen}
          startIcon={<SourceIcon />}
        >
          Select lesson
        </Button>
      </Box>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          height: "94%",
          backgroundColor: theme.palette.blues.main,
        }}
      >
        <Box sx={{ padding: "10px", width: "48%", height: "100%" }}>
          {formJsx}
        </Box>
        <Box sx={{ padding: "10px", width: "48%", height: "100%" }}>
          {newSentences.length > 0 ? (
            <Box sx={{ height: "100%" }}>{newSentencesJsx}</Box>
          ) : (
            <Box sx={{ height: "100%" }}>{instructionsJsx}</Box>
          )}
        </Box>
      </Stack>
      <LessonSelectDialog
        selectedLesson={null}
        open={modalOpen}
        onClose={handleClose}
      />
    </Paper>
  );
}
