import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { API } from "aws-amplify";

import { enqueueSnackbar } from "notistack";
import {
  Stack,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Paper,
} from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import PageviewOutlinedIcon from "@mui/icons-material/Pageview";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import { SentenceAddEdit } from ".";
import { SequenceDisplayDialog } from "../sequences";
import { getLanguageLetters } from "../_helpers/polly-voices";
import { SentenceDisplayWithSplits } from ".";
import { useTheme } from "@mui/material";
import { WindowTitle } from "../_helpers/gvlang-components";

export { SentenceWorkbook };

function SentenceWorkbook() {
  const { id } = useParams();
  const theme = useTheme();

  const navigate = useNavigate();
  const listEndEl = useRef(null);

  const [lesson, setLesson] = useState(null);
  const [reordered, setReordered] = useState(false);
  const [selectedSentence, setSelectedSentence] = useState(null);
  const [prevSelected, setPrevSelected] = useState(null);
  const [isAdding, setIsAdding] = useState(null);
  const [changeCount, setChangeCount] = useState(0);
  const [languageLetters, setLanguageLetters] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const [nlOnLeft, setNlOnLeft] = useState(false);

  // modals
  const [sequenceDisplayOpen, setSequenceDisplayOpen] = useState(false);
  const handleSequenceDisplayClick = () => setSequenceDisplayOpen(true);
  const handleSequenceDisplayClose = () => setSequenceDisplayOpen(false);

  function toggleSplits() {
    setShowFields(!showFields);
    setSelectedSentence(null);
  }

  function toggleNlOnLeft() {
    setNlOnLeft(!nlOnLeft);
  }

  function handleImportClicked() {
    navigate("import");
  }

  function handleTransferClicked() {
    navigate("transfer");
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(lesson.sentences);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setLesson({ ...lesson, sentences: items });
    setReordered(true);
  }

  function handleShuffle() {
    let unshuffled = Array.from(lesson.sentences);
    let shuffled = unshuffled
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    setLesson({ ...lesson, sentences: shuffled });
    setReordered(true);
  }

  useEffect(() => {
    if (id) {
      const getLesson = async () => {
        let response;
        response = await API.get("lessonsApi", `/lessons/one-lesson?id=${id}`);
        setLesson(response.appData.lesson);
        setLanguageLetters(
          getLanguageLetters(response.appData.lesson.origVoice)
        );
      };
      getLesson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function setNewSentences(newSentences) {
    if (lesson) {
      const response = await API.put(
        "lessonsApi",
        `/lessons/set-sentences?id=${id}`,
        {
          body: { sentences: newSentences },
        }
      );
      if (response.error === false) {
        const count = changeCount + 1;
        setChangeCount(count);
        setLesson((prev) => {
          return {
            ...lesson,
            sentences: newSentences,
          };
        });
      } else {
        enqueueSnackbar(response.appData.message, { variant: "error" });
      }
    }
  }

  async function addSentence(data) {
    const user = JSON.parse(localStorage.getItem("gvLangUser"));
    const position = user.preferences.addSentenceTo === "beg" ? 0 : 10000;
    const response = await API.put(
      "lessonsApi",
      `/lessons/add-sentence?id=${id}`,
      {
        body: {
          sentence: data,
          position: position,
          lesson: {
            ownerId: lesson.ownerId,
            name: lesson.name,
            origVoice: lesson.origVoice,
            transVoice: lesson.transVoice,
          },
        },
      }
    );
    if (response.error === false) {
      const sentence = response.appData.sentence[0];
      const sentences = lesson?.sentences ? lesson.sentences : [];
      if (position === 0) {
        sentences.unshift(sentence);
      } else {
        sentences.push(sentence);
      }
      setLesson((prev) => {
        return {
          ...lesson,
          sentences: sentences,
        };
      });

      const count = changeCount + 1;
      setChangeCount(count);
      setSelectedSentence(null);
      setIsAdding(null);
      if (position === 1000) {
        listEndEl.current.scrollIntoView();
      }
    } else {
      enqueueSnackbar(response.appData.message, { variant: "error" });
    }
  }

  function handleCancel() {
    setSelectedSentence(null);
    setIsAdding(null);
  }

  function deleteSentence(sentenceId) {
    const items = lesson.sentences.filter((s) => {
      return s._id !== sentenceId;
    });
    setNewSentences(items);
    setSelectedSentence(null);
  }

  function updateSentence(sentenceId, data) {
    const items = Array.from(lesson.sentences);
    const newSentences = items.map((s) => {
      return s._id === sentenceId ? { ...s, ...data } : s;
    });
    const updatedItem = newSentences.find((s) => s._id === sentenceId);
    setSelectedSentence(null);
    setNewSentences(newSentences);
    setPrevSelected(updatedItem);
  }

  function handleAddClick() {
    setSelectedSentence(null);
    setIsAdding((prevVal) => !prevVal);
  }

  function handleSentenceSelect(item) {
    setIsAdding(null);
    if (selectedSentence === null || selectedSentence !== item) {
      setSelectedSentence(item);
    } else {
      setSelectedSentence(null);
    }
    if (item !== null) setPrevSelected(item);
  }

  function handleSentenceReorder() {
    if (lesson) {
      setNewSentences(lesson.sentences);
      setReordered(false);
    }
  }

  const renderSentence = (item, index) => {
    return (
      <Draggable key={index} draggableId={index + " "} index={index}>
        {(provided) => (
          <Stack ref={provided.innerRef} {...provided.draggableProps}>
            <Divider />
            <Box
              onClick={() => handleSentenceSelect(item)}
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 10fr 10fr 1fr",
                "&:hover": {
                  cursor: "pointer",
                },
                fontSize: "15px",
                marginTop: "4px",
                borderLeft: item.isInstruction ? "4px solid gold" : "inherit",
                backgroundColor:
                  prevSelected === item ? "lightsteelblue" : "inherit",
              }}
            >
              <IconButton
                sx={{
                  color: "primary.dark",
                  height: "20px",
                  width: "30px",
                  padding: "2px",
                  marginRight: "10px",
                  "&:hover": {
                    cursor: "grabbing",
                  },
                }}
                {...provided.dragHandleProps}
              >
                <DragHandleIcon />
              </IconButton>
              <Box sx={{ margin: "0px 10px" }}>
                {!nlOnLeft ? item.origText : item.transText}
              </Box>
              <Box sx={{ margin: "0px 10px" }}>
                {!nlOnLeft ? item.transText : item.origText}
              </Box>
            </Box>
            {showFields && (
              <Stack
                sx={{
                  marginTop: "5px",
                  padding: "8px 10px",
                  backgroundColor: theme.palette.primary.light,
                  color: "white",
                  cursor: "not-allowed",
                }}
              >
                <Typography variant="caption">
                  Source: {item.sourcedFrom}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Typography variant="caption">Splits:</Typography>
                  <SentenceDisplayWithSplits
                    text={item.origText}
                    splits={item.splits}
                    primaryTypographyProps={{ variant: "caption" }}
                  />
                </Stack>
              </Stack>
            )}
            {selectedSentence === item && (
              <Box
                sx={{
                  margin: "5px 25px",
                  padding: "10px",
                  border: "1px solid grey",
                  backgroundColor: "white",
                  borderRadius: "6px",
                }}
              >
                <SentenceAddEdit
                  sentence={selectedSentence}
                  changeCount={changeCount}
                  onAdd={addSentence}
                  onCancel={handleCancel}
                  onUpdate={updateSentence}
                  onDelete={deleteSentence}
                  languageLetters={languageLetters}
                />
              </Box>
            )}
          </Stack>
        )}
      </Draggable>
    );
  };

  const lessonButtons = (
    <Box display="flex" gap="10px">
      <Button
        onClick={handleImportClicked}
        variant="text"
        size="small"
        color="secondary"
        startIcon={<LibraryBooksOutlinedIcon />}
      >
        Bulk Import
      </Button>
      <Button
        onClick={handleTransferClicked}
        variant="text"
        size="small"
        color="secondary"
        startIcon={<MultipleStopIcon />}
      >
        Transfer
      </Button>
      <Button
        onClick={() => navigate(`/app/lessons/seq/${lesson._id}`)}
        variant="text"
        size="small"
        startIcon={<ArrowOutwardIcon />}
        color="secondary"
      >
        Sequence Play
      </Button>
      <Button
        onClick={() => navigate(`/app/lessons/free/${lesson._id}`)}
        variant="text"
        size="small"
        startIcon={<ArrowOutwardIcon />}
        color="secondary"
      >
        Free Play
      </Button>
    </Box>
  );

  const sentenceListButtonsJsx = (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleSentenceReorder}
        disabled={!reordered}
        startIcon={<ImportExportOutlinedIcon />}
      >
        Save order
      </Button>
      <Button
        onClick={() => handleAddClick()}
        variant="text"
        size="small"
        startIcon={<AddCardIcon />}
      >
        New sentence
      </Button>
      <Button
        onClick={handleShuffle}
        variant="text"
        size="small"
        startIcon={<ShuffleIcon />}
        sx={{ marginRight: "15px" }}
      >
        Shuffle
      </Button>

      <Button
        onClick={handleSequenceDisplayClick}
        variant="text"
        size="small"
        startIcon={<PageviewOutlinedIcon />}
        sx={{ marginRight: "15px" }}
      >
        View sequence
      </Button>
      <Button
        onClick={toggleSplits}
        variant="text"
        size="small"
        sx={{ marginRight: "15px" }}
        startIcon={!showFields ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      >
        {!showFields ? "Show fields" : "Hide fields"}
      </Button>
      <Button
        onClick={toggleNlOnLeft}
        variant="text"
        size="small"
        sx={{ marginRight: "15px" }}
      >
        {!nlOnLeft ? "Move NL left" : "Move NL right"}
      </Button>
    </>
  );

  return (
    <Paper sx={{ height: "90vh" }}>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          height: "6%",
          alignItems: "center",
          padding: "8px 10px",
          margin: "10px",
        }}
      >
        {lesson && (
          <>
            <WindowTitle>Sentences Add/Update</WindowTitle>
            <Typography variant="body1">{lesson.name}</Typography>
            <Typography variant="body2">
              {lesson.sentences?.length || 0} sentences
            </Typography>
            {lessonButtons}
          </>
        )}
      </Stack>
      <Box
        height="7%"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap="15px"
        sx={{ backgroundColor: theme.palette.grey[400], padding: "10px" }}
      >
        {sentenceListButtonsJsx}
      </Box>
      <Stack
        height="87%"
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: "white",
          padding: "8px",
        }}
      >
        <Box width="80%">
          {isAdding && (
            <Box
              sx={{
                margin: "5px 25px",
                padding: "10px",
                border: "2px solid indigo",
                backgroundColor: "white",
                borderRadius: "6px",
              }}
            >
              <SentenceAddEdit
                sentence={null}
                changeCount={changeCount}
                onAdd={addSentence}
                onCancel={handleCancel}
                onUpdate={updateSentence}
                onDelete={deleteSentence}
                languageLetters={languageLetters}
              />
            </Box>
          )}
          {lesson?.sentences && (
            <Stack
              sx={{
                height: "100%",
                overflowY: "scroll",
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="u1">
                  {(provided) => (
                    <Stack
                      spacing={2}
                      mr={4}
                      ml={1}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {lesson.sentences.map(renderSentence)}
                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              </DragDropContext>
              <Box
                ref={listEndEl}
                sx={{
                  height: "25px",
                  backgroundColor: "#9fa8da",
                  typography: "caption",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                end of list
              </Box>
            </Stack>
          )}
        </Box>
      </Stack>
      {lesson && (
        <SequenceDisplayDialog
          open={sequenceDisplayOpen}
          onClose={handleSequenceDisplayClose}
          name={lesson.usesSeq.name}
          seqText={lesson.usesSeq.seqText}
          origVoice={lesson.origVoice}
          transVoice={lesson.transVoice}
        />
      )}
    </Paper>
  );
}
