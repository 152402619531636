import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import useAxiosPrivate from "../_hooks/useAxiosPrivate";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  Box,
  Button,
  Paper,
  Typography,
  Stack,
  TextField,
} from "@mui/material";

export { AdminUser };

function UserCard({ user }) {
  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}>
      <Typography variant="caption">Email: </Typography>
      <Typography variant="caption">{user.email}</Typography>
      <Typography variant="caption">Date joined:</Typography>
      <Typography variant="caption">
        {user.account.dateJoined.split("T")[0]}
      </Typography>
    </Box>
  );
}

function AdminUser() {
  const api = useAxiosPrivate();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const validationShema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
  });

  const formOptions = {
    resolver: yupResolver(validationShema),
    defaultValues: {
      email: "",
    },
  };

  const { control, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    const { email } = data;
    try {
      const findUser = async () => {
        const response = await api.post(`user/email`, {
          email: email,
        });
        if (response.status === 204) {
          enqueueSnackbar("No user found with this email", {
            variant: "warning",
          });
          setUser(null);
        } else {
          setUser(response.data.user);
        }
      };
      findUser();
    } catch (err) {
      console.log(err.response);
      enqueueSnackbar(err.response.data.errors.join(", "), {
        variant: "error",
      });
    }
  }

  return (
    <Paper sx={{ padding: "10px" }}>
      <Typography variant="body2" sx={{ fontWeight: 700, marginLeft: "15px" }}>
        Admin Single user
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={2} p={2}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="email"
                  type="text"
                  label="email"
                  error={Boolean(errors.email)}
                  sx={{ width: "300px" }}
                />
              );
            }}
          />
          <Button
            type="primary"
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => handleSubmit(onSubmit)}
          >
            Find User
          </Button>
        </Stack>
      </form>
      {user && (
        <Stack spacing={2}>
          <UserCard user={user} />
          <Button
            variant="outlined"
            sx={{ width: "150px" }}
            onClick={() => navigate(`/users/edit/${user._id}`)}
          >
            Edit user
          </Button>
        </Stack>
      )}
    </Paper>
  );
}
