import React, { useState } from "react";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
//import { getBrowserType } from "../_services/browser.service";

import { Box, Button, Typography, Stack } from "@mui/material";
import { whatsInGvlang, registrationInfo } from "../_helpers/info-blocks";
import { FAQDialog } from "../guides/FAQDialog";
import { TermsOfUseDialog } from "../guides/TermsOfUseDialog";
import { SequenceDisplay } from "../sequences/SequenceDisplay";

export { Welcome };

function Welcome() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [termsOfUseOpen, setTermsOfUseOpen] = useState(false);
  const handleTermsOfUseClick = () => setTermsOfUseOpen(true);
  const handleTermsOfUseClose = () => setTermsOfUseOpen(false);

  const [faqDisplayOpen, setFaqDisplayOpen] = useState(false);
  const handleFaqDisplayClick = () => setFaqDisplayOpen(true);
  const handleFaqDisplayClose = () => setFaqDisplayOpen(false);

  const user = JSON.parse(localStorage.getItem("gvLangUser"));
  let origColor, transColor;
  if (user?.preferences) {
    origColor = user.preferences.origColor;
    transColor = user.preferences.transColor;
  } else {
    origColor = "#595ea1";
    transColor = "#969c3a";
  }

  return (
    <div>
      <Stack direction="row">
        <Stack
          sx={{
            color: theme.palette.neutral.dark,
            borderRadius: "4px",
            padding: "10px",
          }}
        >
          <Stack
            direction="row"
            spacing={4}
            sx={{ height: "50px", alignItems: "center" }}
          >
            <Typography variant="h6">Wecome to PhraseKeeperTTS</Typography>
            {Auth?.user && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate("/app")}
              >
                Main menu
              </Button>
            )}
            {!Auth?.user && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate("/app")}
              >
                Sign In / Sign Up
              </Button>
            )}
            <Box sx={{ marginLeft: "20px" }}>
              {Auth?.user && Auth.user.attributes.email}
            </Box>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Stack
              spacing={1}
              sx={{
                width: "40%",
                minWidth: "300px",
                marginLeft: "10px",
                marginTop: "10px",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: theme.palette.grey[300],
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignContent: "top",

                  paddingBottom: "5px",
                }}
              >
                <Typography sx={{ typography: "body2", fontWeight: 600 }}>
                  A PhraseKeeperTTS sequenceGraphic
                </Typography>
                <Typography sx={{ fontSize: "9px", fontWeight: 700 }}>
                  TM
                </Typography>
              </Box>
              <SequenceDisplay
                seqName="3-2-1 Additive"
                seqText="Otttx1i,Ttttx1i,Offtx1n,Oftfx1n,Ofttx1n,Otffx1n,Otttx2n"
              />
              <Stack mx={3} sx={{ width: "220px" }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography typography="caption">Target language:</Typography>
                  <Typography variant="caption">Astrid sv-SE</Typography>
                  <Box height="15px" width="15px" bgcolor={origColor} />
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption">Natural language:</Typography>
                  <Typography variant="caption">Emma en-GB</Typography>
                  <Box height="15px" width="15px" bgcolor={transColor} />
                </Box>
              </Stack>

              <Stack
                sx={{
                  marginTop: "10px",
                  padding: "10px",
                }}
              >
                <Typography variant="caption">
                  is a diagram of a text-to-speech sequence that plays audio
                  with user-defined steps
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ padding: "15px" }}>
              <Box
                sx={{
                  padding: "10px",
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.light,
                  typography: "body2",
                  marginBottom: "10px",
                }}
              >
                PhraseKeeperTTS - Create your own language drills
              </Box>
              {whatsInGvlang.map((feature, ix) => (
                <Typography key={ix} variant="body2" sx={{ margin: "5px" }}>
                  {feature}
                </Typography>
              ))}
              <Button
                onClick={handleTermsOfUseClick}
                variant="contained"
                size="small"
                color="secondary"
                sx={{ marginRight: "15px", width: "200px", marginTop: "10px" }}
              >
                Terms of Use
              </Button>
            </Stack>
            <Stack
              sx={{
                padding: "15px",
              }}
            >
              <Box
                sx={{
                  padding: "10px",
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.light,
                  typography: "body2",
                  marginBottom: "10px",
                }}
              >
                Sign up for PhraseKeeperTTS
              </Box>
              {registrationInfo.map((regLine, index) => (
                <Typography key={index} variant="body2" sx={{ margin: "5px" }}>
                  {regLine}
                </Typography>
              ))}
              <Button
                onClick={handleFaqDisplayClick}
                variant="contained"
                size="small"
                color="secondary"
                sx={{ marginRight: "15px", marginTop: "10px", width: "200px" }}
              >
                Read the FAQ
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <FAQDialog open={faqDisplayOpen} onClose={handleFaqDisplayClose} />
      <TermsOfUseDialog open={termsOfUseOpen} onClose={handleTermsOfUseClose} />
    </div>
  );
}
